// File is currently a .tsx because VSCode only shows color picker in .tsx not .ts.

import { css as cssEmotion } from "@emotion/css";

const Colors = {
  colorDark: "#001529",
  //colorDarkHighlighted: "#04213b",
  colorDarkHighlighted: "#00101d",

  colorWhiteFont: "#BBB",
  colorWhiteFontHighlight: "#F5F5F5",

  colorLoading: "#009FE3",

  colorHighlightDark: "#006c8d",
  colorError: "#ff4d4f",

  colorAntdButtonBase: "#1890ff",
  colorAntdButtonHover: "#40a9ff",
  colorAntdButtonActive: "#096dd9",

  colorNavBackground: "hsl(0, 0%, 97%)",
  colorNavHighlight: "hsl(0, 0%, 93%)",
  //colorNavHighlight: "#009FE3", // "#3a89ff",

  colorGray: "#444444",
  colorLightGray1: "#d6d6d6",
  colorLightGray2: "hsl(0, 0%, 93%)",

  colorGrayMain: "#222222",
  colorGrayMainDarkened: "#000000",
};

export const cssOverrideClass = "override-82ki1g";

type BorderStyle = "solid" | "dashed" | "dotted" | "double" | "none";

export const S = {
  ...Colors,

  // https://tailwindcss.com/docs/box-sizing
  boxBorder: "box-sizing: border-box;",
  boxContent: "box-sizing: content-box;",
  // https://tailwindcss.com/docs/display
  block: "display: block;",
  inlineBlock: "display: inline-block;",
  inline: "display: inline;",
  flex: "display: flex;",
  inlineFlex: "display: inline-flex;",
  table: "display: table;",
  tableCaption: "display: table-caption;",
  tableCell: "display: table-cell;",
  tableColumn: "display: table-column;",
  tableColumnGroup: "display: table-column-group;",
  tableFooterGroup: "display: table-footer-group;",
  tableHeaderGroup: "display: table-header-group;",
  tableRowGroup: "display: table-row-group;",
  tableRow: "display: table-row;",
  flowRoot: "display: flow-root;",
  grid: "display: grid;",
  inlineGrid: "display: inline-grid;",
  contents: "display: contents;",
  hidden: "display: none;",
  // https://tailwindcss.com/docs/float
  floatRight: "float: right;",
  floatLeft: "float: left;",
  floatNone: "float: none;",
  // https://tailwindcss.com/docs/clear
  clearLeft: "clear: left;",
  clearRight: "clear: right;",
  clearBoth: "clear: both;",
  clearNone: "clear: none;",
  // https://tailwindcss.com/docs/object-fit
  objectContain: "object-fit: contain;",
  objectCover: "object-fit: cover;",
  objectFill: "object-fit: fill;",
  objectNone: "object-fit: none;",
  objectScaleDown: "object-fit: scale-down;",
  // https://tailwindcss.com/docs/object-position
  objectBottom: "object-position: bottom;",
  objectCenter: "object-position: center;",
  objectLeft: "object-position: left;",
  objectLeftBottom: "object-position: left bottom;",
  objectLeftTop: "object-position: left top;",
  objectRight: "object-position: right;",
  objectRightBottom: "object-position: right bottom;",
  objectRightTop: "object-position: right top;",
  objectTop: "object-position: top;",
  // https://tailwindcss.com/docs/overflow
  overflowAuto: "overflow: auto;",
  overflowHidden: "overflow: hidden;",
  overflowVisible: "overflow: visible;",
  overflowScroll: "overflow: scroll;",
  overflowXAuto: "overflow-x: auto;",
  overflowYAuto: "overflow-y: auto;",
  overflowXHidden: "overflow-x: hidden;",
  overflowYHidden: "overflow-y: hidden;",
  overflowXVisible: "overflow-x: visible;",
  overflowYVisible: "overflow-y: visible;",
  overflowXScroll: "overflow-x: scroll;",
  overflowYScroll: "overflow-y: scroll;",
  // https://tailwindcss.com/docs/overscroll-behavior
  overscrollAuto: "overscroll-behavior: auto;",
  overscrollContain: "overscroll-behavior: contain;",
  overscrollNone: "overscroll-behavior: none;",
  overscrollYAuto: "overscroll-behavior-y: auto;",
  overscrollYContain: "overscroll-behavior-y: contain;",
  overscrollYNone: "overscroll-behavior-y: none;",
  overscrollXAuto: "overscroll-behavior-x: auto;",
  overscrollXContain: "overscroll-behavior-x: contain;",
  overscrollXNone: "overscroll-behavior-x: none;",
  // https://tailwindcss.com/docs/position
  static: "position: static;",
  fixed: "position: fixed;",
  absolute: "position: absolute;",
  relative: "position: relative;",
  sticky: "position: sticky;",
  // https://tailwindcss.com/docs/visibility
  visible: "visibility: visible;",
  invisible: "visibility: hidden;",
  // https://tailwindcss.com/docs/z-index
  z: (x: number | "auto") => `z-index: ${x}`,

  // https://tailwindcss.com/docs/flex-direction
  flexRow: "flex-direction: row;",
  flexRowReverse: "flex-direction: row-reverse;",
  flexCol: "flex-direction: column;",
  flexColReverse: "flex-direction: column-reverse;",
  // https://tailwindcss.com/docs/flex-wrap
  flexWrap: "flex-wrap: wrap;",
  flexWrapReverse: "flex-wrap: wrap-reverse;",
  flexNowrap: "flex-wrap: nowrap;",
  // https://tailwindcss.com/docs/flex
  flex1: "flex: 1 1 0%;",
  flexAuto: "flex: 1 1 auto;",
  flexInitial: "flex: 0 1 auto;",
  flexNone: "flex: none;",
  // https://tailwindcss.com/docs/flex-grow
  flexGrow0: "flex-grow: 0;",
  flexGrow: "flex-grow: 1;",
  // https://tailwindcss.com/docs/flex-shrink
  flexShrink0: "flex-shrink: 0;",
  flexShrink: "flex-shrink: 1;",

  container: `
    width: 100%;
    @media (min-width: 640px) {
      max-width: 640px;
    }
    @media (min-width: 768px) {
      max-width: 768px;
    }
    @media (min-width: 1024px) {
      max-width: 1024px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
    @media (min-width: 1536px) {
      max-width: 1536px;
    }
  `,

  // https://tailwindcss.com/docs/justify-content
  justify: (type: "start" | "end" | "center" | "between" | "around" | "evenly") => {
    const value = {
      start: "flex-start",
      end: "flex-end",
      center: "center",
      between: "space-between",
      around: "space-around",
      evenly: "space-evenly",
    }[type];
    return `justify-content: ${value};`;
  },

  // https://tailwindcss.com/docs/align-items
  items: (type: "start" | "end" | "center" | "baseline" | "stretch") => {
    const value = type === "start" || type === "end" ? "flex-" + type : type;
    return `align-items: ${value};`;
  },

  // https://tailwindcss.com/docs/padding
  p: (x: number) => `padding: ${x * 0.0625}rem;`,
  px: (x: number) => `padding-left: ${x * 0.0625}rem; padding-right: ${x * 0.0625}rem;`,
  py: (x: number) => `padding-top: ${x * 0.0625}rem; padding-bottom: ${x * 0.0625}rem;`,
  pl: (x: number) => `padding-left: ${x * 0.0625}rem`,
  pr: (x: number) => `padding-right: ${x * 0.0625}rem`,
  pt: (x: number) => `padding-top: ${x * 0.0625}rem`,
  pb: (x: number) => `padding-bottom: ${x * 0.0625}rem`,

  // https://tailwindcss.com/docs/margin
  m: (x: number) => `margin: ${x * 0.0625}rem;`,
  mx: (x: number) => `margin-left: ${x * 0.0625}rem; margin-right: ${x * 0.0625}rem;`,
  my: (x: number) => `margin-top: ${x * 0.0625}rem; margin-bottom: ${x * 0.0625}rem;`,
  ml: (x: number) => `margin-left: ${x * 0.0625}rem`,
  mr: (x: number) => `margin-right: ${x * 0.0625}rem`,
  mt: (x: number) => `margin-top: ${x * 0.0625}rem`,
  mb: (x: number) => `margin-bottom: ${x * 0.0625}rem`,
  mAuto: "margin: auto",
  mxAuto: "margin-left: auto; margin-right: auto;",
  myAuto: "margin-top: auto; margin-bottom: auto;",
  mlAuto: "margin-left: auto",
  mrAuto: "margin-right: auto",
  mtAuto: "margin-top: auto",
  mbAuto: "margin-bottom: auto",

  // https://tailwindcss.com/docs/width
  // https://tailwindcss.com/docs/height
  w: (x: number) => `width: ${x * 0.0625}rem;`,
  h: (x: number) => `height: ${x * 0.0625}rem;`,
  wFull: "width: 100%;",
  hFull: "height: 100%;",
  wScreen: "width: 100vw;",
  hScreen: "height: 100vh;",
  wAuto: "width: auto;",
  hAuto: "height: auto;",
  wMin: "width: min-content;",
  wMax: "width: max-content;",

  // https://tailwindcss.com/docs/min-width
  minW: (x: 0 | "full" | "min-content" | "max-content") => `min-width: ${x}`,

  spaceX: (x: number) => `& > * + * { margin-left: ${x * 0.0625}rem; }`,
  spaceY: (x: number) => `& > * + * { margin-top: ${x * 0.0625}rem; }`,

  // https://tailwindcss.com/docs/font-size
  textSize: (x: number) => `font-size: ${x * 0.0625}rem; line-height: ${x * 0.0625}rem;`,

  // https://tailwindcss.com/docs/font-weight
  fontWeight: (x: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900) => `font-weight: ${x};`,

  // https://tailwindcss.com/docs/text-overflow
  truncate: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  overflowEllipsis: "text-overflow: ellipsis;",
  overflowClip: "text-overflow: clip;",

  // https://tailwindcss.com/docs/whitespace
  whitespace: (mode: "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap") =>
    `white-space: ${mode}`,

  // Add hoc helper
  textColor: (color: string) => `color: ${color}`,
  bgColor: (color: string) => `background-color: ${color}`,

  border: (x: number = 1, color = "#000", style: BorderStyle = "solid") =>
    `border: ${x}px ${color} ${style};`,
  borderWidth: (x: number = 1) => `border-width: ${x}px;`,
  borderColor: (color: string) => `border-color: ${color};`,
  borderStyle: (style: BorderStyle) => `border-style: ${style};`,

  // https://tailwindcss.com/docs/list-style-type
  listNone: "list-style-type: none;",
  listDisc: "list-style-type: disc;",
  listDecimal: "list-style-type: decimal;",

  // https://tailwindcss.com/docs/border-radius
  rounded: (x: number | "full" = 2) => {
    const value = x === "full" ? 9999 : x;
    return `border-radius: ${value * 0.0625}rem;`;
  },

  // https://tailwindcss.com/docs/transition-property
  transitionAll: (t: number = 150) => `
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: ${t}ms;
  `,

  // https://tailwindcss.com/docs/cursor
  cursor: (
    mode: "auto" | "default" | "pointer" | "wait" | "text" | "move" | "help" | "not-allowed"
  ) => `cursor: ${mode}`,

  // special helpers
  // https://css-tricks.com/overriding-default-button-styles/
  // https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit
  unstyledButton: `
    font: inherit;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    line-height: 1.0;
    &:focus {
      outline: none;
    }
  `,
  // In order to make button look like a link (navigation), we need to mirror
  // Antd's button style.
  linkLike: `
    color: ${Colors.colorAntdButtonBase};
    transition: color 0.3s;
    &:hover {
      color: ${Colors.colorAntdButtonHover};
    }
    &:active {
      color: ${Colors.colorAntdButtonActive};
    }
  `,

  // pseudo class wrapper
  hover: (...css: string[]) => `&:hover { ${join(...css)} }`,
  focus: (...css: string[]) => `&:focus { ${join(...css)} }`,
  focusWithin: (...css: string[]) => `&:focus-within { ${join(...css)} }`,
  focusVisible: (...css: string[]) => `&:focus-visible { ${join(...css)} }`,
  visited: (...css: string[]) => `&:visited { ${join(...css)} }`,
  checked: (...css: string[]) => `&:checked { ${join(...css)} }`,
  active: (...css: string[]) => `&:active { ${join(...css)} }`,
  disabled: (...css: string[]) => `&:disabled { ${join(...css)} }`,

  first: (...css: string[]) => `&:first-child { ${join(...css)} }`,
  last: (...css: string[]) => `&:last-child { ${join(...css)} }`,
  odd: (...css: string[]) => `&:odd-child { ${join(...css)} }`,
  even: (...css: string[]) => `&:even-child { ${join(...css)} }`,

  override: (...css: string[]) => `.${cssOverrideClass} & { ${join(...css)} }`,
};

export function join(...css: string[]): string {
  return css.join(";\n");
}

export function makeClass(...css: string[]): string {
  return cssEmotion(join(...css));
}

type ClassNameProp = { className: string };

export function css(...css: string[]): ClassNameProp {
  return { className: cssEmotion(join(...css)) };
}

export function combined(...cssClasses: (ClassNameProp | string | undefined)[]): ClassNameProp {
  return {
    className: cssClasses
      .filter((c) => c != null)
      .map((c) => (typeof c === "string" ? c : c!.className))
      .join(" "),
  };
}

// Utility override classes

export const cssEnableOverrides = { className: cssOverrideClass };

export const cssInvisible = css(S.override(S.invisible));

export const cssTextError = css(S.override(S.textColor(S.colorError)));
