import { ViewRange } from "./view_range";
import { Timestamp, TimeRange } from "../../utils/date_utils";

export interface Animation {
  update(viewRange: ViewRange): boolean;
}

/**
 * The idea of this helper class is to abstract away exponential growth with
 * renormalization.
 *
 * In the case of N -> Infinity, the returned zoom factor grows exponentially
 * with wStep from 0 to 1, for instance for wStep = 0.75:
 * i = 0 => factor = 0.0
 * i = 1 => factor = 0.75 (= 1 - 0.25 ** 1)
 * i = 2 => factor = 0.9375 (= 1 - 0.25 ** 2)
 *
 * I.e., in each step the function goes 75% towards the goal. Of course in
 * theory the function will never fully reach 1.0. That's the purpose of the
 * N parameter: It linearly re-normalizes the values so that the N-th value
 * is exactly 1.0.
 *
 * The `stepSize` parameters allows to specify a base unit for wStep, i.e.,
 * `stepSize = 10` with `wStep = 0.75` mean that the function grows by
 * 75% in units of 10 instead of 1.
 */
export class ZoomFactorHelper {
  private alpha: number;
  private factorAtNInverse: number;

  constructor(wStep: number, N: number, stepSize = 1.0) {
    // Rewrite `alpha ^ (i / stepSize)` as modified base `(alpha ^ (1/stepSize)) ^ i`
    // to avoid the division in each iteration.
    this.alpha = Math.pow(1 - wStep, 1 / stepSize);
    // For re-normalization we only need to evaluate the "raw factor" at N. We take
    // the inverse here to turn the division into a multiplication later.
    this.factorAtNInverse = isFinite(N) ? 1 / this.getZoomFactorRaw(N) : 1.0;
  }

  getZoomFactorRescaled(i: number): number {
    return this.getZoomFactorRaw(i) * this.factorAtNInverse;
  }

  private getZoomFactorRaw(i: number): number {
    return 1 - Math.pow(this.alpha, i);
  }
}

export class AnimationZoom implements Animation {
  private startTime: number;

  // Note that the purpose of `referenceFrameTime` is only to make the
  // wStep parameter more intuitive to control.
  static referenceFrameTime = (1 / 60) * 1000;
  static maxTime = 150;
  static wStep = 0.3;
  static zoomFactorHelper = new ZoomFactorHelper(
    AnimationZoom.wStep,
    AnimationZoom.maxTime,
    AnimationZoom.referenceFrameTime
  );

  constructor(private initRange: TimeRange, private zoomRange: TimeRange) {
    this.startTime = performance.now();
  }

  getZoomRange(): TimeRange {
    return this.zoomRange;
  }

  reset(initRange: TimeRange, zoomRange: TimeRange) {
    this.initRange = initRange;
    this.zoomRange = zoomRange;
    this.startTime = performance.now();
  }

  update(viewRange: ViewRange): boolean {
    const elapsed = performance.now() - this.startTime;

    let tsFromInit = this.initRange.from;
    let tsUptoInit = this.initRange.upto;
    let tsFromZoom = this.zoomRange.from;
    let tsUptoZoom = this.zoomRange.upto;

    if (elapsed < AnimationZoom.maxTime) {
      let wZoom = AnimationZoom.zoomFactorHelper.getZoomFactorRescaled(elapsed);
      let wInit = 1 - wZoom;
      let timestampFrom = tsFromInit * wInit + tsFromZoom * wZoom;
      let timestampUpto = tsUptoInit * wInit + tsUptoZoom * wZoom;
      viewRange.setRange({
        from: timestampFrom as Timestamp,
        upto: timestampUpto as Timestamp,
      });
      return false;
    } else {
      viewRange.setRange(this.zoomRange);
      return true;
    }
  }
}

export class AnimationDrag implements Animation {
  private velTimestampPerMS: number;
  private decel: number;
  private lastTime: number;

  // Initially I went for 3.0, but I feel fast scrolling is disconcerting?
  static MAX_VEL_PIXEL_PER_MS = 1.8;

  constructor(velPixelPerMS: number, viewRange: ViewRange) {
    // Without a velocity threshold, scrolling can become a little bit crazy.
    velPixelPerMS = AnimationDrag.clampVelPixelPerMS(velPixelPerMS);

    const timestampPerPixel = viewRange.getTimePerPixel();
    const sign = viewRange.futureUp ? +1 : -1;

    this.velTimestampPerMS = sign * timestampPerPixel * velPixelPerMS;
    this.decel = timestampPerPixel * (2 / 1000) * (this.velTimestampPerMS > 0 ? -1 : +1);

    this.lastTime = performance.now();
  }

  private static clampVelPixelPerMS(vel: number) {
    return vel < -AnimationDrag.MAX_VEL_PIXEL_PER_MS
      ? -AnimationDrag.MAX_VEL_PIXEL_PER_MS
      : vel > AnimationDrag.MAX_VEL_PIXEL_PER_MS
      ? AnimationDrag.MAX_VEL_PIXEL_PER_MS
      : vel;
  }

  private updateTime(): number {
    let lastTime = this.lastTime;
    let currTime = performance.now();
    let deltaTime = currTime - lastTime;
    this.lastTime = currTime;
    return deltaTime;
  }

  update(viewRange: ViewRange): boolean {
    let deltaTime = this.updateTime();

    let velBefore = this.velTimestampPerMS;
    this.velTimestampPerMS += this.decel * deltaTime;
    let velAfter = this.velTimestampPerMS;

    // We have to be careful here: We want to update regularly on velAfter === 0.
    // However, we must not continue if velBefore === 0, otherwise we can animate
    // infinitely if the velocity hits zero exactly.
    if (velBefore !== 0 && velBefore * velAfter >= 0) {
      let deltaTimestamp = this.velTimestampPerMS * deltaTime;
      let timestampFrom = viewRange.getRange().from + deltaTimestamp;
      let timestampUpto = viewRange.getRange().upto + deltaTimestamp;
      viewRange.setRange({
        from: timestampFrom as Timestamp,
        upto: timestampUpto as Timestamp,
      });
      return false;
    } else {
      return true;
    }
  }
}
