import { useRef, useState } from "react";

import { Tag, Modal } from "antd";
import { LeftOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { Window, RequireLoginModal, UserIconWhite } from "../utils";

import { S, css } from "../../styles/styles";

import { useHistory, useParams, Link } from "react-router-dom";

import { backend } from "../../backend";
import * as types from "../../backend/types/api";

import * as date_utils from "../../utils/date_utils";
import { useRequest, Preloader } from "../../utils/react_utils";

import { AddTag } from "./sub/AddTag";

const cssBack = css(S.textSize(12));
const cssHeadline = css(S.mt(10));
const cssDate = css(S.textColor("#666"));
const cssTags = css(S.mt(10));

const cssReferences = css(
  S.mt(10),
  S.pt(10),
  S.mb(10),
  S.pb(10),
  `border-top: 1px solid ${S.colorLightGray2}`,
  `border-bottom: 1px solid ${S.colorLightGray2}`
);
const cssReferencesList = css(S.listNone, S.pl(1), S.mb(0)); // For some reason a very slight padding is needed to align the item correctly.

export const FactPreloader = new Preloader(
  async (factId: string) => await backend().factGet(factId)
);

export function Fact(props: { isAuthenticated: boolean }) {
  const { factId } = useParams<{ factId?: string }>();
  if (factId != null) {
    return <FactImpl {...props} factId={factId} />;
  } else {
    console.log("ERROR: Router did not provide a factId.");
    return null;
  }
}

type FactProps = {
  isAuthenticated: boolean;
  factId: string;
};

export function FactImpl({ isAuthenticated, factId }: FactProps) {
  const requestKey = factId;

  const history = useHistory();

  const [fact] = useRequest(requestKey, async () => {
    const requestKey = factId;
    const req = await FactPreloader.get(requestKey, factId);
    // Handle request errors
    // Note that request error handling should be in the callback, because it is
    // illegal to use history.push() directly in the render function, and also
    // the modal window displays twice (perhaps due to the double render function
    // calling in dev mode).
    if (!req.isOk) {
      Modal.error({
        title: "Failed to fetch fact",
        content: req.reqError || req.appError,
      });
      history.push("/");
      return undefined;
    } else {
      return req.data;
    }
  });

  const [tagUpdates, setTagUpdates] = useState<Record<string, types.TagRelevanceWithUserVoting>>(
    {}
  );

  const tagVoteRequestHandler = useTagVoteRequestHandler(
    isAuthenticated,
    factId,
    (tagName: string, tag: types.TagRelevanceWithUserVoting) => {
      setTagUpdates((old) => ({ ...old, [tagName]: tag }));
    }
  );

  const onAddTagCallback = (tagName: string, tag: types.TagRelevanceWithUserVoting) => {
    setTagUpdates((old) => ({ ...old, [tagName]: tag }));
  };

  // Handle loading
  if (fact == null) {
    if (FactPreloader.hasPreloadedData()) {
      // If the preloaded has data available, it is fine to return an empty VDOM, because the
      // component will re-render immediately anyway when the effect within useRequest runs.
      return null;
    } else {
      // TODO: Here we could implement a loading indicator.
      return null;
    }
  }

  const factReferences = fact.references.map((url) => simplifyUrl(url));

  // Compute resulting tags and sort
  const tagsSorted = mergeTags(fact.tags, tagUpdates);
  // console.log(tagsSorted);

  return (
    <Window>
      {/*
      <Button
        type="primary"
        onClick={() => history.push("../..")}
        icon={<LeftOutlined style={{ fontSize: "12px" }} />}
      />
      */}
      <Link to="../.." {...cssBack}>
        <LeftOutlined style={{ fontSize: "12px" }} /> back
      </Link>

      <h2 {...cssHeadline}>{fact.headline}</h2>

      <div {...cssDate}>
        {date_utils.formatToDay(date_utils.toDateFields(fact.timestamp as date_utils.Timestamp))}
      </div>

      <div {...cssReferences}>
        <ul {...cssReferencesList}>
          {factReferences.map((reference) => (
            <li key={reference.url}>
              <a href={reference.url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faExternalLinkAlt} /> <span>{reference.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <UserBadge username={fact.username} />

      <div {...cssTags}>
        {tagsSorted.map((tag) => (
          <VotableTag
            key={tag.tag}
            tag={tag}
            vote={(upvote) => tagVoteRequestHandler(tag, upvote)}
          />
        ))}
      </div>
      <AddTag isAuthenticated={isAuthenticated} factId={factId} addTagCallback={onAddTagCallback} />
    </Window>
  );
}

const cssVoteRow = css(S.flex, S.items("center"));
const cssVoteColumn = css(S.inlineFlex, S.flexCol, S.items("center"), S.mr(8), S.w(40));
const cssVoteButton = css(S.unstyledButton);
const cssTriangle = css(S.textSize(20), S.override(S.textColor("#666")));
const cssTriangleHighlight = css(S.textSize(20), S.override(S.textColor("#e99700")));
const cssNumberLabel = css(S.mb(-1)); // To strip away the font descendent (Roboto required -4, Lato okay with -1)

function VotableTag({
  tag,
  vote,
}: {
  tag: types.TagRelevanceWithUserVoting;
  vote: (upvote: boolean) => void;
}) {
  return (
    <div {...cssVoteRow}>
      <span {...cssVoteColumn}>
        <button onClick={() => vote(true)} {...cssVoteButton}>
          <CaretUpOutlined {...(tag.voteKind === "upvote" ? cssTriangleHighlight : cssTriangle)} />
        </button>
        <span {...cssNumberLabel}>{tag.relevance.toFixed(0)}</span>
        <button onClick={() => vote(false)} {...cssVoteButton}>
          <CaretDownOutlined
            {...(tag.voteKind === "downvote" ? cssTriangleHighlight : cssTriangle)}
          />
        </button>
      </span>
      <Tag key={tag.tag} color="green">
        {tag.tag}
      </Tag>
    </div>
  );
}

const cssUserBadge = css(S.floatRight, S.p(8), S.rounded(4), S.bgColor("#E5F5F9"), S.w(180));

const cssUserLink = css(
  S.flex,
  S.items("center"),
  // Not sure if this is needed:
  // https://css-tricks.com/flexbox-truncated-text/
  S.minW(0)
);

const cssUsername = css(S.flexGrow, S.truncate);

const cssPostedBy = css(S.textSize(10), S.mb(8));

function UserBadge(props: { username: string }) {
  return (
    <div {...cssUserBadge}>
      <div {...cssPostedBy}>Posted by</div>
      <Link to={`/user/${props.username}`} {...cssUserLink}>
        <UserIconWhite />
        <div {...cssUsername}>
          <span style={{ textOverflow: "ellipsis" }}>{props.username}</span>
        </div>
      </Link>
    </div>
  );
}

// ----------------------------------------------------------------------------
// useTagVoteRequestHandler hook to abstract away vote requests
// ----------------------------------------------------------------------------

/*
type SetTagUpdates = React.Dispatch<
  React.SetStateAction<Record<string, types.TagRelevanceWithUserVoting>>
>;
*/
type TagVoteRequestHandler = (tag: types.TagRelevanceWithUserVoting, upvote: boolean) => void;

function useTagVoteRequestHandler(
  isAuthenticated: boolean,
  factId: string,
  voteCallback: (tagName: string, tag: types.TagRelevanceWithUserVoting) => void
): TagVoteRequestHandler {
  const requestsInProgress = useRef<Record<string, boolean>>({});

  const handler = async (tag: types.TagRelevanceWithUserVoting, upvote: boolean) => {
    // Check if we are authenticated at all
    if (!isAuthenticated) {
      RequireLoginModal("Voting on fact requires login.");
      return;
    }

    // Ensure only a single request is in progress per tag
    if (tag.tag in requestsInProgress.current) {
      return;
    } else {
      requestsInProgress.current[tag.tag] = true;
    }

    // Infer the vote kind depending on existing vote
    let voteKind: types.InputVoteKind;
    if ((upvote && tag.voteKind === "upvote") || (!upvote && tag.voteKind === "downvote")) {
      voteKind = "resetvote";
    } else {
      voteKind = upvote ? "upvote" : "downvote";
    }

    const res = await backend().factVote(factId, tag.tag, voteKind);

    if (res.isOk) {
      const tagUpdated = res.data;
      voteCallback(tagUpdated.tag, tagUpdated);
    } else {
      Modal.error({
        title: "Vote failed",
        content: res.reqError || res.appError,
      });
    }

    delete requestsInProgress.current[tag.tag];
  };

  return handler;
}

// ----------------------------------------------------------------------------
// Utils
// ----------------------------------------------------------------------------

function mergeTags(
  baseTags: types.TagRelevanceWithUserVoting[],
  tagUpdates: Record<string, types.TagRelevanceWithUserVoting>
) {
  const result = [...baseTags];

  for (const tagName in tagUpdates) {
    const tagUpdate = tagUpdates[tagName];
    const idx = result.findIndex((tag) => tag.tag === tagName);
    if (idx >= 0) {
      result[idx] = tagUpdate;
    } else {
      result.push(tagUpdate);
    }
  }

  const resultSorted = result.sort((a, b) => b.relevance - a.relevance);
  return resultSorted;
}

function simplifyUrl(url: string) {
  let name = url;
  const indexDoubleSlash = name.indexOf("//");
  if (indexDoubleSlash >= 0) {
    name = name.slice(indexDoubleSlash + 2);
  }
  const indexSlash = name.indexOf("/");
  if (indexSlash >= 0) {
    name = name.slice(0, indexSlash);
  }
  return { name, url };
}
