import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";

import { useHistory } from "react-router-dom";

import { useRefFn } from "../../utils/react_utils";

import { TimeRange } from "../../utils/date_utils";

import { ResizeObserver } from "../../utils/resize_observer";

import { FactPreloader } from "../fact/Fact";

import { ViewRange } from "./view_range";
import * as view_state from "./view_range";

import { TimelineView } from "./timeline_view";

import { Animation, AnimationZoom } from "./animation_utils";

import { DragHandler, PinchHandler } from "./event_utils";

// ----------------------------------------------------------------------------
// Main component
// ----------------------------------------------------------------------------

const ZOOM_IN_FACTOR = 1.5;
const ZOOM_OUT_FACTOR = 1 / ZOOM_IN_FACTOR;
const ZOOM_IN_FACTOR_FINE = 1.05;
const ZOOM_OUT_FACTOR_FINE = 1 / ZOOM_IN_FACTOR_FINE;

export type TimelineHandle = {
  zoomTo: (range: TimeRange) => void;
};

export type TimelineProps = {
  viewRange: ViewRange;
  tags: string[];
  resizeObserver: ResizeObserver;
  handle: React.MutableRefObject<TimelineHandle | undefined>;
};

/**
 * The Timeline component is the interface between the React world to the underlying
 * View component. Responsibilities:
 * - React state handling
 * - Map React events to underlying timeline updates.
 * - Transitions between controlled (animation) to uncontrolled (user input) states.
 */
export function Timeline({ viewRange, tags, resizeObserver, handle }: TimelineProps) {
  const history = useHistory();

  // Refs
  const refView = useRefFn<TimelineView>(() => new TimelineView(viewRange, tags));

  const refEventHandlers = useRefFn(() => ({
    drag: new DragHandler(),
    pinch: new PinchHandler(),
  }));

  const refAnimation = useRef<Animation | null>(null);

  // States
  const [animating, setAnimating] = useState(false);

  const redrawWithSizeMeasurement = useCallback(() => {
    const view = refView.current;
    if (view.root != null && view.canvas != null && view.canvasHover != null) {
      // Note 1: This is the only place where DOM querying should occur.
      // Note that we query the root element instead of the canvases directly. The
      // canvas size is fully managed by code and not by CSS, in order to avoid
      // letting the canvas obtain a fractional size in the first place.
      // Note 2: For extra correctness, we check if view.root is actually
      // still part of the DOM. If not getBoundingClientRect still returns
      // a rect, but all values will be zero, which is not an update we need
      // to forward to the view.
      if (document.body.contains(view.root)) {
        const rect = view.root.getBoundingClientRect();
        view.updateClientBoundingRect(rect);
      } else {
        console.log(
          "WARNING: redrawWithSizeMeasurement called when root element wasn't part of the DOM."
        );
      }
    } else {
      console.log("WARNING: redrawWithSizeMeasurement called before refs were set.");
    }
  }, [refView]);

  // Effect to trigger initial canvas draw
  useEffect(() => {
    redrawWithSizeMeasurement();
  }, [redrawWithSizeMeasurement]);

  useEffect(() => {
    refView.current.updateTags(tags);
  }, [refView, tags]);

  // Re-register resize/postFetch observer
  resizeObserver.register("timeline", () => {
    redrawWithSizeMeasurement();
  });

  // Note: the cleanup of the resize observer needs to be in a layout effect instead
  // of a normal effect. Otherwise, the resize observer triggers a callback before the
  // final event cleanup runs. In this case, there will be one final callback call in
  // which the view.root element has already been detached from the DOM, and no size
  // measurement is possible / needed.
  useLayoutEffect(() => {
    return () => {
      resizeObserver.unregister("timeline");
    };
  });

  // Re-register handle callbacks
  handle.current = {
    zoomTo: (zoomRange: TimeRange) => {
      const initRange = viewRange.getRange();
      if (refAnimation.current instanceof AnimationZoom) {
        refAnimation.current.reset(initRange, zoomRange);
      } else {
        refAnimation.current = new AnimationZoom(initRange, zoomRange);
      }
      if (!animating) {
        setAnimating(true);
      }
    },
  };

  // Animation render effect
  useEffect(() => {
    if (animating === true) {
      let animationFrameId: number;

      const render = () => {
        let animation = refAnimation.current;

        let isFinished = animation != null ? animation.update(viewRange) : true;
        refView.current.updateViewRange(viewRange);

        if (isFinished) {
          setAnimating(false);
        } else {
          animationFrameId = window.requestAnimationFrame(render);
        }
      };

      // Not sure if render immediately or postponing is desireable.
      render();
      // animationFrameId = window.requestAnimationFrame(render);

      return () => {
        // console.log("cleaning up animation");
        window.cancelAnimationFrame(animationFrameId);
      };
    } else {
      refAnimation.current = null;
    }
  }, [refView, animating, viewRange]);

  // Mouse handlers

  const onMouseWheel = (event: React.WheelEvent) => {
    const animation = refAnimation.current;

    let zoomTimestamp = refView.current.getTimestampFromClientY(event.clientY);
    if (zoomTimestamp != null) {
      let scrollUp = event.deltaY < 0;
      // prettier-ignore
      let factor = !event.shiftKey ?
          (scrollUp ? ZOOM_IN_FACTOR : ZOOM_OUT_FACTOR) :
          (scrollUp ? ZOOM_IN_FACTOR_FINE : ZOOM_OUT_FACTOR_FINE);

      // By default the basis of the zoom computation is the current range of the view.
      // However if there is already a zoom animation in progress, we build on top of it
      // to chain the zoom effects.
      let baseRange: TimeRange;
      if (animation instanceof AnimationZoom) {
        baseRange = animation.getZoomRange();
      } else {
        baseRange = viewRange.getRange();
      }
      let zoomRange = view_state.computeZoomedRange(
        zoomTimestamp,
        baseRange,
        factor,
        viewRange.getHeight()
      );

      if (zoomRange != null) {
        if (animation instanceof AnimationZoom) {
          animation.reset(viewRange.getRange(), zoomRange);
        } else {
          refAnimation.current = new AnimationZoom(viewRange.getRange(), zoomRange);
        }
        if (!animating) {
          setAnimating(true);
        }
      }
    }
  };

  const handleClick = async (clientX: number, clientY: number) => {
    const matchingCircle = refView.current.getMatchingCircleFromClientXY(clientX, clientY);
    if (matchingCircle != null) {
      const factId = matchingCircle.id;
      await FactPreloader.preload(factId, factId);
      history.push("fact/" + factId);
    }
  };

  const onMouseDown = (event: React.MouseEvent) => {
    refEventHandlers.current.drag.onDragStart(
      event.clientY,
      animating,
      setAnimating,
      refAnimation,
      refView.current.root
    );
    // Unset mouse coordinates before dragging so that no hover can appear during dragging.
    refView.current.updateMouseXY(undefined, undefined);
  };

  const onMouseMove = (event: React.MouseEvent) => {
    const eventHandlers = refEventHandlers.current;
    if (eventHandlers.drag.dragging) {
      eventHandlers.drag.onDrag(event.clientY, viewRange, () =>
        refView.current.updateViewRange(viewRange)
      );
    } else {
      refView.current.updateMouseXY(event.clientX, event.clientY);
    }
  };

  const onMouseUp = (event: React.MouseEvent) => {
    const eventHandlers = refEventHandlers.current;
    if (!eventHandlers.drag.hasBeenDragged()) {
      handleClick(event.clientX, event.clientY);
    }
    refView.current.updateMouseXY(event.clientX, event.clientY);
    eventHandlers.drag.onDragEnd(setAnimating, refAnimation, viewRange, refView.current.root);
    event.preventDefault();
  };

  // Touch handlers

  const onTouchStart = (event: React.TouchEvent) => {
    const eventHandlers = refEventHandlers.current;
    if (event.touches.length === 1) {
      eventHandlers.drag.onDragStart(
        event.touches[0].clientY,
        animating,
        setAnimating,
        refAnimation,
        refView.current.root
      );
    } else if (event.touches.length === 2) {
      eventHandlers.drag.onDragEndNoAnimation(refView.current.root);
      eventHandlers.pinch.onPinchStart(event.touches[0], event.touches[1], animating, setAnimating);
    }
  };

  const onTouchMove = (event: React.TouchEvent) => {
    const eventHandlers = refEventHandlers.current;
    event.stopPropagation();
    event.preventDefault();
    if (eventHandlers.drag.dragging && event.touches.length === 1) {
      eventHandlers.drag.onDrag(event.touches[0].clientY, viewRange, () =>
        refView.current.updateViewRange(viewRange)
      );
    } else if (event.touches.length === 2) {
      eventHandlers.pinch.onPinch(
        event.touches[0],
        event.touches[1],
        viewRange,
        refView.current.getTimestampFromClientY.bind(refView.current),
        () => refView.current.updateViewRange(viewRange)
      );
    }
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    const eventHandlers = refEventHandlers.current;
    if (event.touches.length === 0) {
      // TODO: In contrast to onMouseUp we cannot handle a click here, because
      // event.touches is empty now, so we don't know the x/y.
      eventHandlers.drag.onDragEnd(setAnimating, refAnimation, viewRange, refView.current.root);
    } else if (eventHandlers.pinch.isPinching()) {
      // Touches went from 2 to 1 => switch back from pinching to dragging.
      eventHandlers.pinch.onPinchEnd();
      eventHandlers.drag.onDragStart(
        event.touches[0].clientY,
        animating,
        setAnimating,
        refAnimation,
        refView.current.root
      );
    }
  };

  return (
    <div
      ref={(ref) => {
        // TODO Do we have to check if rRenderer.current.root is already set?
        if (ref != null) {
          refView.current.setRoot(ref);
        }
      }}
      onWheel={onMouseWheel}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        border: "1px solid #EEE",
        boxSizing: "border-box",
        overflow: "hidden",
        cursor: "grab",
        touchAction: "none",
      }}
    >
      <canvas
        ref={(ref) => {
          if (ref != null) {
            refView.current.setCanvas(ref);
          }
        }}
        style={{
          position: "absolute",
          zIndex: 1,
        }}
      />
      <canvas
        ref={(ref) => {
          if (ref != null) {
            refView.current.setCanvasHover(ref);
          }
        }}
        style={{
          position: "absolute",
          zIndex: 2,
        }}
      />
    </div>
  );
}
