import { useState } from "react";

import { Input, InputNumber, Button, Tag } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker } from "../utils/DatePicker";

import { S, css, combined, cssInvisible, cssTextError } from "../../styles/styles";

import { TagSelect, SearchResults, TagWithData } from "../utils/TagSelect";

import { useInitialRender } from "../../utils/react_utils";
import { zip } from "../../utils/funcs";

import { backend } from "../../backend";

import {
  Validated,
  ValidatedString,
  ValidatedDate,
  ValidatedNumber,
  validatorText,
  validatorUrl,
  validatorTagName,
  validatorTagRating,
} from "../../utils/validation";

const cssFormStatus = css(S.textColor("#777"), S.textSize(11), S.mt(4), S.mb(12));

const cssDatePickerRow = css(S.flex);
const cssDatePicker = css(S.block, S.flexGrow);

const cssFromRow = css(S.flex, S.items("center"));
const cssFormInput = css(S.flexGrow);
const cssFormInputNumber = css(S.ml(8));
const cssFormMinus = css(
  S.flexGrow0,
  S.ml(8),
  S.textSize(16),
  S.override(S.textColor(S.colorGray)),
  S.hover(S.textColor("#000")),
  S.transitionAll()
);

export type Setter<T> = (setter: ((old: T) => T) | T) => void;

export function HeadlineInput(props: {
  headline: ValidatedString;
  setHeadline: Setter<ValidatedString>;
}) {
  const [focus, setFocus] = useState<undefined | string>(undefined);
  return (
    <>
      <Input
        autoFocus
        placeholder="What has happened?"
        spellCheck={true}
        onChange={(evt) => props.setHeadline(props.headline.validate(evt.target.value))}
        onFocus={() => setFocus(props.headline.key)}
        onBlur={() => setFocus(undefined)}
      />
      <FormStatus hasFocus={focus === props.headline.key} {...props.headline} />
    </>
  );
}

export function DateInput(props: { date: ValidatedDate; setDate: Setter<ValidatedDate> }) {
  const [focus, setFocus] = useState<undefined | string>(undefined);
  return (
    <>
      <div {...cssDatePickerRow}>
        <DatePicker
          placeholder="When did it happen?"
          onFocus={() => setFocus(props.date.key)}
          onBlur={() => setFocus(undefined)}
          onChange={(newDate) => {
            props.setDate(props.date.validate(newDate));
          }}
          {...cssDatePicker}
        />
        <div {...cssDatePicker}></div>
      </div>
      <FormStatus hasFocus={focus === props.date.key} {...props.date} />
    </>
  );
}

export function ReferencesList(props: {
  references: ValidatedString[];
  setReferences: Setter<ValidatedString[]>;
}) {
  const [focus, setFocus] = useState<undefined | string>(undefined);

  // Needed to disable autoFocus on the initial rendering so that it doesn't
  // steal the focus of the headline input. In all subsequent renders, auto
  // focus should be the preferred behavior.
  const initialRendering = useInitialRender();

  const list =
    props.references.length > 0 ? (
      props.references.map((reference) => (
        <div key={reference.key}>
          <div {...cssFromRow}>
            <Input
              autoFocus={!initialRendering}
              placeholder="URL"
              onChange={(evt) =>
                props.setReferences((old) =>
                  old.map((ref) =>
                    ref.key === reference.key ? ref.validate(evt.target.value) : ref
                  )
                )
              }
              onFocus={() => setFocus(reference.key)}
              onBlur={() => setFocus(undefined)}
              {...cssFormInput}
            />
            <MinusCircleOutlined
              onClick={() =>
                props.setReferences((old) => old.filter((ref) => ref.key !== reference.key))
              }
              {...cssFormMinus}
            />
          </div>
          <FormStatus hasFocus={focus === reference.key} {...reference} />
        </div>
      ))
    ) : (
      <Spacer />
    );

  return (
    <>
      {list}
      {props.references.length < 5 ? (
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => props.setReferences((old) => [...old, Validated.create(validatorUrl, "")])}
          style={{ fontSize: "12px" }}
          size="small"
        >
          Add reference
        </Button>
      ) : null}
    </>
  );
}

export function BulletsList(props: {
  bulletPoints: ValidatedString[];
  setBulletPoints: Setter<ValidatedString[]>;
}) {
  const [focus, setFocus] = useState<undefined | string>(undefined);

  const list =
    props.bulletPoints.length > 0 ? (
      props.bulletPoints.map((bulletPoint) => (
        <div key={bulletPoint.key}>
          <div {...cssFromRow}>
            <Input
              autoFocus
              placeholder="Details"
              spellCheck={true}
              onChange={(evt) =>
                props.setBulletPoints((old) =>
                  old.map((bp) => (bp.key === bulletPoint.key ? bp.validate(evt.target.value) : bp))
                )
              }
              onFocus={() => setFocus(bulletPoint.key)}
              onBlur={() => setFocus(undefined)}
              {...cssFormInput}
            />
            <MinusCircleOutlined
              onClick={() =>
                props.setBulletPoints((old) => old.filter((bp) => bp.key !== bulletPoint.key))
              }
              {...cssFormMinus}
            />
          </div>
          <FormStatus hasFocus={focus === bulletPoint.key} {...bulletPoint} />
        </div>
      ))
    ) : (
      <Spacer />
    );

  return (
    <>
      {list}
      {props.bulletPoints.length < 5 ? (
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() =>
            props.setBulletPoints((old) => [...old, Validated.create(validatorText, "")])
          }
          style={{ fontSize: "12px" }}
          size="small"
        >
          Add bullet point
        </Button>
      ) : null}
    </>
  );
}

/*
For the TagsList the design breaks down a little bit, because values are tuples.
However, validating the data as tuples doesn't really make sense, because while
entering either the tag or the rating, the other one is obviously in an invalid
state temporarily. To keep the validation local to each input, the data tuples
have to be separated into their components. This however means that the concept
of having a single status bar per row does not match well, because two validated
values would require two individual status bars. Currently the status bar uses
a slightly hacky "multi" implementation that returns the first invalid status.
*/
export function TagsList(props: {
  tagNames: ValidatedString[];
  setTagNames: Setter<ValidatedString[]>;
  tagRatings: ValidatedNumber[];
  setTagRatings: Setter<ValidatedNumber[]>;
}) {
  const tags = zip(props.tagNames, props.tagRatings);

  const initialRendering = useInitialRender();

  const list =
    tags.length > 0 ? (
      tags.map(([tagName, tagRating], i) => (
        <TagRow
          key={tagName.key}
          initialRendering={initialRendering}
          onTagNameChange={(value) =>
            props.setTagNames((old) =>
              old.map((t) => (t.key === tagName.key ? tagName.validate(value) : t))
            )
          }
          onTagRatingChange={(value) => {
            props.setTagRatings((old) =>
              old.map((t) => (t.key === tagRating.key ? tagRating.validate(value) : t))
            );
          }}
          onDeleteRow={() => {
            props.setTagNames((old) => old.filter((t, j) => i !== j));
            props.setTagRatings((old) => old.filter((t, j) => i !== j));
          }}
          validatedTagName={tagName}
          validatedTagRating={tagRating}
        />
      ))
    ) : (
      <Spacer />
    );

  return (
    <>
      {list}
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => {
          props.setTagNames((old) => [...old, Validated.create(validatorTagName, "")]);
          props.setTagRatings((old) => [...old, Validated.create(validatorTagRating, 50)]);
        }}
        style={{ fontSize: "12px" }}
        size="small"
      >
        Add tag
      </Button>
    </>
  );
}

export function TagRow({
  initialRendering,
  onTagNameChange,
  onTagRatingChange,
  onDeleteRow,
  validatedTagName,
  validatedTagRating,
}: {
  initialRendering: boolean;
  onTagNameChange: (value: string) => void;
  onTagRatingChange: (value: number) => void;
  onDeleteRow: () => void;
  validatedTagName: ValidatedString;
  validatedTagRating: ValidatedNumber;
}) {
  const [focus, setFocus] = useState<undefined | string>(undefined);

  const search = async (s: string): Promise<SearchResults> => {
    const tags = await backend().tagsTop(s);
    if (tags.isOk) {
      const exactMatch =
        tags.data.filter((t) => t.tag.toLowerCase() === s.toLowerCase()).length > 0;
      const validationResult = validatedTagName.validate(s);
      const invalidReason =
        !validationResult.isValid && validationResult.immediate
          ? validatedTagName.validate(s).msg
          : undefined;
      return {
        entries: tags.data.map((t) => ({ tagKey: t.tag, auxData: undefined })),
        exactMatch,
        invalidReason,
      };
    } else {
      console.log("Request failed: " + tags.reqError || tags.appError);
      return {
        entries: [],
        exactMatch: false,
        invalidReason: undefined,
      };
    }
  };

  return (
    <div>
      <div {...cssFromRow}>
        <TagSelect
          mode="single"
          search={search}
          renderRow={(t: TagWithData) => <Tag>{t.tagKey}</Tag>}
          autoFocus={!initialRendering}
          onChange={onTagNameChange}
          onFocus={() => setFocus("tagName")}
          onBlur={() => setFocus(undefined)}
          {...cssFormInput}
        />
        <div {...cssFormInputNumber}>
          <InputNumber
            defaultValue={50}
            min={1}
            max={99}
            precision={0}
            onChange={onTagRatingChange}
            onFocus={() => setFocus("tagRating")}
            onBlur={() => setFocus(undefined)}
          />
        </div>
        <MinusCircleOutlined onClick={onDeleteRow} {...cssFormMinus} />
      </div>
      {/*<FormStatus hasFocus={focus === tagName.key} {...tagName} />*/}
      {/*<FormStatus hasFocus={focus === tagRating.key} {...tagRating} />*/}
      <MultiFormStatus
        validatedList={[validatedTagName, validatedTagRating]}
        focuses={[focus === "tagName", focus === "tagRating"]}
      />
    </div>
  );
}

function FormStatus({
  msg,
  isValid,
  immediate,
  hasFocus,
  initial,
}: {
  msg: string;
  isValid: boolean;
  immediate: boolean;
  hasFocus: boolean;
  initial: boolean;
}) {
  const visible = immediate || (!isValid && !hasFocus && !initial);
  return (
    <div
      {...combined(
        cssFormStatus,
        !isValid ? cssTextError : undefined,
        !visible ? cssInvisible : undefined
      )}
    >
      {msg.length > 0 ? msg : "\u200b"}
    </div>
  );
}

function MultiFormStatus({
  validatedList,
  focuses,
}: {
  validatedList: Validated<any, any>[];
  focuses: boolean[];
}) {
  // First pass: Prioritize invalid
  for (const [validated, focus] of zip(validatedList, focuses)) {
    if (!validated.isValid) {
      return <FormStatus hasFocus={focus} {...validated} />;
    }
  }
  // Second pass: Return first one
  for (const [validated, focus] of zip(validatedList, focuses)) {
    return <FormStatus hasFocus={focus} {...validated} />;
  }
  return null;
}

function Spacer() {
  return <div style={{ height: "12px" }} />;
}

// ----------------------------------------------------------------------------
// Misc utils
// ----------------------------------------------------------------------------

export const cssFormBody = css(S.spaceY(16));
export const cssFormLabel = css(
  S.mt(28),
  S.mb(8),
  S.spaceX(6),
  S.fontWeight(700),
  S.textColor(S.colorGray)
);
export const cssSubmitRow = css(S.flex, S.justify("end"), S.mt(40));

export function FormLabel(props: { label: string; tooltip: React.ReactElement }) {
  return (
    <div {...cssFormLabel}>
      <span>{props.label}</span>
      {props.tooltip}
    </div>
  );
}
