import { useState } from "react";

import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { RequireLoginModal } from "../../utils";
import { TagRow } from "../../form_utils/form_utils";

import { Validated, validatorTagName, validatorTagRating } from "../../../utils/validation";

import { backend } from "../../../backend";
import * as types from "../../../backend/types/api";

import { S, css } from "../../../styles/styles";

const cssTagEdit = css(S.mt(20));
const cssTagAddButtonRow = css(S.flex, S.justify("start"), S.mb(12));

type AddTagProps = {
  isAuthenticated: boolean;
  factId: string;
  addTagCallback: (tagName: string, tag: types.TagRelevanceWithUserVoting) => void;
};

export function AddTag({ isAuthenticated, factId, addTagCallback }: AddTagProps) {
  const [isEditing, setIsEditing] = useState(false);

  const initialTagName = () => Validated.create(validatorTagName, "");
  const initialTagRating = () => Validated.create(validatorTagRating, 50);

  const [tagName, setTagName] = useState(initialTagName());
  const [tagRating, setTagRating] = useState(initialTagRating());

  const onAddTag = async () => {
    if (tagName.isValid && tagRating.isValid) {
      const res = await backend().factAddTag(factId, tagName.value, tagRating.value);
      if (res.isOk) {
        const actualTagName = res.data.tag;
        addTagCallback(actualTagName, {
          tag: actualTagName,
          relevance: tagRating.value,
          voteKind: null,
        });
        setIsEditing(false);
      } else {
        Modal.error({
          title: "Adding tag failed",
          content: res.reqError || res.appError,
        });
      }
    } else {
      setTagName(tagName.makeNonInitial());
      setTagRating(tagRating.makeNonInitial());
    }
  };

  if (!isEditing) {
    return (
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => {
          if (!isAuthenticated) {
            RequireLoginModal("Voting on fact requires login.");
            return;
          } else {
            // Resetting the values has the benefit that the validators are reset to "initial" state.
            setTagName(initialTagName());
            setTagRating(initialTagRating());
            setIsEditing(true);
          }
        }}
        style={{ fontSize: "12px", marginTop: "12px" }}
        size="small"
      >
        Add tag
      </Button>
    );
  } else {
    return (
      <div {...cssTagEdit}>
        <TagRow
          initialRendering={false}
          onTagNameChange={(value) => {
            setTagName(tagName.validate(value));
          }}
          onTagRatingChange={(value) => {
            setTagRating(tagRating.validate(value));
          }}
          onDeleteRow={() => {
            setIsEditing(false);
          }}
          validatedTagName={tagName}
          validatedTagRating={tagRating}
        />
        <div {...cssTagAddButtonRow}>
          <Button type="primary" onClick={onAddTag}>
            Add tag
          </Button>
        </div>
      </div>
    );
  }
}
