import { LinearTransformParams } from "../../core/types";
import { paramsFromOffsetAndScale } from "../../core/linear_transform";

// TODO: Better names here...

// Also, cleanup of on-pixel vs between-pixel conventions.
// In general entities with `_LINE` suffixes are typically
// at half-pixel coordinates, because they are intended to
// be drawn with 1px width (which requires placement in-between).

export let geom = constructGeom();

export function updateGeom() {
  geom = constructGeom();
}

function constructGeom() {
  // Refers to the first vertical line between axis labels and bubble area.
  const xVertLine = 135.5;
  const xVertLineCeiled = Math.ceil(xVertLine);
  const xTickFrom = xVertLine - 5;
  const xTickUpto = xVertLine + 5;

  const xTicksRight = xVertLine - 10.5;

  // Refers to the second vertical line between bubble area and text area.
  const xVertLineMiddle = xVertLine + 180;
  const xVertLineMiddleFloored = Math.floor(xVertLineMiddle);

  const xFactLabels = Math.ceil(xVertLineMiddle + 20);

  const bubbleRadius = 14;
  const hoverRadius = bubbleRadius + 6;

  const factBoxHeight = 28;
  const factBoxHeightHalf = factBoxHeight / 2;
  const factBoxXOffset = 8;
  const factBoxXOffsetDoubled = factBoxXOffset * 2;

  // Should be seen in context of font-size (currently 14px).
  // Positive values shift labels down, negative up.
  const fontOffset = 1;

  const viewYExtend = 20;

  // Helper variables for transform
  const xMaxRelevance = xVertLineMiddleFloored - bubbleRadius;
  const xMinRelevance = Math.floor(xVertLine + 0.2 * (xVertLineMiddle - xVertLine)) + bubbleRadius;
  const xDeltaRelevance = (1 / 100) * (xMaxRelevance - xMinRelevance);

  const transformRelevanceToX: LinearTransformParams = paramsFromOffsetAndScale(
    xMinRelevance,
    xDeltaRelevance
  );

  return {
    xVertLine,
    xVertLineCeiled,
    xTickFrom,
    xTickUpto,
    xTicksRight,
    xVertLineMiddle,
    xVertLineMiddleFloored,
    xFactLabels,
    bubbleRadius,
    hoverRadius,
    factBoxHeight,
    factBoxHeightHalf,
    factBoxXOffset,
    factBoxXOffsetDoubled,
    fontOffset,
    transformRelevanceToX,
    viewYExtend,
  };
}
