import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";

export function Tooltip(props: { title: React.ReactElement; content: React.ReactElement }) {
  return (
    <Popover
      title={props.title}
      content={props.content}
      placement="right"
      overlayStyle={{
        width: "400px",
      }}
      // {color="#FAFAFA"}
    >
      <QuestionCircleOutlined />
    </Popover>
  );
}

export function TooltipHeadline() {
  const title = <b>What to put here?</b>;
  const content = (
    <span>
      <p>The headline of the news.</p>
      <p>The maximum length of a headline is 120 characters.</p>
    </span>
  );
  return <Tooltip title={title} content={content} />;
}

export function TooltipDate() {
  const title = <b>What to put here?</b>;
  const content = (
    <span>
      <p>
        <b>When it happened:</b> In case a time fact occurred at a clear and unambiguous point in
        time, this date should be reported. Examples are sporting events, press statements, election
        days, etc.
      </p>
      <p>
        <b>When it surfaced:</b> In other cases it isn't entirely obvious when the underlying event
        happened exactly. In these cases report the date when the news first surfaced in the media.
        Examples are scandals, disclosures of internal events (e.g. of a company), or simply news
        with a diffuse temporal definition.
      </p>
      <p>
        <b>If assigning a precise day is impossible:</b> Use the checkbox <i>precise day unknown</i>{" "}
        to express that it is not possible to assign an exact day. In this case it is optional to
        fill the month and day fields. A year is required as a bare minimum.
      </p>
      <p>
        <b>Does the timezone matter?</b> No. To keep things simple, chronwire doesn't care about
        exact time zones. Events should be reported in the local time zone of the event. If it is a
        truly global event, any date is fine.
      </p>
    </span>
  );
  return <Tooltip title={title} content={content} />;
}

export function TooltipReferences() {
  const title = <b>What to put here?</b>;
  const content = (
    <span>
      <p>A link to an external site that confirms the news and provides additional details.</p>
      <p>At least one reference is needed. Optionally, multiple references can be added.</p>
    </span>
  );
  return <Tooltip title={title} content={content} />;
}

export function TooltipTags() {
  const title = <b>What to put here?</b>;
  const content = (
    <span>
      <p>A news must be associated with at least one tag.</p>
      <p>
        The association with the a tag is reflected by a relevance value between 1 and 99. Put a
        larger number, if you feel the news has a high relevance in the context of the tags. Put a
        lower number, if you feel it is not very relevant.
      </p>
      <p>
        The given value is only used for initializing the relevance, and therefore not critical.
        Over time, the votes of the community will override the initial relevance.
      </p>
    </span>
  );
  return <Tooltip title={title} content={content} />;
}

export function TooltipBulletsPoints() {
  const title = <b>What to put here?</b>;
  const content = (
    <span>
      <p>Optionally, you can add details of the news in the form of short bullet points.</p>
      <p>The maximum length of a bullet point entry is 120 characters.</p>
    </span>
  );
  return <Tooltip title={title} content={content} />;
}
