import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { S, css } from "../../styles/styles";

const cssUserIconBackground = css(
  S.textColor(S.colorGray),
  S.bgColor(S.colorLightGray1),
  S.textSize(18),
  S.w(34),
  S.h(34),
  S.rounded("full"),
  // Using flex for centering icon on background: https://stackoverflow.com/a/37407965/1804173
  S.flex,
  S.justify("center"),
  S.items("center"),
  S.mr(12)
);

export function UserIcon() {
  return (
    <div {...cssUserIconBackground}>
      <FontAwesomeIcon icon={faUser} />
    </div>
  );
}

const cssUserIconBackgroundWhite = css(
  S.textColor(S.colorGray),
  S.bgColor("#FFF"),
  S.border(1, S.colorLightGray1),
  S.textSize(18),
  S.w(34),
  S.h(34),
  S.rounded("full"),
  // To avoid icon being shrunk within a flex
  S.flexShrink0,
  // Using flex for centering icon on background: https://stackoverflow.com/a/37407965/1804173
  S.flex,
  S.justify("center"),
  S.items("center"),
  S.mr(12)
);

export function UserIconWhite() {
  return (
    <div {...cssUserIconBackgroundWhite}>
      <FontAwesomeIcon icon={faUser} />
    </div>
  );
}
