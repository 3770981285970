import { S, css } from "../../styles/styles";

import { getRelevanceColor } from "../../utils/colors";

/*
const cssCommon = css(S.flex, S.items("baseline"), S.mb(-4));
const cssChron = css(S.textSize(19), S.fontWeight(700), S.textColor(getRelevanceColor(15)));
const cssWire = css(S.textSize(19), S.fontWeight(700), S.textColor(getRelevanceColor(90)));
*/
const cssCommon = css(S.inlineFlex, S.items("center"));
const cssChron = css(S.textSize(16), S.fontWeight(700), S.textColor(getRelevanceColor(15)));
const cssWire = css(S.textSize(16), S.fontWeight(700), S.textColor(getRelevanceColor(90)));

export function Logo() {
  return (
    <div {...cssCommon}>
      <span {...cssChron}>chron</span>
      <span {...cssWire}>wire</span>
    </div>
  );
}
