import React, { useState } from "react";

import { Form, Input, Button, Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";

import { S, css } from "./styles/styles";

import { Logo } from "./components/nav/Logo";

const cssButtonDark = css(
  S.inlineBlock,
  S.mx(8),
  S.py(4),
  S.px(16),
  S.rounded("full"),
  S.transitionAll(),
  S.h(34),
  S.whitespace("nowrap"),
  S.border(2, S.colorGrayMain),
  S.bgColor(S.colorGrayMain),
  S.textColor("#DDD"),
  S.inlineFlex,
  S.items("center")
);

/**
 * Just a pathetic pseudo auth to make deployment not immediately public.
 */
export function AuthForm({ children }: { children?: React.ReactNode }): React.ReactElement | null {
  const [auth, setAuth] = useState(false);

  const onFinish = (values: any) => {
    let password = values.password;
    if (password === "forfriends") {
      setAuth(true);
    }
  };

  if (!auth) {
    return (
      <Modal
        visible={true}
        title={
          <>
            <div {...cssButtonDark}>
              <Logo />
            </div>{" "}
            <span>Private Beta</span>
          </>
        }
        closable={false}
        footer={null}
      >
        <p>Welcome!</p>
        <p>
          Chronwire is currently running in private beta. Feel free to share the private beta
          password with friends, but don't post it in public just yet.
        </p>
        <p>
          Note: As of now, the site isn't running well on mobile yet, and is best experienced on
          desktops.
        </p>
        <p>If you'd like to get in touch:</p>
        <div style={{ margin: "20px auto", width: "100%", textAlign: "center" }}>
          <b>contact@chronwire.com</b>
        </div>

        <Form initialValues={{ password: "" }} onFinish={onFinish}>
          <Form.Item name="password" validateTrigger="onBlur">
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="beta password"
              autoFocus
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              Start
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else if (children != null) {
    return <>{children}</>;
  } else {
    return null;
  }
}
