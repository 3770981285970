import React from "react";

import { Link } from "react-router-dom";

import { S, css } from "../../styles/styles";

import { Session, SessionStates } from "../../types";

import { UserIcon } from "../utils/UserIcon";

import { Logo } from "./Logo";

const cssNav = css(
  S.h(48),
  S.bgColor(S.colorNavBackground),
  // avoid accidental flex shrinkage
  S.flexShrink0,
  `border-bottom: 1px solid ${S.colorLightGray1}`,
  "box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 1px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 8px 0px"
);

const cssContainer = css(
  S.hFull,
  S.container,
  // Following two lines as a work-around for nav overflow on mobiles...
  "max-width: 100%",
  S.overflowXAuto,
  S.mxAuto,
  S.flex,
  S.flexNowrap,
  S.justify("between"),
  S.items("center")
  // S.spaceX(4)
);

type NavProps = {
  session: SessionStates;
};

export function Nav({ session }: NavProps): React.ReactElement {
  let navInner: React.ReactElement;
  if (session === "guest") {
    navInner = <NavGuest />;
  } else {
    navInner = <NavLoggedIn session={session} />;
  }

  return (
    <header {...cssNav}>
      <div {...cssContainer}>{navInner}</div>
    </header>
  );
}

const cssButtonCommon = [
  S.inlineBlock,
  S.mx(8),
  S.py(4),
  S.px(16),
  S.rounded("full"),
  S.transitionAll(),
  S.h(34),
  S.whitespace("nowrap"),
];

const cssButtonDark = css(
  ...cssButtonCommon,
  S.border(2, S.colorGrayMain),
  S.bgColor(S.colorGrayMain),
  S.textColor("#DDD"),
  S.hover(S.textColor(S.colorWhiteFontHighlight), S.border(2, S.colorGrayMainDarkened))
);

const cssButtonLight = css(
  ...cssButtonCommon,
  S.border(2, S.colorGrayMain),
  S.textColor(S.colorGrayMain),
  S.hover(S.textColor(S.colorGrayMainDarkened), S.border(2, S.colorGrayMainDarkened))
);

function NavGuest() {
  return (
    <>
      <Link to="/" {...cssButtonDark}>
        <Logo />
      </Link>
      <Beta />

      <div style={{ flexGrow: 1 }}></div>

      <Link to="/about" {...cssButtonLight}>
        About
      </Link>
      <Link to="/login" {...cssButtonLight}>
        Log in
      </Link>
      <Link to="/register" {...cssButtonDark}>
        Sign up
      </Link>
    </>
  );
}

function NavLoggedIn({ session }: { session: Session }) {
  return (
    <>
      <Link to="/" {...cssButtonDark}>
        <Logo />
      </Link>
      <Beta />

      <div style={{ flexGrow: 1 }}></div>

      <UserBadge session={session} />

      <Link to="/about" {...cssButtonLight}>
        About
      </Link>
      <Link to="/post" {...cssButtonDark}>
        Post
      </Link>
    </>
  );
}

const cssUsername = css(
  S.textColor(S.colorGrayMain),
  S.mx(12),
  S.px(8),
  S.textSize(12),
  S.flex,
  S.items("center"),
  // make it clickable
  S.hover(S.bgColor(S.colorNavHighlight)),
  S.cursor("pointer"),
  S.hFull,
  S.transitionAll()
);

function UserBadge({ session }: { session: Session }) {
  return (
    <Link to={`/user/${session.username}`} {...cssUsername}>
      <UserIcon />
      <span>{session.username}</span>
    </Link>
  );
}

const cssBeta = css(
  S.textSize(14),
  S.fontWeight(700),
  S.ml(8),
  S.textColor("rgba(0, 0, 0, 0.4)"),
  "text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);"
);

function Beta() {
  return <span {...cssBeta}>BETA</span>;
}
