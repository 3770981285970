import { FactCore, FactsLayout, LayoutParams, MinZoomMap } from "./types";

import { TimeRange, extendRange } from "../utils/date_utils";

import { backend } from "../backend";

import type { FactsProvider } from "./facts_provider_interface";
import { computeLayout } from "./layout";

import Worker from "./worker";

const worker = new Worker();

const RANGE_EXTENSION_FULL = 1.0;
const RANGE_EXTENSION_SEMI = 0.8;
const ZOOM_RATIO_TRIGGER = 0.25;

type CachedTags = Record<string, true>;

type CachedQueryParams = {
  range: TimeRange;
  timePerPixel: number;
  tags: Record<string, true>;
};

function needsRangeUpdate(
  queriedRange: TimeRange,
  queriedZoom: number,
  cachedRange: TimeRange,
  cachedZoom: number,
  isExact: boolean
): boolean {
  return (
    queriedRange.from < cachedRange.from ||
    queriedRange.upto > cachedRange.upto ||
    (!isExact && queriedZoom / cachedZoom < ZOOM_RATIO_TRIGGER)
  );
}

function needsTagsUpdate(queriedTags: string[], cachedTags: CachedTags): boolean {
  // TODO: In the long term we could separate this into a "needs refetch" vs "needs filtering"
  // variant. Basically, if a queriedTag is removed, no re-fetching is needed (this logic
  // is already implemented below). Note however, this is required to refilter and recompute
  // the relevance scores, which would require to store the ungrouped facts, or including
  // of the tags/relevance arrays in the FactCore. For now let's keep things simple and
  // refetch whenever something changes.
  /*
  for (const queriedTag of queriedTags) {
    if (!(queriedTag in cachedTags)) {
      return true;
    }
  }
  return false;
  */
  const set1 = arrayToSet(queriedTags);
  const set2 = cachedTags;
  for (const key in set1) {
    if (!(key in set2)) {
      return true;
    }
  }
  for (const key in set2) {
    if (!(key in set1)) {
      return true;
    }
  }
  return false;
}

function arrayToSet(tags: string[]): CachedTags {
  const set: CachedTags = {};
  for (let i = 0; i < tags.length; ++i) {
    const tag = tags[i];
    set[tag] = true;
  }
  return set;
}

export class BackendFactsProvider implements FactsProvider {
  isLoading: boolean;
  failedRequestTracker: FailedRequestTracker;

  cachedParams: CachedQueryParams | undefined;
  cached?: {
    facts: FactCore[];
    isExact: boolean;
    bubbleMinZoomMap: MinZoomMap;
    textMinZoomMap: MinZoomMap;
  };

  constructor(private renderCallback: () => void) {
    this.isLoading = false;
    this.failedRequestTracker = new FailedRequestTracker();
  }

  async initiateRequest(range: TimeRange, tags: string[], layout: LayoutParams) {
    this.isLoading = true;

    // Note: We query a bit more than we actually store as the cached query param,
    // so that a request is triggered even before the query hits the boundaries,
    // and there is no need to recompute the range extension in each check.
    const rangeSemiExtended = extendRange(range, RANGE_EXTENSION_SEMI);
    const rangeFullExtended = extendRange(range, RANGE_EXTENSION_FULL);

    const result = await worker.processRequest(backend().url, rangeFullExtended, tags, layout);

    if (result != null) {
      this.cached = result;
      this.cachedParams = {
        range: rangeSemiExtended,
        timePerPixel: layout.timePerPixel,
        tags: arrayToSet(tags),
      };

      try {
        this.renderCallback();
      } catch (e) {
        console.log("Error in render callback.");
        console.log(e);
      }

      this.failedRequestTracker.reportSuccess();
    } else {
      this.failedRequestTracker.reportFailure();
    }

    this.isLoading = false;
  }

  getFacts(range: TimeRange, tags: string[], layout: LayoutParams): FactsLayout {
    if (this.needsUpdate(range, tags, layout)) {
      this.initiateRequest(range, tags, layout);
    }

    if (this.cached != null) {
      return computeLayout(
        this.cached.facts,
        range,
        layout,
        this.cached.bubbleMinZoomMap,
        this.cached.textMinZoomMap
      );
    } else {
      return { circles: [], markers: [] };
    }
  }

  private needsUpdate(range: TimeRange, tags: string[], layout: LayoutParams) {
    if (this.isLoading || this.failedRequestTracker.noMoreRequests()) {
      return false;
    } else {
      return (
        this.cached == null ||
        this.cachedParams == null ||
        needsRangeUpdate(
          range,
          layout.timePerPixel,
          this.cachedParams.range,
          this.cachedParams.timePerPixel,
          this.cached.isExact
        ) ||
        needsTagsUpdate(tags, this.cachedParams.tags)
      );
    }
  }
}

// Helper class to avoid re-requesting data indefinitely in case of a problem
// (possibly causing DoS-like request spamming).
class FailedRequestTracker {
  numConsecutiveErrors = 0;

  static MAX_ERRORS = 3;

  reportSuccess() {
    this.numConsecutiveErrors = 0;
  }

  reportFailure() {
    this.numConsecutiveErrors += 1;
    if (this.numConsecutiveErrors === FailedRequestTracker.MAX_ERRORS) {
      console.log(
        `Data requesting reached ${FailedRequestTracker.MAX_ERRORS} consecutive failures. Running no more requests.`
      );
    }
  }

  noMoreRequests() {
    return this.numConsecutiveErrors >= FailedRequestTracker.MAX_ERRORS;
  }
}
