// This follows the approach "Search and Select Users" in https://ant.design/components/select/

import { useState, useRef, useMemo } from "react";

import { Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";

export type ValueType = { key?: string; label: React.ReactNode; value: string };

export interface DebouncedSelectProps<T> extends Omit<SelectProps<T>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<T[]>;
  debounceTimeout?: number;
}

export function DebouncedSelect<T extends ValueType>({
  fetchOptions,
  debounceTimeout = 400,
  ...props
}: DebouncedSelectProps<T>) {
  const [fetching, setFetching] = useState(true);
  const [options, setOptions] = useState<T[]>([]);

  const fetchIdRef = useRef(0);

  const fetchDebounced = useMemo(() => {
    const loadOptions = (value: string) => {
      console.log("search called with:", value);

      setOptions([]);
      setFetching(true);

      fetchIdRef.current += 1;
      const fetchId = fetchIdRef.current;

      fetchOptions(value).then((newOptions) => {
        // Only store the result if no new request has been spawned.
        if (fetchId !== fetchIdRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<T>
      labelInValue
      filterOption={false}
      onSearch={fetchDebounced}
      onFocus={() => fetchDebounced("") /* TODO: here we actually don't want the debounce delay*/}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      showAction={["focus", "click"]}
    />
  );
}

// https://medium.com/@griffinmichl/implementing-debounce-in-javascript-eab51a12311e
function debounce<F extends (...args: any[]) => void>(func: F, wait: number) {
  let timeout: NodeJS.Timeout | undefined;
  return function (this: unknown, ...args: Parameters<F>) {
    const context = this;
    if (timeout != null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
