import React from "react";

import { Button } from "antd";

import { Window } from "./Window";

import { getRenderStats, resetRenderStats } from "../timeline/render_stats";

export function RenderStats() {
  const stats = getRenderStats();

  return (
    <Window>
      <table>
        <tbody>
          <tr>
            <td>Num calls</td>
            <td>{stats.numCalls}</td>
          </tr>
          <tr>
            <td>Delta (min)</td>
            <td>{stats.deltaMin}</td>
          </tr>
          <tr>
            <td>Delta (avg)</td>
            <td>{stats.deltaAvg}</td>
          </tr>
          <tr>
            <td>Delta (max)</td>
            <td>{stats.deltaMax}</td>
          </tr>
          <tr>
            <td>Layout % (min)</td>
            <td>{stats.layoutPctMin * 100}</td>
          </tr>
          <tr>
            <td>Layout % (avg)</td>
            <td>{stats.layoutPctAvg * 100}</td>
          </tr>
          <tr>
            <td>Layout % (max)</td>
            <td>{stats.layoutPctMax * 100}</td>
          </tr>
        </tbody>
      </table>
      <Button
        type="primary"
        onClick={() => {
          resetRenderStats();
        }}
        style={{ marginTop: "20px" }}
      >
        Reset
      </Button>
    </Window>
  );
}
