import { Form, Input, Button, Modal } from "antd";

import { Window } from "../utils/Window";

import { backend } from "../../backend";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type FormValues = {
  username: string;
  password: string;
};

export type LoginProps = {
  onLogin: (username: string) => void;
};

export function Login({ onLogin }: LoginProps) {
  const [form] = Form.useForm();

  const onFinish = async (values: FormValues) => {
    console.log("Login with", values);
    let res = await backend().usersLogin(values.username, values.password);
    console.log(res);
    if (res.isOk) {
      onLogin(values.username);
    } else {
      Modal.error({
        title: "Login failed",
        content: res.reqError || res.appError,
      });
    }
  };

  return (
    <div>
      <Window>
        <h1 style={{ fontSize: "28px" }}>Log in </h1>

        <Form {...formItemLayout} form={form} name="login" onFinish={onFinish} requiredMark={false}>
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Please enter your username.",
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Window>
    </div>
  );
}
