import { Distinct } from "./typescript_utils";

// ----------------------------------------------------------------------------
// Constants
// ----------------------------------------------------------------------------

export const MILLIS_PER_DAY = 1000 * 3600 * 24;
export const DAYS_PER_MILLI = 1 / MILLIS_PER_DAY;

export const DAYS_PER_WEEK = 7;
export const WEEKS_PER_DAY = 1 / DAYS_PER_WEEK;

export const DAYS_PER_YEAR = 365.25;
export const YEARS_PER_DAY = 1 / DAYS_PER_YEAR;

export const DAYS_PER_QUARTER = DAYS_PER_YEAR / 4;
export const QUARTERS_PER_DAY = 1 / DAYS_PER_YEAR;

export const MONTHS_PER_YEAR = 12;
export const YEARS_PER_MONTH = 1 / MONTHS_PER_YEAR;

export const DAYS_PER_MONTH = DAYS_PER_YEAR / MONTHS_PER_YEAR;
export const MONTHS_PER_DAY = 1 / DAYS_PER_MONTH;

export const MILLIS_PER_WEEK = MILLIS_PER_DAY * DAYS_PER_WEEK;
export const WEEKS_PER_MILLI = 1 / MILLIS_PER_WEEK;

export const MILLIS_PER_YEAR = MILLIS_PER_DAY * DAYS_PER_YEAR;
export const YEARS_PER_MILLI = 1 / MILLIS_PER_YEAR;

// ----------------------------------------------------------------------------
// UTC helpers
// ----------------------------------------------------------------------------

export type Timestamp = Distinct<number, "Timestamp">;

export function utcTimestamp(year: number, month?: number, day?: number): Timestamp {
  month = month == null ? 0 : month - 1;
  day = day == null ? 1 : day;

  // We again have to account for the year 0-99 special treatment, and again
  // it is a problem that the year 0 was a leapyear, but 1900 wasn't. This
  // means that date pairs X.Y.19ZZ and X.Y.ZZ do not have a fixed timestamp
  // offset. There are rather two different offsets for dates before 28.2.00
  // and all dates after, because of the leap day. In order to avoid having
  // to check for dates before/after and maintain two different time offsets,
  // it may be easier to adjust the date to a year that is a leapyear as well
  // like 400. Then it should be sufficient to adjust the timestamp by subtracting
  // the one offset between 1.1.0 and 1.1.400.
  let wasAdjusted = false;
  if (year >= 0 && year < 100) {
    year += 400;
    wasAdjusted = true;
  }

  let timestamp = Date.UTC(year, month, day);

  if (wasAdjusted) {
    /*
    // Snippet to determine offset:
    year400 = new Date(Date.UTC(400, 0))
    year0 = new Date(Date.UTC(0, 0))
    year0.setUTCFullYear(0, 0)
    year0.setUTCHours(0, 0, 0, 0)
    console.log(year400.getTime() - year0.getTime())
    */
    let offsetYear400 = 12622780800000;
    timestamp -= offsetYear400;
  }

  return timestamp as Timestamp;
}

// ----------------------------------------------------------------------------
// Parsing
// ----------------------------------------------------------------------------

export function utcTimestampFromString(s: string): Timestamp | undefined {
  const match = /^\s*(-?\d?\d?\d?\d?\d)-(\d\d)-(\d\d)\s*$/.exec(s);
  if (match != null) {
    const year = parseInt(match[1]);
    const month = parseInt(match[2]);
    const day = parseInt(match[3]);
    if (month < 1 || month > 12) {
      return undefined;
    }
    if (day < 1) {
      return undefined;
    }
    return utcTimestamp(year, month, day);
  }
}

// ----------------------------------------------------------------------------
// Default TimeRange construction
// ----------------------------------------------------------------------------

export function utcTimestampNow(): Timestamp {
  let date = new Date();
  return date.getTime() as Timestamp;
}

export function getFutureTimestamp(): Timestamp {
  let now = utcTimestampNow();
  return ceilToDay(now);
}

export function getDefaultTimeRange(): TimeRange {
  // For now return ~3 months to slowly put the focus on news.
  let upto = getFutureTimestamp();
  let from = (upto - MILLIS_PER_WEEK * 12) as Timestamp;
  return { from, upto };
}

export function getLastWeek(): TimeRange {
  let upto = getFutureTimestamp();
  let from = (upto - MILLIS_PER_WEEK) as Timestamp;
  return { from, upto };
}

export function getLastMonth(): TimeRange {
  let upto = getFutureTimestamp();
  let from = (upto - MILLIS_PER_WEEK * 4) as Timestamp;
  return { from, upto };
}

export function getLastYear(): TimeRange {
  let upto = getFutureTimestamp();
  let from = (upto - MILLIS_PER_YEAR) as Timestamp;
  return { from, upto };
}

export function extendRange(range: TimeRange, extension = 1.0): TimeRange {
  const delta = (range.upto - range.from) * extension;
  return {
    from: (range.from - delta) as Timestamp,
    upto: (range.upto + delta) as Timestamp,
  };
}

// ----------------------------------------------------------------------------
// DateFields
// ----------------------------------------------------------------------------

export type DateFields = {
  year: number;
  month: number;
  day: number;
};

export type FullDateFields = DateFields & {
  hours: number;
  minutes: number;
  seconds: number;
  millis: number;
};

export function toDateFields(timestamp: Timestamp): DateFields {
  let date = new Date(timestamp);
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
  };
}

export function toFullDateFields(timestamp: Timestamp): FullDateFields {
  let date = new Date(timestamp);
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
    millis: date.getUTCMilliseconds(),
  };
}

export function toUTCTimestamp(date: DateFields) {
  return utcTimestamp(date.year, date.month, date.day);
}

// ----------------------------------------------------------------------------
// Date math
// ----------------------------------------------------------------------------

// http://howardhinnant.github.io/date_algorithms.html
// https://stackoverflow.com/questions/7960318/math-to-convert-seconds-since-1970-into-date-and-vice-versa

export function getDaysFromEpoch(date: DateFields): number {
  let y = date.year;
  let m = date.month;
  let d = date.day;
  y -= m <= 2 ? 1 : 0;
  let era = Math.floor(y / 400);
  let yoe = Math.floor(y - era * 400); // [0, 399]
  let doy = Math.floor((153 * (m + (m > 2 ? -3 : 9)) + 2) / 5) + d - 1; // [0, 365]
  let doe = yoe * 365 + Math.floor(yoe / 4) - Math.floor(yoe / 100) + doy; // [0, 146096]
  // console.log(era, yoe, doy, doe);
  return era * 146097 + doe - 719468;
}

export function getWeekday(date: DateFields): number {
  let days = getDaysFromEpoch(date);
  // The condition accounts for % using truncated divsion internally.
  // https://en.wikipedia.org/wiki/Modulo_operation
  // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
  // The original paper was using:
  // return days >= -4 ? (days + 4) % 7 : ((days + 5) % 7) + 6;
  // But this simplified expression should do the job as well:
  return (((days + 4) % 7) + 7) % 7;
}

// ----------------------------------------------------------------------------
// Simple adding
// ----------------------------------------------------------------------------

export function addMonths(date: DateFields, x: number): DateFields {
  let tmp = date.month - 1 + x;
  let month = (tmp % 12) + 1;
  if (month <= 0) {
    month += 12;
  }
  let year = date.year + Math.floor(tmp / 12);
  return { year: year, month: month, day: date.day };
}

export function addQuarters(date: DateFields, x: number): DateFields {
  return addMonths(date, 3 * x);
}

export function addYears(date: DateFields, x: number): DateFields {
  return { year: date.year + x, month: date.month, day: date.day };
}

// ----------------------------------------------------------------------------
// Other formatters
// ----------------------------------------------------------------------------

export function formatDateDefault(d: Date): string {
  function pad(n: number): string {
    return n < 10 ? "0" + n : n.toString();
  }
  return d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate());
}

export function formatDateFull(d: Date): string {
  function pad(n: number): string {
    return n < 10 ? "0" + n : n.toString();
  }
  return (
    d.getFullYear() +
    "-" +
    pad(d.getMonth() + 1) +
    "-" +
    pad(d.getDate()) +
    "  @  " +
    pad(d.getHours()) +
    ":" +
    pad(d.getMinutes()) +
    ":" +
    pad(d.getSeconds())
  );
}

/*
In general it would be nice to use something like date-fns here.

https://date-fns.org/v2.16.1/docs/format

However:
- It doesn't really support formatting UTC dates, only via hacks or the
  full date-fns-tzn dependency.
- We only need a tiny fraction of the formatting features.

Conclusion: For now let's implement our own formatting.
*/

// TODO: Could be moved into some more general utils?
function getLocale() {
  if (typeof window !== "undefined") {
    return navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  } else {
    return "en-us";
  }
}

function getMonthName(i: number, locale: string): string {
  return new Date(2000, i - 1, 1).toLocaleString(locale, { month: "long" });
}

function initializeMonthNameLookup(): { [month: number]: string } {
  let locale = getLocale();
  let names: { [month: number]: string } = {};
  for (let i = 1; i <= 12; ++i) {
    names[i] = getMonthName(i, locale);
  }
  return names;
}

const MONTH_NAME_LOOKUP = initializeMonthNameLookup();

export function formatToDay(d: DateFields): string {
  return `${d.day} ${MONTH_NAME_LOOKUP[d.month]} ${d.year}`;
}

export function formatToMonth(d: DateFields): string {
  return `${MONTH_NAME_LOOKUP[d.month]} ${d.year}`;
}

export function formatToQuarter(d: DateFields): string {
  return `${MONTH_NAME_LOOKUP[floorMonthToQuarter(d.month)]} ${d.year}`;
}

export function formatToYear(d: DateFields): string {
  return `${d.year}`;
}

// ----------------------------------------------------------------------------
// Time Range
// ----------------------------------------------------------------------------

export type TimeRange = {
  from: Timestamp;
  upto: Timestamp;
};

export function isRangeValid(range: TimeRange): boolean {
  return isFinite(range.from) && isFinite(range.upto) && range.from <= range.upto;
}

export function isRangeBelowMaxZoomLevel(range: TimeRange, visiblePixels: number): boolean {
  let diffYears = getYearsDiffApprox(range.upto, range.from);
  let yearsPerPixel = diffYears / visiblePixels;
  // Allowing upto 5 years-per-pixel means that with a visible height of 1000 pixels
  // a total range of 5 years can be covered. This should suffice.
  return yearsPerPixel < 5;
}

export function shiftRange(range: TimeRange, deltaTimestamp: number): TimeRange {
  return {
    from: (range.from + deltaTimestamp) as Timestamp,
    upto: (range.upto + deltaTimestamp) as Timestamp,
  };
}

// ----------------------------------------------------------------------------
// Flooring/Ceiling
// ----------------------------------------------------------------------------

export function floorToMultipleOf(x: number, multipleOf: number): number {
  return Math.floor(x / multipleOf) * multipleOf;
}

export function floorMonthToQuarter(month: number): number {
  return Math.floor((month - 1) / 3) * 3 + 1;
}

// Fixed time floor/ceil (return timestamp only)

export function floorToDay(t: Timestamp): Timestamp {
  // TODO: Check if it is actually possible to just floor/ceil to multiples
  // of 24 * 3600 * 1000 instead of having to convert to/from date fields.
  let d = toDateFields(t);
  let tFloored = toUTCTimestamp(d);
  return tFloored;
}

export function ceilToDay(t: Timestamp): Timestamp {
  let floored = floorToDay(t);
  if (floored === t) {
    return t;
  } else {
    return (floored + MILLIS_PER_DAY) as Timestamp;
  }
}

export function floorToWeek(t: Timestamp): Timestamp {
  let d = toDateFields(t);
  let weekday = getWeekday(d);
  let tFloored = (toUTCTimestamp(d) - weekday * MILLIS_PER_DAY) as Timestamp;
  return tFloored;
}

export function ceilToWeek(t: Timestamp): Timestamp {
  let floored = floorToWeek(t);
  if (floored === t) {
    return t;
  } else {
    return (floored + 7 * MILLIS_PER_DAY) as Timestamp;
  }
}

// Variable time floor/ceil (return timestamp + date)

export type DateAndTimestamp = {
  date: DateFields;
  timestamp: Timestamp;
};

export function floorToMonth(t: Timestamp): DateAndTimestamp {
  let date = toDateFields(t);
  date.day = 1;
  let tFloored = toUTCTimestamp(date);
  return { date, timestamp: tFloored };
}

export function ceilToMonth(t: Timestamp) {
  let floored = floorToMonth(t);
  if (floored.timestamp === t) {
    return floored;
  } else {
    let date = addMonths(floored.date, 1);
    return { date, timestamp: toUTCTimestamp(date) };
  }
}

export function floorToQuarter(t: Timestamp): DateAndTimestamp {
  let date = toDateFields(t);
  date.month = floorMonthToQuarter(date.month);
  date.day = 1;
  let tFloored = toUTCTimestamp(date);
  return { date, timestamp: tFloored };
}

export function ceilToQuarter(t: Timestamp) {
  let floored = floorToQuarter(t);
  if (floored.timestamp === t) {
    return floored;
  } else {
    let date = addQuarters(floored.date, 1);
    return { date, timestamp: toUTCTimestamp(date) };
  }
}

export function floorToYear(t: Timestamp, multipleOf = 1): DateAndTimestamp {
  let date = toDateFields(t);
  date.year = floorToMultipleOf(date.year, multipleOf);
  date.month = 1;
  date.day = 1;
  let tFloored = toUTCTimestamp(date);
  return { date, timestamp: tFloored };
}

export function ceilToYear(t: Timestamp, multipleOf = 1) {
  let floored = floorToYear(t, multipleOf);
  if (floored.timestamp === t) {
    return floored;
  } else {
    let date = addYears(floored.date, multipleOf);
    return { date, timestamp: toUTCTimestamp(date) };
  }
}

// ----------------------------------------------------------------------------
// Label creation
// ----------------------------------------------------------------------------

export type DateLabel = {
  timestamp: Timestamp;
  text: string;
  isOdd: boolean;
};

// construct helpers

function constructDateLabelToDay(timestamp: Timestamp): DateLabel {
  const dateFields = toDateFields(timestamp);
  return { timestamp, text: formatToDay(dateFields), isOdd: dateFields.day % 2 === 1 };
}

function constructDateLabelToWeek(timestamp: Timestamp): DateLabel {
  const dateFields = toDateFields(timestamp);
  return { timestamp, text: formatToDay(dateFields), isOdd: dateFields.day % 2 === 1 };
}

function constructDateLabelToMonth(tsDate: DateAndTimestamp): DateLabel {
  return {
    timestamp: tsDate.timestamp,
    text: formatToMonth(tsDate.date),
    isOdd: (tsDate.date.month - 1) % 2 === 1,
  };
}

function constructDateLabelToQuarter(tsDate: DateAndTimestamp): DateLabel {
  return {
    timestamp: tsDate.timestamp,
    text: formatToQuarter(tsDate.date),
    isOdd: Math.floor(tsDate.date.month / 3) % 2 === 1,
  };
}

function constructDateLabelToYear(tsDate: DateAndTimestamp, multipleOf: number): DateLabel {
  return {
    timestamp: tsDate.timestamp,
    text: formatToYear(tsDate.date),
    isOdd: Math.floor(tsDate.date.year / multipleOf) % 2 === 1,
  };
}

// "between" variants, all timestamps satisfy `from <= ts <= upto`

export function genDaysBetween(range: TimeRange): DateLabel[] {
  let timestamp = ceilToDay(range.from);
  let endTimestamp = range.upto;

  let dates = [] as DateLabel[];

  while (timestamp <= endTimestamp) {
    dates.push(constructDateLabelToDay(timestamp));
    timestamp = (timestamp + MILLIS_PER_DAY) as Timestamp;
  }

  return dates;
}

export function genWeeksBetween(range: TimeRange): DateLabel[] {
  let timestamp = ceilToWeek(range.from);
  let endTimestamp = range.upto;

  let dates = [] as DateLabel[];

  while (timestamp <= endTimestamp) {
    dates.push(constructDateLabelToWeek(timestamp));
    timestamp = (timestamp + MILLIS_PER_WEEK) as Timestamp;
  }

  return dates;
}

export function genMonthsBetween(range: TimeRange): DateLabel[] {
  let current = ceilToMonth(range.from);
  let endTimestamp = range.upto;

  let dates = [] as DateLabel[];

  while (current.timestamp <= endTimestamp) {
    dates.push(constructDateLabelToMonth(current));
    let nextDate = addMonths(current.date, 1);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
  }

  return dates;
}

export function genQuartersBetween(range: TimeRange): DateLabel[] {
  let current = ceilToQuarter(range.from);
  let endTimestamp = range.upto;

  let dates = [] as DateLabel[];

  while (current.timestamp <= endTimestamp) {
    dates.push(constructDateLabelToQuarter(current));
    let nextDate = addQuarters(current.date, 1);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
  }

  return dates;
}

export function genYearsBetween(range: TimeRange, multipleOf = 1): DateLabel[] {
  let current = ceilToYear(range.from, multipleOf);
  let endTimestamp = range.upto;

  let dates = [] as DateLabel[];

  while (current.timestamp <= endTimestamp) {
    dates.push(constructDateLabelToYear(current, multipleOf));
    let nextDate = addYears(current.date, multipleOf);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
  }

  return dates;
}

// "enclosing" variants

export function genDaysEnclosing(range: TimeRange): DateLabel[] {
  let timestamp = floorToDay(range.from);
  let endTimestamp = range.upto;

  let dates = [constructDateLabelToDay(timestamp)];

  while (timestamp < endTimestamp) {
    timestamp = (timestamp + MILLIS_PER_DAY) as Timestamp;
    dates.push(constructDateLabelToDay(timestamp));
  }

  return dates;
}

export function genWeeksEnclosing(range: TimeRange): DateLabel[] {
  let timestamp = floorToWeek(range.from);
  let endTimestamp = range.upto;

  let dates = [constructDateLabelToWeek(timestamp)];

  while (timestamp < endTimestamp) {
    timestamp = (timestamp + MILLIS_PER_WEEK) as Timestamp;
    dates.push(constructDateLabelToWeek(timestamp));
  }

  return dates;
}

export function genMonthsEnclosing(range: TimeRange): DateLabel[] {
  let current = floorToMonth(range.from);
  let endTimestamp = range.upto;

  let dates = [constructDateLabelToMonth(current)];

  while (current.timestamp < endTimestamp) {
    let nextDate = addMonths(current.date, 1);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
    dates.push(constructDateLabelToMonth(current));
  }

  return dates;
}

export function genQuartersEnclosing(range: TimeRange): DateLabel[] {
  let current = floorToQuarter(range.from);
  let endTimestamp = range.upto;

  let dates = [constructDateLabelToQuarter(current)];

  while (current.timestamp < endTimestamp) {
    let nextDate = addQuarters(current.date, 1);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
    dates.push(constructDateLabelToQuarter(current));
  }

  return dates;
}

export function genYearsEnclosing(range: TimeRange, multipleOf = 1): DateLabel[] {
  let current = floorToYear(range.from, multipleOf);
  let endTimestamp = range.upto;

  let dates = [constructDateLabelToYear(current, multipleOf)];

  while (current.timestamp < endTimestamp) {
    let nextDate = addYears(current.date, multipleOf);
    current = { date: nextDate, timestamp: toUTCTimestamp(nextDate) };
    dates.push(constructDateLabelToYear(current, multipleOf));
  }

  return dates;
}

export type RecommendedTicks = {
  main: DateLabel[];
  upper: DateLabel[];
  lower: DateLabel[] | undefined;
  lowerScore: number | undefined;
};

export type LevelDays = { kind: "days" };
export type LevelWeeks = { kind: "weeks" };
export type LevelMonths = { kind: "months" };
export type LevelQuarters = { kind: "quarters" };
export type levelYears = { kind: "years"; level: NiceLevel };
export type Level = LevelDays | LevelWeeks | LevelMonths | LevelQuarters | levelYears;

function getUpperLevel(level: Level): Level {
  switch (level.kind) {
    case "days": {
      // return { kind: "weeks" };
      return { kind: "months" };
    }
    case "weeks": {
      return { kind: "months" };
    }
    case "months": {
      // return { kind: "quarters" };
      return { kind: "years", level: 0 as NiceLevel };
    }
    case "quarters": {
      return { kind: "years", level: 0 as NiceLevel };
    }
    case "years": {
      // return { kind: "years", level: (level.level + 1) as NiceLevel };
      const nextMultipleOfTen = Math.ceil((level.level + 1) / 3) * 3;
      return { kind: "years", level: nextMultipleOfTen as NiceLevel };
    }
  }
}

function getLowerLevel(level: Level): Level | undefined {
  switch (level.kind) {
    case "days": {
      return undefined;
    }
    case "weeks": {
      return { kind: "days" };
    }
    case "months": {
      return { kind: "weeks" };
    }
    case "quarters": {
      return { kind: "months" };
    }
    case "years": {
      if (level.level === 0) {
        return { kind: "quarters" };
      } else if (isFiveCase(level.level)) {
        // If we have `0 5 10` labels, it looks ugly to have `0 2 4 6 8 10` sublabels.
        // I think it is better to have no sublabel system is this case at all.
        return undefined;
      } else {
        return { kind: "years", level: (level.level - 1) as NiceLevel };
      }
    }
  }
}

function computeRecommendedLevel(daysDiff: number, maxTicks: number): Level {
  if (daysDiff < maxTicks) {
    return { kind: "days" };
  }

  let weeksDiff = daysDiff * WEEKS_PER_DAY;
  if (weeksDiff < maxTicks) {
    return { kind: "weeks" };
  }

  let monthsDiff = daysDiff * MONTHS_PER_DAY;
  if (monthsDiff < maxTicks) {
    return { kind: "months" };
  }

  let quartersDiff = monthsDiff / 3;
  if (quartersDiff < maxTicks) {
    return { kind: "quarters" };
  }

  let numTicks = quartersDiff / 4;
  let level = getSmallestNiceLevel(numTicks, maxTicks);
  // Note that the smallest nice number could even be less than 1 year
  // theoretically, but that will neither look nice for years, nor does
  // genYearsBetween work (because internally in date-fns the increment
  // number gets floored to zero, turning the function into an infinite
  // loop).
  if (level < 0) {
    level = 0 as NiceLevel;
  }
  return { kind: "years", level };
}

function computeRecommendedTicksSingle(range: TimeRange, level: Level): DateLabel[] {
  switch (level.kind) {
    case "days": {
      return genDaysEnclosing(range);
    }
    case "weeks": {
      return genWeeksEnclosing(range);
    }
    case "months": {
      return genMonthsEnclosing(range);
    }
    case "quarters": {
      return genQuartersEnclosing(range);
    }
    case "years": {
      let multipleOf = convertToNiceStepSize(level.level);
      return genYearsEnclosing(range, multipleOf);
    }
  }
}

function computeVisibilityScore(level: Level, daysDiff: number, maxTicks: number): number {
  let divisor: number;
  switch (level.kind) {
    case "days": {
      divisor = 1;
      break;
    }
    case "weeks": {
      divisor = DAYS_PER_WEEK;
      break;
    }
    case "months": {
      divisor = DAYS_PER_MONTH;
      break;
    }
    case "quarters": {
      divisor = DAYS_PER_QUARTER;
      break;
    }
    case "years": {
      let multipleOf = convertToNiceStepSize(level.level);
      divisor = DAYS_PER_YEAR * multipleOf;
      break;
    }
  }
  let ticks = daysDiff / divisor;
  // For a sub level, the ration must be >= 1 because the ticks have exceeded maxTicks.
  let ratio = ticks / maxTicks;
  // Idea: 1 - (ratio - 1)
  let score = 2 - ratio;
  if (score < 0) {
    score = 0;
  }
  return score;
}

export function computeRecommendedTicks(range: TimeRange, visiblePixels: number): RecommendedTicks {
  // Reasonable scaling system:
  // (Hours) => Days => (Weeks) => Months => Quarter => Year
  // And from that point on:
  // 1 year, 2 years, 5 years, 10 year, 20 years, 50 years, 100 years, ...

  // let densityTimePerPixel = (dateUpto.getTime() - range.from.getTime()) / visiblePixels;
  // console.log(densityTimePerPixel, visiblePixels);

  let TARGET_PIXEL_PER_GROUP = 50;

  let maxTicks = visiblePixels / TARGET_PIXEL_PER_GROUP;
  if (maxTicks <= 0) {
    return { main: [], upper: [], lower: undefined, lowerScore: undefined };
  }
  // console.log("Target max ticks:", maxTicks);

  let daysDiff = getDaysDiff(range.upto, range.from);
  let mainLevel = computeRecommendedLevel(daysDiff, maxTicks);

  let lowerLevel = getLowerLevel(mainLevel);
  let upperLevel = getUpperLevel(mainLevel);

  return {
    main: computeRecommendedTicksSingle(range, mainLevel),
    upper: computeRecommendedTicksSingle(range, upperLevel),
    lower: lowerLevel != null ? computeRecommendedTicksSingle(range, lowerLevel) : undefined,
    lowerScore:
      lowerLevel != null ? computeVisibilityScore(lowerLevel, daysDiff, maxTicks) : undefined,
  };
}

// ----------------------------------------------------------------------------
// Misc helpers
// ----------------------------------------------------------------------------

export type NiceLevel = Distinct<number, "NiceLevel">;

/**
 * Returns the smallest "nice" step size that satisfies:
 *
 *    numTicks / niceStep <= maxTicks
 *
 * where nice refers to numbers of the sequence:
 * ..., 1, 2, 5, 10, 20, 50, 100, ...
 *
 * See tests for a more illustrative reference implementation.
 *
 * The niceStep gets returned encoded as a level for easier
 * transition between levels.
 */
function getSmallestNiceLevel(numTicks: number, maxTicks: number): NiceLevel {
  let ratio = numTicks / maxTicks;

  let exponent = Math.floor(Math.log10(ratio));
  let fraction = ratio / Math.pow(10, exponent);

  // The `<=` corresponds to breaking on `num_ticks <= max_ticks` in the iterative version,
  // i.e., `num_tick < max_ticks` could be achieved by changing to `<` here.
  let levelOffset: number;
  if (fraction <= 1.0) {
    // case: fractionCeiled = 1
    levelOffset = 0;
  } else if (fraction <= 2.0) {
    // case: fractionCeiled = 2
    levelOffset = 1;
  } else if (fraction <= 5.0) {
    // case: fractionCeiled = 5
    levelOffset = 2;
  } else {
    // case: fractionCeiled = 1
    levelOffset = 0;
    exponent += 1;
  }

  // niceStep could now be computed via `fractionCeiled * Math.pow(10, exponent)`
  // but we postpone the conversion and encode the intermediate result into a level.
  return (exponent * 3 + levelOffset) as NiceLevel;
}

function convertToNiceStepSize(level: NiceLevel): number {
  let exponent = Math.floor(level / 3);
  let levelOffset = level - exponent * 3;
  switch (levelOffset) {
    case 0: {
      return 1 * Math.pow(10, exponent);
    }
    case 1: {
      return 2 * Math.pow(10, exponent);
    }
    case 2: {
      return 5 * Math.pow(10, exponent);
    }
    default: {
      console.log("ERROR: Impossible level offset: " + levelOffset);
      return 1;
    }
  }
}

export function isOneCase(level: NiceLevel): boolean {
  let levelOffset = ((level % 3) + 3) % 3;
  return levelOffset === 0;
}

export function isTwoCase(level: NiceLevel): boolean {
  let levelOffset = ((level % 3) + 3) % 3;
  return levelOffset === 1;
}

export function isFiveCase(level: NiceLevel): boolean {
  let levelOffset = ((level % 3) + 3) % 3;
  return levelOffset === 2;
}

export function getDaysDiff(a: Timestamp, b: Timestamp): number {
  let diffMillis = a - b;
  return diffMillis * DAYS_PER_MILLI;
}

/**
 * Note that it doesn't really make sense to express a difference
 * in years, because a year doesn't have a well defined length anyway.
 * This should only be used express a difference in millisecond
 * in a somewhat more human friendly unit.
 */
export function getYearsDiffApprox(a: Timestamp, b: Timestamp): number {
  let diffMillis = a - b;
  return diffMillis * YEARS_PER_MILLI;
}

export const _private = {
  getSmallestNiceLevel: getSmallestNiceLevel,
  convertToNiceStepSize: convertToNiceStepSize,
};
