import { FormEvent, useState } from "react";

import { Button, Modal } from "antd";

import { useHistory } from "react-router-dom";

import { Window, RequireLogin } from "../utils";
import * as tooltips from "../utils/tooltips";

import { S, css } from "../../styles/styles";

import { zip } from "../../utils/funcs";

import { backend } from "../../backend";

import { SessionStates } from "../../types";

import {
  Validated,
  ValidatedString,
  validatorText,
  validatorDate,
  validatorUrl,
  validatorTagName,
  validatorTagRating,
} from "../../utils/validation";

import {
  HeadlineInput,
  DateInput,
  ReferencesList,
  BulletsList,
  TagsList,
  FormLabel,
  cssFormBody,
  cssSubmitRow,
} from "../form_utils/form_utils";

const cssRequireLogin = css(S.my(48));

export function Post({ session }: { session: SessionStates }) {
  if (session === "guest") {
    return (
      <div {...cssRequireLogin}>
        <RequireLogin message="Posting facts requires to be logged in." />
      </div>
    );
  } else {
    return <PostLoggedIn />;
  }
}

export function PostLoggedIn() {
  const history = useHistory();

  const [headline, setHeadline] = useState(() => Validated.create(validatorText, ""));
  const [date, setDate] = useState(() => Validated.create(validatorDate, null));

  const [references, setReferences] = useState(() => [Validated.create(validatorUrl, "")]);
  const [bulletPoints, setBulletPoints] = useState<ValidatedString[]>([]);

  const [tagNames, setTagNames] = useState(() => [Validated.create(validatorTagName, "")]);
  const [tagRatings, setTagRatings] = useState(() => [Validated.create(validatorTagRating, 50)]);

  const tags = zip(tagNames, tagRatings);

  function checkAllValid() {
    if (!headline.isValid) {
      return false;
    }
    if (!date.isValid) {
      return false;
    }
    for (const reference of references) {
      if (!reference.isValid) {
        return false;
      }
    }
    for (const bulletPoint of bulletPoints) {
      if (!bulletPoint.isValid) {
        return false;
      }
    }
    for (const tagName of tagNames) {
      if (!tagName.isValid) {
        return false;
      }
    }
    for (const tagRating of tagRatings) {
      if (!tagRating.isValid) {
        return false;
      }
    }
    return true;
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    // console.log(headline);
    // console.log(date);

    const allValid = checkAllValid();

    if (allValid && date.value != null) {
      const dateString = date.value.format("YYYY-MM-DD");

      const convertedTags = tags.map(([tagName, tagRating]) => ({
        tag: tagName.value,
        relevance: tagRating.value,
      }));
      const convertedReferences = references.map((ref) => ref.value);
      const convertedBulletPoints = bulletPoints.map((bp) => bp.value);

      let res = await backend().factsAdd(
        headline.value,
        dateString,
        convertedTags,
        convertedBulletPoints,
        convertedReferences
      );

      if (res.isOk) {
        // TODO: Call to preloaded
        history.replace(`/fact/${res.data.factId}`);
      } else {
        Modal.error({
          title: "Post failed",
          content: res.reqError || res.appError,
        });
      }
    } else {
      // If submit is triggered in an incomplete/invalid state, reveal
      // all errors by removing the `initial` flag.
      setHeadline(headline.makeNonInitial());
      setDate(date.makeNonInitial());
      setReferences((old) => old.map((ref) => ref.makeNonInitial()));
      setBulletPoints((old) => old.map((bp) => bp.makeNonInitial()));
      setTagNames((old) => old.map((t) => t.makeNonInitial()));
      setTagRatings((old) => old.map((t) => t.makeNonInitial()));
    }
  };

  return (
    <div>
      <Window>
        <form onSubmit={onSubmit}>
          <h2>Post a new time fact</h2>
          <div {...cssFormBody}>
            <div>
              <FormLabel label="Headline" tooltip={<tooltips.TooltipHeadline />} />
              <HeadlineInput headline={headline} setHeadline={setHeadline} />
            </div>

            <div>
              <FormLabel label="Date" tooltip={<tooltips.TooltipDate />} />
              <DateInput date={date} setDate={setDate} />
            </div>

            <div>
              <FormLabel label="References" tooltip={<tooltips.TooltipReferences />} />
              <ReferencesList references={references} setReferences={setReferences} />
            </div>

            <div>
              <FormLabel label="Tags" tooltip={<tooltips.TooltipTags />} />
              <TagsList
                tagNames={tagNames}
                setTagNames={setTagNames}
                tagRatings={tagRatings}
                setTagRatings={setTagRatings}
              />
            </div>

            <div>
              <FormLabel label="Bullet points" tooltip={<tooltips.TooltipBulletsPoints />} />
              <BulletsList bulletPoints={bulletPoints} setBulletPoints={setBulletPoints} />
            </div>

            <div {...cssSubmitRow}>
              <Button type="primary" htmlType="submit">
                Post
              </Button>
            </div>
          </div>
        </form>
      </Window>
    </div>
  );
}
