import { useRef, useState } from "react";

import { Switch, Route, useHistory } from "react-router-dom";
import useResizeObserver from "use-resize-observer";

import { getRequestedQueryParams } from "./url_loader";

import { useRefFn } from "./utils/react_utils";

import { ViewRange } from "./components/timeline/view_range";
import { getDefaultTimeRange } from "./utils/date_utils";
import { ResizeObserver } from "./utils/resize_observer";

import { Nav } from "./components/nav/Nav";
import { Main } from "./components/main/Main";
import { Fact } from "./components/fact/Fact";
import { Post } from "./components/fact/Post";
import { Register } from "./components/user/Register";
import { Login } from "./components/user/Login";
import { User } from "./components/user/User";
import { About } from "./components/misc/About";

import { RenderStats } from "./components/utils/RenderStats";

import { SessionStates } from "./types";

import { S, css, cssOverrideClass, combined } from "./styles/styles";

// Since the resize observer has singleton semantics anyway, it should be
// safe to hold it as a global reference instead of regular React refs.
// Note that this also avoids any issues with memoized render calls that
// may result in clients not re-registering because their render calls are
// skipped.
const resizeObserver = new ResizeObserver();

const cssRootContainer = combined(css(S.wFull, S.hFull, S.flex, S.flexCol), cssOverrideClass);
const cssMainContainer = css(S.container, S.mxAuto, S.hFull, S.flexGrow);

type AppProps = {
  session: SessionStates;
  setSession: (session: SessionStates) => void;
  defaultTag: string | undefined;
};

export function App(props: AppProps) {
  console.log("Rendering App");
  const isAuthenticated = props.session !== "guest";

  const requestedQueryParams = getRequestedQueryParams();

  // Refs

  // Why hoist the view range is into main app and not owned by view itself?
  // In order to allow for persistance of the view range, after a user has
  // navigated away from the main view.
  const viewRange = useRefFn(() => {
    return new ViewRange(
      requestedQueryParams != null ? requestedQueryParams.range : getDefaultTimeRange()
    );
  });

  const rootRef = useRef<HTMLDivElement>(null);

  // State

  const [selectedTags, setSelectedTags] = useState<string[]>(
    requestedQueryParams != null
      ? requestedQueryParams.tags
      : props.defaultTag != null
      ? [props.defaultTag]
      : []
  );

  useResizeObserver<HTMLDivElement>({
    ref: rootRef,
    onResize: ({ width, height }) => {
      console.log(`Resized root to ${width} x ${height}`);
      if (width != null && height != null) {
        resizeObserver.call(width, height);
      }
    },
  });

  // Callbacks

  const history = useHistory();

  const onRegister = (username: string) => {
    props.setSession({ username });
    history.replace("/");
  };

  const onLogin = (username: string) => {
    props.setSession({ username });
    history.replace("/");
  };

  /*
    Note: Two divs are needed:
    - the one for the resize observer needs full width, to "see" any resizing.
    - the inner one for the main component has a max width, i.e., resizes only if needed.
  */
  return (
    <div {...cssRootContainer} ref={rootRef}>
      <Nav session={props.session} />
      <div {...cssMainContainer}>
        <Switch>
          <Route path="/fact/:factId">
            <Fact isAuthenticated={isAuthenticated} />
          </Route>
          <Route path="/user/:userId">
            <User />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/register">
            <Register onRegister={onRegister} />
          </Route>
          <Route path="/login">
            <Login onLogin={onLogin} />
          </Route>
          <Route path="/post">
            <Post session={props.session} />
          </Route>
          <Route path="/render_stats">
            <RenderStats />
          </Route>
          <Route path="/">
            <Main
              viewRange={viewRange.current}
              resizeObserver={resizeObserver}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
