type ResizeCallback = (width: number, height: number) => void;

export class ResizeObserver {
  callbacks: { [id: string]: ResizeCallback } = {};

  register(id: string, callback: ResizeCallback) {
    this.callbacks[id] = callback;
  }

  unregister(id: string) {
    delete this.callbacks[id];
  }

  call(width: number, height: number) {
    for (let id in this.callbacks) {
      let callback = this.callbacks[id];
      callback(width, height);
    }
  }
}
