import { LoadingOutlined } from "@ant-design/icons";

import { S, css } from "../../styles/styles";

const cssCenter = css(
  S.absolute,
  "top:  50%;",
  "left: 50%;",
  "transform: translate(-50%,-50%);",
  S.flex,
  S.flexCol,
  S.items("center")
);
const cssSpinner = css(S.textSize(32));
// const cssLoading = css(S.mt(24), S.textSize(20));

export function LoadingPage() {
  // Setting color via inline CSS due to specificity...
  return (
    <div {...cssCenter}>
      <div>
        <LoadingOutlined style={{ color: S.colorLoading }} spin {...cssSpinner} />
      </div>
      {/*<div {...cssLoading}>Loading...</div>*/}
    </div>
  );
}
