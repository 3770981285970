let stats = {
  numCalls: 0,
  deltaAvg: 0,
  deltaMin: 999,
  deltaMax: -999,
  layoutPctAvg: 0,
  layoutPctMin: 999,
  layoutPctMax: -999,
};

export function updateRenderStats(t1: number, t2: number, t3: number) {
  const delta = t3 - t1;
  const deltaLayout = t2 - t1;
  const pctLayout = deltaLayout / delta;

  stats.numCalls += 1;

  stats.deltaAvg += (delta - stats.deltaAvg) / stats.numCalls;
  if (delta < stats.deltaMin) {
    stats.deltaMin = delta;
  }
  if (delta > stats.deltaMax) {
    stats.deltaMax = delta;
  }

  stats.layoutPctAvg += (pctLayout - stats.layoutPctAvg) / stats.numCalls;
  if (pctLayout < stats.layoutPctMin) {
    stats.layoutPctMin = pctLayout;
  }
  if (pctLayout > stats.layoutPctMax) {
    stats.layoutPctMax = pctLayout;
  }
}

export function getRenderStats() {
  return stats;
}

export function resetRenderStats() {
  stats = {
    numCalls: 0,
    deltaAvg: 0,
    deltaMin: 999,
    deltaMax: -999,
    layoutPctAvg: 0,
    layoutPctMin: 999,
    layoutPctMax: -999,
  };
}

/**
 * Requires setting `privacy.reduceTimerPrecision` in FF to false to
 * get any meaningful output. Snippets for usage:
 *
 * // eslint-disable-next-line import/first
 * import { DebugStatistic } from "./render_stats";
 *
 * const stats = new DebugStatistic();
 *
 * const t1 = performance.now()
 * // ...
 * const t2 = performance.now()
 * stats.push(t2 - t1)
 */
export class DebugStatistic {
  values: number[] = [];

  push(x: number) {
    this.values.push(x);

    if (this.values.length % 100 === 0) {
      const values = this.values.sort((a, b) => a - b);
      const mean = values.reduce((a, b) => a + b, 0) / values.length;
      const min = values[0];
      const max = values[values.length - 1];
      const median = values[values.length / 2];
      const p05 = values[Math.floor(0.05 * values.length)];
      const p95 = values[Math.floor(0.95 * values.length)];
      const pct_zero = (100 * values.filter((x) => x === 0).length) / values.length;
      console.log(
        `N = ${values.length} mean = ${mean} | min = ${min} p05 = ${p05} p50 = ${median} p95 = ${p95} max = ${max} | % zero: ${pct_zero}`
      );
    }
  }
}
