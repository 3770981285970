export type Response<T, E> = Ok<T> | Err<E>;

type Ok<T> = {
  isOk: true;
  code: number;
  data: T;
  appError?: never;
  reqError?: never;
};
type Err<E> = {
  isOk: false;
  code?: number;
  reqError?: string;
  appError?: E;
  data?: never;
};

export const ok = <T>(code: number, data: T): Ok<T> => {
  return {
    isOk: true,
    code,
    data,
  };
};

export const err = <E>(code?: number, reqError?: string, appError?: E): Err<E> => {
  return {
    isOk: false,
    code,
    reqError,
    appError,
  };
};

// Type utils to convert from backend result type to frontend response type.

export type BackendResult<T, E> = BackendOk<T> | BackendErr<E>;

type BackendOk<T> = {
  isOk: true;
  data: T;
  error?: never;
};
type BackendErr<E> = {
  isOk: false;
  data?: never;
  error: E;
};

// export type AsResp<X> = X extends AppResult<infer T, infer E> ? Resp<T, E> : never;
export type AsResponse<X> = X extends BackendOk<infer T>
  ? Ok<T>
  : X extends BackendErr<infer E>
  ? Err<E>
  : never;

// type GetOk<X> = X extends AppResult<infer T, infer E> ? T : never;
// type GetErr<X> = X extends AppResult<infer T, infer E> ? E : never;

/*
function f<X>(): X extends AppResult<infer T, infer E> ? AppResult<T, E> : never {
  let x = {} as any;
  return x as AppResult<T, E>;
}

function typed<X>(): AsResp<X> {
  //let x = {} as any;
  let x = ok2(200, "asdf") as AsResp<X>;
  return x;
}

let a = typed<ResFactGet>();
*/
