import React from "react";

import { Animation, AnimationDrag, AnimationZoom } from "./animation_utils";

import { ViewRange, computePinchZoomedRange } from "./view_range";

import * as date_utils from "../../utils/date_utils";

/**
 * Helper class that allows to keep track of drag velocity.
 */
export class VelocityTracker {
  lastDragY?: number;
  lastTime?: number;
  velocity: number = 0; // Unit: pixel/ms
  dragDistance: number = 0;

  update(currDragY: number): number | undefined {
    let currTime = performance.now();

    let deltaY = undefined as number | undefined;
    let deltaTime = undefined as number | undefined;
    let velocity = undefined as number | undefined;

    if (this.lastDragY != null) {
      deltaY = currDragY - this.lastDragY;
    }
    if (this.lastTime != null) {
      deltaTime = currTime - this.lastTime;
    }
    if (deltaY != null && deltaTime != null) {
      velocity = deltaY / deltaTime;
      this.dragDistance += Math.abs(deltaY);
    }

    this.lastDragY = currDragY;
    this.lastTime = currTime;
    if (velocity != null) {
      // Use some EWMA model to slightly smooth velocity.
      this.velocity = 0.9 * velocity + 0.1 * this.velocity;
      // console.log(deltaY, deltaTime, velocity, this.velocity);
    }

    return deltaY;
  }

  reset() {
    this.lastDragY = undefined;
    this.lastTime = undefined;
    this.velocity = 0;
    this.dragDistance = 0;
  }
}

/**
 * Event handler for mouse/touch dragging (pan).
 */
export class DragHandler {
  dragging: boolean;
  private tracker: VelocityTracker;

  static readonly dragDetectionPixelThreshold = 5;

  constructor() {
    this.dragging = false;
    this.tracker = new VelocityTracker();
  }

  onDragStart(
    currDragY: number,
    animating: boolean,
    setAnimating: (value: React.SetStateAction<boolean>) => void,
    rAnimation: React.MutableRefObject<Animation | null>,
    container: HTMLElement | undefined
  ) {
    let canStartDragging = true;
    if (animating) {
      // Normal animations can be interrupted, but zoom animations must terminate
      // so that we can only zoom by full zoom factors.
      if (rAnimation.current instanceof AnimationZoom) {
        canStartDragging = false;
      } else {
        setAnimating(false);
      }
    }
    if (canStartDragging) {
      this.tracker.reset();
      this.tracker.update(currDragY);
      this.dragging = true;
      if (container != null) {
        container.style["cursor"] = "grabbing";
      }
    }
  }

  onDrag(currDragY: number, viewRange: ViewRange, redraw: () => void) {
    let deltaY = this.tracker.update(currDragY);
    if (deltaY != null) {
      let deltaTimestamp = viewRange.convertDeltaYToDeltaTime(deltaY);
      viewRange.setRange(date_utils.shiftRange(viewRange.getRange(), deltaTimestamp));
      // TODO: Should the redraw happen in a request animation frame?
      redraw();
    }
  }

  onDragEnd(
    setAnimating: (value: React.SetStateAction<boolean>) => void,
    rAnimation: React.MutableRefObject<Animation | null>,
    viewRange: ViewRange,
    container: HTMLElement | undefined
  ) {
    // At first I was trying to incorporate the clientY position here as well,
    // running a final onDrag() updating including a redraw. However it looks
    // like the clientY of the onMoveUp event has a weird behavior and doesn't
    // really fit to the clientY values from mouse move: In a quick upward mouse
    // movement where all the move deltaY are negative, the resulting deltaY
    // suddenly becomes positive. This indicates that the mouse up event actually
    // uses an earlier clientX value as the move events. Better to ignore it.
    let velocity = this.tracker.velocity;
    this.dragging = false;
    if (container != null) {
      container.style["cursor"] = "grab";
    }

    // Setup animation
    if (velocity !== 0) {
      rAnimation.current = new AnimationDrag(velocity, viewRange);
      setAnimating(true);
    }
  }

  onDragEndNoAnimation(container: HTMLElement | undefined) {
    this.dragging = false;
    if (container != null) {
      container.style["cursor"] = "grab";
    }
  }

  hasBeenDragged(): boolean {
    return this.tracker.dragDistance >= DragHandler.dragDetectionPixelThreshold;
  }
}

/**
 * Event handler for touch pinch zooming.
 */
export class PinchHandler {
  eventA?: React.Touch;
  eventB?: React.Touch;

  onPinchStart(
    newEventA: React.Touch,
    newEventB: React.Touch,
    animating: boolean,
    setAnimating: (value: React.SetStateAction<boolean>) => void
  ) {
    if (animating) {
      setAnimating(false);
    }
    this.eventA = newEventA;
    this.eventB = newEventB;
  }

  onPinch(
    eventI: React.Touch,
    eventJ: React.Touch,
    viewRange: ViewRange,
    timestampLookup: (clientY: number) => date_utils.Timestamp | undefined,
    redraw: () => void
  ) {
    if (this.eventA != null && this.eventB != null) {
      let [eventA, eventB] = identifyEvents(this.eventA, this.eventB, eventI, eventJ);
      if (eventA != null && eventB != null) {
        let oldZoomDateA = timestampLookup(this.eventA.clientY);
        let oldZoomDateB = timestampLookup(this.eventB.clientY);
        let newZoomDateA = timestampLookup(eventA.clientY);
        let newZoomDateB = timestampLookup(eventB.clientY);
        if (
          oldZoomDateA != null &&
          oldZoomDateB != null &&
          newZoomDateA != null &&
          newZoomDateB != null
        ) {
          let newRange = computePinchZoomedRange(
            oldZoomDateA,
            oldZoomDateB,
            newZoomDateA,
            newZoomDateB,
            viewRange.getRange(),
            viewRange.getHeight()
          );
          if (newRange != null) {
            viewRange.setRange(newRange);
            // TODO: Should the redraw happen in a request animation frame?
            redraw();
          }
        }
        this.eventA = eventA;
        this.eventB = eventB;
      } else {
        this.eventA = eventI;
        this.eventB = eventJ;
      }
    } else {
      this.eventA = eventI;
      this.eventB = eventJ;
    }
  }

  onPinchEnd() {
    this.eventA = undefined;
    this.eventB = undefined;
  }

  isPinching(): boolean {
    return this.eventA != null && this.eventB != null;
  }
}

function identifyEvents(
  eventA: React.Touch,
  eventB: React.Touch,
  eventI: React.Touch,
  eventJ: React.Touch
): [React.Touch | undefined, React.Touch | undefined] {
  if (eventA.identifier === eventI.identifier && eventB.identifier === eventJ.identifier) {
    return [eventI, eventJ];
  } else if (eventA.identifier === eventJ.identifier && eventB.identifier === eventI.identifier) {
    return [eventJ, eventI];
  } else {
    return [undefined, undefined];
  }
}
