import { Timestamp, TimeRange, RecommendedTicks } from "../../utils/date_utils";
import * as date_utils from "../../utils/date_utils";
import { LinearTransformParams } from "../../core/types";
import { applyLinearTransform } from "../../core/linear_transform";

/**
 * A few design notes:
 * - Since the view range is a frequently changing view setting (modified in particular
 *   during animations), this class is currently using a mutable design instead of
 *   recreating view ranges all the time.
 * - The state must be hoisted into the outer app context so that the view is persisted
 *   when navigating away from the main view.
 * - As a result, the height handling is slightly awkward: In the outer app context the
 *   client height cannot be known -- that depends on the children (view components)
 *   and will only be obtained later in resize callbacks. This means that it is impossible
 *   in the main app component to initialize the view range with an "extension", i.e.,
 *   show e.g. 1 month, but add a margin of a few pixel on top/bottom to make sure that
 *   the month is actually fully visible. The solution is to internally initialize the
 *   height to -1 to indicate "not initialized". The initially given range is stored
 *   unmodified, and only when the height is first set the extension gets applied to the
 *   range.
 */
export class ViewRange {
  ticks!: RecommendedTicks;
  private transformToY!: LinearTransformParams;
  private transformToTimestamp!: LinearTransformParams;
  private invHeight: number;

  constructor(private range: TimeRange, private height = -1, readonly futureUp = true) {
    this.invHeight = 1 / height;
    this.recomputeTicks();
    this.recomputeTransforms();
  }

  private recomputeTicks() {
    // console.log("recomputing ticks");
    this.ticks = date_utils.computeRecommendedTicks(this.range, this.height);
  }

  private recomputeTransforms() {
    const timePerHeight = (this.range.upto - this.range.from) * this.invHeight;
    const heightPerTime = 1 / timePerHeight;
    if (this.futureUp) {
      this.transformToY = {
        origin: this.range.upto,
        scale: -heightPerTime,
      };
      // Optimized version of paramsFromOffsetAndScale (only 1 division needed)
      this.transformToTimestamp = {
        origin: this.range.upto * heightPerTime,
        scale: -timePerHeight,
      };
    } else {
      this.transformToY = {
        origin: this.range.from,
        scale: heightPerTime,
      };
      // Optimized version of paramsFromOffsetAndScale (only 1 division needed)
      this.transformToTimestamp = {
        origin: -this.range.from * heightPerTime,
        scale: timePerHeight,
      };
    }
  }

  getHeight(): number {
    return this.height;
  }
  setHeight(newHeight: number, extendPixel: number) {
    if (newHeight === 0) {
      // Ignore zero height updates, because it would lead to NaNs.
      return;
    }
    const oldHeight = this.height;
    this.height = newHeight;
    this.invHeight = 1 / newHeight;

    if (oldHeight === -1) {
      // Special case: Initialization -- now that we have a height, adjust the range to incorporate an extension.
      this.range = this.extendRange(this.range, extendPixel);
    } else {
      // Standard case: Invariant timePerPixel ratio and range.upto value.
      const deltaTimestamp = this.range.upto - this.range.from;
      const timePerPixel = deltaTimestamp / oldHeight;
      this.range = {
        from: (this.range.upto - newHeight * timePerPixel) as Timestamp,
        upto: this.range.upto,
      };
    }

    this.recomputeTicks();
    this.recomputeTransforms();
  }

  getRange(): TimeRange {
    return this.range;
  }
  setRange(range: TimeRange) {
    // Should we add the check:
    // date_utils.isRangeValid(zoomedRange) && date_utils.isRangeBelowMaxZoomLevel(zoomedRange, visiblePixels)
    // here?
    // Pro: Proper invariant
    // Con: Harder for clients to react to failed range setting.
    // Probably not, because this class is supposed to be a low-level setter of the range,
    // used e.g. during animations. Validation should happen on the animation inputs.
    this.range = range;
    this.recomputeTicks();
    this.recomputeTransforms();
  }

  getY(timestamp: Timestamp): number {
    return applyLinearTransform(this.transformToY, timestamp);
  }
  getYTransformParams(): LinearTransformParams {
    return this.transformToY;
  }

  getTimestamp(y: number): Timestamp {
    return applyLinearTransform(this.transformToTimestamp, y) as Timestamp;
  }
  getTimestampTransformParams(): LinearTransformParams {
    return this.transformToTimestamp;
  }

  convertDeltaYToDeltaTime(deltaY: number): Timestamp {
    const deltaTimestamp = this.range.upto - this.range.from;
    const sign = this.futureUp ? +1 : -1;
    return (sign * deltaY * this.invHeight * deltaTimestamp) as Timestamp;
  }

  getTimePerPixel(): Timestamp {
    const deltaTimestamp = this.range.upto - this.range.from;
    return (this.invHeight * deltaTimestamp) as Timestamp;
  }

  extendRange(range: TimeRange, extendPixel: number): TimeRange {
    const reducedHeight = this.height - 2 * extendPixel;
    if (reducedHeight <= 0) {
      return range;
    } else {
      const deltaTimestamp = range.upto - range.from;
      const timePerPixel = deltaTimestamp / reducedHeight;
      return {
        from: (range.from - timePerPixel * extendPixel) as Timestamp,
        upto: (range.upto + timePerPixel * extendPixel) as Timestamp,
      };
    }
  }
}

// ----------------------------------------------------------------------------
// Related free functions
// ----------------------------------------------------------------------------

export function computeZoomedRange(
  zoomTimestamp: Timestamp,
  range: TimeRange,
  factor: number,
  visiblePixels: number
): TimeRange | undefined {
  let oldTimestampFrom = range.from;
  let oldTimestampUpto = range.upto;
  let newTimestampFrom = zoomTimestamp + (oldTimestampFrom - zoomTimestamp) / factor;
  let newTimestampUpto = zoomTimestamp + (oldTimestampUpto - zoomTimestamp) / factor;
  let zoomedRange = {
    from: newTimestampFrom as Timestamp,
    upto: newTimestampUpto as Timestamp,
  };
  if (
    date_utils.isRangeValid(zoomedRange) &&
    date_utils.isRangeBelowMaxZoomLevel(zoomedRange, visiblePixels)
  ) {
    return zoomedRange;
  }
}

export function computePinchZoomedRange(
  oldZoomDateA: Timestamp,
  oldZoomDateB: Timestamp,
  newZoomDateA: Timestamp,
  newZoomDateB: Timestamp,
  oldRange: TimeRange,
  visiblePixels: number
): TimeRange | undefined {
  let transform = new Transform(oldZoomDateA, oldZoomDateB, newZoomDateA, newZoomDateB);
  let newTimestampFrom = transform.transformInv(oldRange.from);
  let newTimestampUpto = transform.transformInv(oldRange.upto);
  if (isFinite(newTimestampFrom) && isFinite(newTimestampUpto)) {
    let newRange = {
      from: newTimestampFrom as Timestamp,
      upto: newTimestampUpto as Timestamp,
    };
    if (
      date_utils.isRangeValid(newRange) &&
      date_utils.isRangeBelowMaxZoomLevel(newRange, visiblePixels)
    ) {
      return newRange;
    }
  }
}

export class Transform {
  m: number;
  c: number;

  constructor(a1: number, b1: number, a2: number, b2: number) {
    this.m = (a2 - b2) / (a1 - b1);
    this.c = a2 - this.m * a1;
    // console.log(this.m, this.c);
  }

  transform(x: number): number {
    return this.m * x + this.c;
  }

  transformInv(x: number): number {
    return (x - this.c) / this.m;
  }
}
