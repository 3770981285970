import { Timestamp, TimeRange } from "./utils/date_utils";
import * as date_utils from "./utils/date_utils";

let requestedQueryParams:
  | {
      range: TimeRange;
      tags: string[];
    }
  | undefined;

export function normalizerBrowserLocation() {
  const queryParamsString = window.location.search;
  const queryParams = new URLSearchParams(queryParamsString);

  const fromString = queryParams.get("from");
  const uptoString = queryParams.get("upto");
  const tagsString = queryParams.get("tags");

  if (queryParamsString.length > 0) {
    window.history.pushState(null, "Main page", "/");
  }

  if (fromString == null || uptoString == null || tagsString == null) {
    return;
  }

  const from = date_utils.utcTimestampFromString(fromString) as Timestamp;
  const upto = date_utils.utcTimestampFromString(uptoString) as Timestamp;
  const tags = tagsString.split(",");

  if (from != null || upto != null) {
    requestedQueryParams = { range: { from, upto }, tags };
  }
}

export function getRequestedQueryParams() {
  return requestedQueryParams;
}
