import { LinearTransformParams } from "./types";

export function applyLinearTransform(params: LinearTransformParams, x: number): number {
  return (x - params.origin) * params.scale;
}

export function paramsFromOffsetAndScale(offset: number, scale: number): LinearTransformParams {
  return {
    origin: -offset / scale,
    scale,
  };
}
