import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import { AppLoader } from "./AppLoader";
import { AuthForm } from "./AuthForm";

import { BrowserRouter as Router } from "react-router-dom";

import { normalizerBrowserLocation } from "./url_loader";

import "./styles/index.css";

const isDev = process.env.NODE_ENV === "development";

normalizerBrowserLocation();

// Note: The <Router> wrapping is hoisted out of the <App> component,
// because otherwise `useLocation` is not usable within the <App>.
// See: https://flaviocopes.com/react-router-uselocation-usehistory-undefined/
ReactDOM.render(
  <React.StrictMode>
    {isDev ? (
      <Router>
        <AppLoader />
      </Router>
    ) : (
      <AuthForm>
        <Router>
          <AppLoader />
        </Router>
      </AuthForm>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

const enablePerformanceMeasuring = false;
if (enablePerformanceMeasuring) {
  // https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
}
