import React from "react";
import styles from "./Window.module.css";

type WindowProps = {
  children: React.ReactNode;
};

export function Window({ children }: WindowProps) {
  return <div className={styles.frame}>{children}</div>;
}

export function WindowInvisible({ children }: WindowProps) {
  return <div className={styles.frameInvisible}>{children}</div>;
}
