import { TimeRange, Timestamp, MILLIS_PER_DAY } from "../utils/date_utils";

import { FactCore, LayoutParams, FactsLayout, FactCircle, MinZoomMap } from "./types";
import { applyLinearTransform } from "./linear_transform";

/**
 * PRECONDITION: Facts must be sorted descending by relevance.
 */
export function computeLayout(
  facts: FactCore[],
  range: TimeRange,
  layout: LayoutParams,
  bubbleMinZoomMap: MinZoomMap,
  textMinZoomMap: MinZoomMap
): FactsLayout {
  // Helper constants for performance
  const doubleRadius = 2 * layout.radius;
  const pixelPerTime = 1 / layout.timePerPixel;

  // Extend timestamp range so that bubbles are clipped only when invisible.
  const timestampFromExtended = range.from - layout.timePerPixel * layout.radius;
  const timestampUptoExtended = range.upto + layout.timePerPixel * layout.radius;

  const circles: FactCircle[] = [];
  const markers: FactCore[] = [];

  const returnAdjustedTimestamp = { timestampAdjusted: NaN, properIntradayShift: false };

  for (let i = 0; i < facts.length; ++i) {
    const fact = facts[i];

    // Mutating fact is currently okay, and done in favor of reducing GC. Currently only fact.timestamp
    // is mutated and can be reset via fact.timestampOrig.
    computeAdjustedTimestamp(
      fact.timestampOrig,
      fact.rank,
      layout.timePerPixel,
      layout.textGap,
      layout.futureUp,
      returnAdjustedTimestamp
    );
    fact.timestamp = returnAdjustedTimestamp.timestampAdjusted as Timestamp;

    if (!(fact.timestamp >= timestampFromExtended && fact.timestamp <= timestampUptoExtended)) {
      continue;
    }
    markers.push(fact);

    const bubbleMinZoom = bubbleMinZoomMap[fact.id] as number | undefined;
    const bubbleVisible = bubbleMinZoom != null && bubbleMinZoom > layout.timePerPixel;

    const textVisible =
      textMinZoomMap[fact.id] > layout.timePerPixel || returnAdjustedTimestamp.properIntradayShift;

    if (bubbleVisible || textVisible) {
      let x = applyLinearTransform(layout.transformRelevanceToX, fact.relevance);
      for (let j = 0; j < circles.length; ++j) {
        const circle = circles[j];
        const dist = Math.abs(fact.timestamp - circle.timestamp) * pixelPerTime;
        if (dist < doubleRadius) {
          const dx = Math.sqrt(doubleRadius * doubleRadius - dist * dist);
          if (circle.x - dx < x) {
            x = circle.x - dx;
          }
        }
      }

      const y = applyLinearTransform(layout.transformTimestampToY, fact.timestamp);

      // Text visibility veto: If the x value is outside the visible range,
      // hide the text label, because hovering the text label would have to
      // highlight a bubble that is not in view.
      const withText = x < layout.minVisibleX + layout.radius ? false : textVisible;

      circles.push({
        id: fact.id,
        x: x,
        y: y,
        r: layout.radius,
        timestamp: fact.timestamp,
        relevance: fact.relevance,
        headline: fact.headline,
        withText,
      });
    }
  }

  return { circles: circles, markers: markers };
}

type ReturnAdjustedTimestamp = {
  timestampAdjusted: number;
  properIntradayShift: boolean;
};

/**
 * Function uses ugly output parameter due to big allocation overhead of using return tuple
 * (40% of total allocations).
 */
export function computeAdjustedTimestamp(
  timestamp: number,
  rank: number,
  timePerPixel: number,
  targetDeltaPixel: number,
  futureUp: boolean,
  ret: ReturnAdjustedTimestamp
): void {
  if (rank === 1) {
    ret.timestampAdjusted = timestamp;
    ret.properIntradayShift = false;
  } else {
    const sign = futureUp ? -1 : +1;

    const targetTimestamp = timestamp + sign * (rank - 1) * targetDeltaPixel * timePerPixel;

    // The limit timestamps corresponds to going to the "next" day, and subtracting the
    // target delta pixel from it, where semantics of "next" must follow time direction.
    const limitTimestamp = timestamp + sign * (MILLIS_PER_DAY - targetDeltaPixel * timePerPixel);

    if (futureUp) {
      if (limitTimestamp > targetTimestamp) {
        if (limitTimestamp < timestamp) {
          ret.timestampAdjusted = limitTimestamp;
          ret.properIntradayShift = false;
          return;
        } else {
          ret.timestampAdjusted = timestamp;
          ret.properIntradayShift = false;
          return;
        }
      }
    } else {
      if (limitTimestamp < targetTimestamp) {
        if (limitTimestamp > timestamp) {
          ret.timestampAdjusted = limitTimestamp;
          ret.properIntradayShift = false;
          return;
        } else {
          ret.timestampAdjusted = timestamp;
          ret.properIntradayShift = false;
          return;
        }
      }
    }

    ret.timestampAdjusted = targetTimestamp;
    ret.properIntradayShift = true;
  }
}
