import { useState, useEffect } from "react";

import { App } from "./App";
import { LoadingPage } from "./components/utils/LoadingPage";

import { backend } from "./backend";
import { SessionStates } from "./types";

/**
 * The idea of the AppLoader is to keep all "initial in-flight" state out of the
 * normal app component. This simplifies the state handling in the app, because
 * there is no need for "ternary" states.
 *
 * From a UX perspective this implies that loading happens centralized and the
 * user will be given a loading page, instead of handling the "loading" state
 * in various individual UI components.
 */
export function AppLoader() {
  console.log("Rendering AppLoader");

  const [session, setSession] = useState<SessionStates | "loading">("loading");

  // Initial effects

  useEffect(() => {
    async function loadSession() {
      // Check session
      const res = await backend().usersSession();
      if (res.isOk) {
        setSession(res.data);
      } else {
        setSession("guest");
      }
    }
    // For inspection of loading page:
    // setTimeout(loadSession, 10000);
    loadSession();
  }, []);

  if (session === "loading") {
    return <LoadingPage />;
  } else {
    return <App session={session} setSession={setSession} defaultTag={"world"} />;
  }
}
