import { Window } from "../utils/Window";

import { S, css } from "../../styles/styles";

const cssHeadline = css(S.textSize(22), S.fontWeight(700), S.mb(20), S.mt(24));
const cssText = css(`
  li {${S.mt(12)}}
  p {${S.mt(16)}}
`);

export function About() {
  return (
    <div>
      <Window>
        <h1 {...cssHeadline} style={{ marginTop: "0" }}>
          About
        </h1>

        <div {...cssText}>
          <p>
            <b>The mission:</b> Making it easier to stay on top of what is happening in the world.
          </p>

          <h1 {...cssHeadline}>Why?</h1>
          <div {...cssText}>
            Staying up-to-date is more and more challenging in present age. Traditional news feeds
            can easily be overwhelming, or they don't cover all the topics you're interested in, and
            you have to consult many sources to stay up to date. Chronwire's goal is to make it
            easier...
            <ul>
              <li>... to follow news at your own pace.</li>
              <li>... to follow a topic at a high level only.</li>
              <li>
                ... to catch up on what has happened after you have missed the news for a few weeks
                — for whatever reason that may have happened.
              </li>
              <li>... to discover the historic evolvement of a new topics.</li>
              <li>... to have a broad range of topics not limited on areas of traditional news.</li>
            </ul>
            <p>
              <b>The idea:</b> Use the timeline visualization to explore the topics you're
              interested in. Zoom out, if you want to get an overview / catch up with the topics.
              Zoom in if you're interested in the detailed news.
            </p>
          </div>

          <h1 {...cssHeadline}>How?</h1>
          <p>
            Chronwire is a community-driven news aggregator website featuring a unique news timeline
            visualization. Compared to other news aggregator sites, posts on chronwire have two
            essential properties:
            <ul>
              <li>An explicit date to anchor the headline on the timeline.</li>
              <li>
                A community-driven relevance score between 0 and 100 to reflect how relevant a
                headline is with respect to a certain topic.
              </li>
            </ul>
          </p>
          <p>
            Posts can be cross-linked to any topic. It is up to the community/followers of the
            communities to decide if the post is relevant in the respective topic.
          </p>

          <h1 {...cssHeadline}>Planned features</h1>
          <div {...cssText}>
            As you may have observed, the project is currently in an early beta state. Amongst the
            planned features are:
            <ul>
              <li>
                A reputation system to make contributing to the community more fun and rewarding.
              </li>
              <li>
                Moderators: High reputation users may benefit from extra privileges in moderating
                content.
              </li>
              <li>
                Suggested edits: Discovered anything that is wrong? Make a suggestion to improve it.
              </li>
              <li>
                Curated channels: Users may provide 'locked' topics, that can only be assigned by
                them.
              </li>
              <li>
                Duplicate flagging: If two posts refer to the same event, they should get linked.
              </li>
              <li>
                Incubating posts: To avoid posting the same event multiple times, post may reside in
                an 'incubating' state for a while. In case there are duplicate posts, the community
                can vote on which post will eventually make it to avoid final duplicates in the
                first places.
              </li>
            </ul>
          </div>

          <h1 {...cssHeadline}>Get in touch</h1>
          <div {...cssText}>
            If you want to know more, give feedback, or want to get involved, feel free to contact
            us at:
            <br />
            <div style={{ margin: "20px auto", width: "100%", textAlign: "center" }}>
              <b>contact@chronwire.com</b>
            </div>
          </div>
        </div>
      </Window>
    </div>
  );
}
