import React from "react";

import { Alert, Modal } from "antd";
import { Link } from "react-router-dom";

type RequireLoginProps = {
  message: string;
};

export function RequireLogin({ message }: RequireLoginProps) {
  return (
    <Alert
      message="Log in required"
      description={
        <div>
          {message} If you already have an account you can <Link to="/login">log in</Link>,
          otherwise you can create an account by <Link to="/register">signing up</Link>.
        </div>
      }
      type="warning"
      showIcon
    />
  );
}

export function RequireLoginModal(message: string) {
  Modal.info({
    title: "Log in required",
    // TODO: Unfortunately React Router complains that <Link> must not be used outside a router
    // when using it in a modal.
    // https://stackoverflow.com/questions/53771502/using-react-router-with-react-portals
    content: (
      <div>
        {message} If you already have an account you can log in, otherwise you can create an account
        by signing up.
      </div>
    ),
    /*
    content: (
      <div>
        {message} If you already have an account you can <Link to="/login">log in</Link>, otherwise
        you can create an account by <Link to="/register">signing up</Link>.
      </div>
    ),
    */
  });
}
