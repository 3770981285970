import * as date_utils from "../../utils/date_utils";

import { FactsLayout, FactCircle, FactCore } from "../../core/types";
import { applyLinearTransform } from "../../core/linear_transform";
import {
  getRelevanceColor,
  getRelevanceColorSemiDarkened,
  getRelevanceColorDarkened,
} from "../../utils/colors";

import { ViewRange } from "./view_range";
import { Size, DrawParams } from "./types";

import { geom } from "./geometry";

const fonts = {
  headline: "16px Roboto Condensed",
  tickLabel: "12px Roboto Condensed",
  relevanceNumber: "11px Roboto Condensed",
};

type TextLabel = {
  circle: FactCircle;
  width: number;
};

// ----------------------------------------------------------------------------
// Canvas drawing
// ----------------------------------------------------------------------------

// eslint-disable-next-line import/first
// import { DebugStatistic } from "./render_stats";
// const stats = new DebugStatistic();

export class RendererCanvas {
  circles: FactCircle[];
  textLabels: TextLabel[];
  colorNormalizer: ColorNormalizer;
  isHoverCleared: boolean = true;

  constructor(public canvas: HTMLCanvasElement, public canvasHover: HTMLCanvasElement) {
    this.circles = [];
    this.textLabels = [];
    this.colorNormalizer = new ColorNormalizer();
  }

  render(viewRange: ViewRange, size: Size, factsLayout: FactsLayout, params: DrawParams) {
    const canvas = this.canvas;
    this.colorNormalizer.scanFacts(factsLayout.markers);

    let ctx = canvas.getContext("2d")!;
    clear(ctx, size);

    // console.log(viewRange.ticks);

    let ysPrimary = viewRange.ticks.main.map(
      (tick) => Math.floor(viewRange.getY(tick.timestamp)) + 0.5
    );
    let ysSecondary = viewRange.ticks.lower?.map(
      (tick) => Math.floor(viewRange.getY(tick.timestamp)) + 0.5
    );

    // bubble background
    ctx.fillStyle = "#222222"; // "#fdfdfd"
    ctx.fillRect(geom.xVertLine - 0.5, 0, geom.xVertLineMiddle - geom.xVertLine, size.height);

    // bubbles
    for (const fact of factsLayout.circles) {
      if (fact.x > geom.xVertLine - geom.bubbleRadius) {
        let x = fact.x;
        let y = fact.y;
        /*
        let r =
          MIN_RELEVANCE_RADIUS +
          0.01 * fact.relevance * (MAX_RELEVANCE_RADIUS - MIN_RELEVANCE_RADIUS);
        */
        let r = geom.bubbleRadius;
        let colorFg = this.colorNormalizer.getRelevanceColorSemiDarkened(fact.relevance);
        let colorBg = this.colorNormalizer.getRelevanceColorDarkened(fact.relevance);
        ctx.beginPath();
        ctx.strokeStyle = colorFg;
        ctx.fillStyle = colorBg;
        ctx.lineWidth = 1;
        ctx.arc(x, y, r - 0.5, 0, 2 * Math.PI); // subtract half line width from radius
        // ctx.arc(X_VERT_LINE + 2 * fact.relevance, y, 4, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fill();
      }
    }
    ctx.lineWidth = 1.0;

    // markers
    for (let i = 0; i < factsLayout.markers.length; ++i) {
      const fact = factsLayout.markers[i];
      const x = applyLinearTransform(geom.transformRelevanceToX, fact.relevance) - 0.5;
      const y = viewRange.getY(fact.timestamp) - 0.5;
      const color = this.colorNormalizer.getRelevanceColor(fact.relevance);
      ctx.fillStyle = color;
      ctx.fillRect(x - 1, y - 1, 3, 3);
      // TODO: For 1x1 rects, putImageData seems to be faster. Test what is faster for 3x3.
    }

    // axis background (for clipping)
    ctx.fillStyle = "#ffffff"; // "#fdfdfd"
    ctx.fillRect(0, 0, geom.xVertLine, size.height);

    // add even/odd shading
    // disable for now, just not great yet...
    /*
    ctx.beginPath();
    ctx.fillStyle = "#f5fdff";
    for (let i = 0; i < viewRange.ticks.upper.length - 1; ++i) {
      if (viewRange.ticks.upper[i].isOdd) {
        // const y1 = ysPrimary[i];
        // const y2 = ysPrimary[i + 1];
        const tick1 = viewRange.ticks.upper[i];
        const tick2 = viewRange.ticks.upper[i + 1];
        const y1 = Math.floor(viewRange.getY(tick1.timestamp)) + 0.5;
        const y2 = Math.floor(viewRange.getY(tick2.timestamp)) + 0.5;
        ctx.rect(X_VERT_LINE_MIDDLE, y1, size.width, y2 - y1);
      }
    }
    ctx.fill();
    */

    // axis hlines
    ctx.beginPath();
    ctx.strokeStyle = "#f0f0f0";
    for (let y of ysPrimary) {
      ctx.moveTo(geom.xVertLineMiddle, y);
      ctx.lineTo(size.width, y);
    }
    ctx.stroke();

    // axis ticks
    ctx.beginPath();
    ctx.strokeStyle = "#666";
    for (let y of ysPrimary) {
      ctx.moveTo(geom.xTickFrom, y);
      ctx.lineTo(geom.xTickUpto, y);
    }
    if (ysSecondary != null && viewRange.ticks.lowerScore != null) {
      for (let y of ysSecondary) {
        let tickWidthHalf = 5 * viewRange.ticks.lowerScore;
        ctx.moveTo(geom.xVertLine - tickWidthHalf, y);
        ctx.lineTo(geom.xVertLine + tickWidthHalf, y);
      }
    }
    // vertical axis line
    ctx.moveTo(geom.xVertLine, 0.5);
    ctx.lineTo(geom.xVertLine, size.height + 0.5);
    ctx.stroke();

    // future line
    if (params.future >= viewRange.getRange().from && params.future <= viewRange.getRange().upto) {
      let y = Math.floor(viewRange.getY(params.future));
      ctx.beginPath();
      ctx.strokeStyle = "#f0f0f0";
      ctx.setLineDash([5, 3]);
      ctx.lineWidth = 2;
      ctx.moveTo(geom.xVertLineMiddle, y);
      ctx.lineTo(size.width, y);
      ctx.stroke();
      ctx.setLineDash([]);
      ctx.lineWidth = 1;
    }

    // tick labels
    ctx.font = fonts.tickLabel;
    ctx.textAlign = "right";
    ctx.fillStyle = "#222222";
    ctx.textBaseline = "middle";

    for (let tick of viewRange.ticks.main) {
      let text = tick.text;
      let x = geom.xTicksRight;
      let y = viewRange.getY(tick.timestamp) + geom.fontOffset;

      ctx.fillText(text, x, y);
    }

    if (params.drawSecondaryAxisLabels) {
      // Currently unimplemented
    }

    // facts texts
    const textLabels: TextLabel[] = [];
    ctx.font = fonts.headline;
    ctx.textAlign = "left";
    ctx.fillStyle = "#222222";
    ctx.textBaseline = "middle";

    for (let i = 0; i < factsLayout.circles.length; ++i) {
      const fact = factsLayout.circles[i];
      const y = Math.floor(viewRange.getY(fact.timestamp)) + geom.fontOffset; // TODO avoid recomputation
      if (fact.withText) {
        ctx.fillText(fact.headline, geom.xFactLabels, y);
        textLabels.push({
          circle: fact,
          width: ctx.measureText(fact.headline).width,
        });
      }
    }

    // remember circles and text labels
    this.circles = factsLayout.circles;
    this.textLabels = textLabels;
  }

  renderHover(circle: FactCircle, size: Size) {
    const canvas = this.canvasHover;

    let ctx = canvas.getContext("2d")!;
    ctx.clearRect(0, 0, size.width, size.height);

    let color = this.colorNormalizer.getRelevanceColor(circle.relevance);
    drawHoverConnectionLine(ctx, circle, color);
    drawCircleHighlight(ctx, circle, color);
    drawHeadline(ctx, circle, color);
    drawTimestamp(ctx, circle, color);

    this.isHoverCleared = false;
  }

  clearHover(size: Size) {
    if (!this.isHoverCleared) {
      let ctx = this.canvasHover.getContext("2d")!;
      ctx.clearRect(0, 0, size.width, size.height);
      this.isHoverCleared = true;
    }
  }

  getMatchingCircle(x: number, y: number): FactCircle | undefined {
    // Note the x check against X_VERT_LINE_CEILED makes sure that a hover
    // is only allow if at least half of the bubble is in view, avoiding hovering
    // bubbles that are almost entirely in the axis label area.
    if (x < geom.xVertLineMiddle) {
      for (let circle of this.circles) {
        if (isInCircle(x, y, circle) && circle.x > geom.xVertLineCeiled) {
          return circle;
        }
      }
    } else {
      for (let textLabel of this.textLabels) {
        if (isInLabelBox(x, y, textLabel) && textLabel.circle.x > geom.xVertLineCeiled) {
          return textLabel.circle;
        }
      }
    }
  }

  resizeCanvasesToClientSize(clientSize: Size) {
    resizeCanvasToClientSize(this.canvas, clientSize);
    resizeCanvasToClientSize(this.canvasHover, clientSize);
  }
}

// ----------------------------------------------------------------------------
// Drawing helper (hover)
// ----------------------------------------------------------------------------

function clear(ctx: CanvasRenderingContext2D, size: Size) {
  // ctx.clearRect is slow, but still slightly faster fillStyle + fillRect.
  // Note that fillStyle + rect + fill is much slower.
  // clearRect:
  // N = 1000 mean = 0.47382000000002134 | min = 0.339999999999236 p05 = 0.3599999999996726 p50 = 0.41999999999825377 p95 = 0.819999999999709 max = 7.399999999999636 | % zero: 0
  // fillStyle + fillRect:
  // N = 1000 mean = 0.4754200000000201 | min = 0.339999999999236 p05 = 0.36000000000012733 p50 = 0.42000000000007276 p95 = 0.8400000000001455 max = 4.180000000000064 | % zero: 0
  // fillStyle + rect + fill:
  // N = 1000 mean = 1.4953799999999626 | min = 0.8799999999991996 p05 = 0.9599999999991269 p50 = 1.2399999999997817 p95 = 2.8000000000010914 max = 9.539999999999964 | % zero: 0
  ctx.clearRect(0, 0, size.width, size.height);
  //ctx.fillStyle = "#ffffff";
  //ctx.fillRect(0, 0, size.width, size.height);
}

function drawCircleHighlight(ctx: CanvasRenderingContext2D, fact: FactCircle, color: string) {
  let lineWidth = 1.0 + ((fact.relevance / 100) * geom.bubbleRadius) / 5;
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.fillStyle = "#181818";
  ctx.lineWidth = lineWidth;
  ctx.arc(fact.x, fact.y, fact.r - lineWidth / 2, 0, 2 * Math.PI);
  // ctx.arc(X_VERT_LINE + 2 * fact.relevance, y, 4, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.fill();

  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.arc(fact.x, fact.y, geom.hoverRadius, 0, 2 * Math.PI);
  ctx.stroke();

  // Relevance label
  ctx.font = fonts.relevanceNumber;
  ctx.textAlign = "center";
  ctx.fillStyle = "#CCCCCC";
  ctx.textBaseline = "middle";
  ctx.beginPath();
  ctx.fillText("" + Math.round(fact.relevance), fact.x, fact.y + geom.fontOffset);
}

function drawHoverConnectionLine(ctx: CanvasRenderingContext2D, fact: FactCircle, color: string) {
  const y = Math.floor(fact.y);
  ctx.strokeStyle = color;
  ctx.fillStyle = "#181818";

  const xFrom = Math.floor(geom.xVertLine) - geom.factBoxXOffsetDoubled;
  const xUpto = geom.xFactLabels;

  // horizontal line
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(xFrom, y);
  ctx.lineTo(fact.x - geom.hoverRadius, y);
  ctx.moveTo(fact.x + geom.hoverRadius, y);
  ctx.lineTo(xUpto, y);
  ctx.stroke();
  ctx.lineWidth = 1;

  // small triangle
  const baseXR = xUpto - geom.factBoxXOffset;
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(baseXR - 4, y);
  ctx.lineTo(baseXR, y - 4);
  ctx.lineTo(baseXR, y + 4);
  ctx.fill();

  // small triangle
  const baseXL = xFrom + geom.factBoxXOffset;
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(baseXL + 4, y);
  ctx.lineTo(baseXL, y - 4);
  ctx.lineTo(baseXL, y + 4);
  ctx.fill();
}

function drawHeadline(ctx: CanvasRenderingContext2D, fact: FactCircle, color: string) {
  const y = Math.floor(fact.y);

  ctx.font = fonts.headline;
  const textMetrics = ctx.measureText(fact.headline);
  let width = Math.ceil(textMetrics.width);

  // Headline background
  ctx.beginPath();
  ctx.fillStyle = "#FFFFFF";
  ctx.shadowColor = "#00000040";
  ctx.shadowBlur = 8;
  /*
  ctx.strokeStyle = "#CCC";
  ctx.lineWidth = 1;
  ctx.rect(
    X_FACT_LABELS - consts.FACT_BOX_X_OFFSET + 0.5,
    y - consts.FACT_BOX_HEIGHT_HALF + 0.5,
    width + consts.FACT_BOX_X_OFFSET_DOUBLED - 1.0,
    consts.FACT_BOX_HEIGHT - 1.0
  );
  */
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.rect(
    geom.xFactLabels - geom.factBoxXOffset + 1.0,
    y - geom.factBoxHeightHalf + 1.0,
    width + geom.factBoxXOffsetDoubled - 2.0,
    geom.factBoxHeight - 2.0
  );
  ctx.fill();
  ctx.shadowBlur = 0;
  ctx.stroke();
  ctx.lineWidth = 2;

  // Headline
  ctx.font = fonts.headline;
  ctx.textAlign = "left";
  ctx.fillStyle = "#222222";
  ctx.textBaseline = "middle";
  ctx.fillText(fact.headline, geom.xFactLabels, y + geom.fontOffset);
}

function drawTimestamp(ctx: CanvasRenderingContext2D, fact: FactCircle, color: string) {
  const date = date_utils.formatToDay(date_utils.toDateFields(fact.timestamp));

  const y = Math.floor(fact.y);

  const textMetrics = ctx.measureText(date);
  let width = Math.ceil(textMetrics.width);
  let x = Math.floor(geom.xVertLine) - width - geom.factBoxXOffsetDoubled;

  // Background
  ctx.beginPath();
  ctx.fillStyle = "#FFFFFF";
  ctx.shadowColor = "#00000040";
  ctx.shadowBlur = 8;
  /*
  ctx.strokeStyle = "#CCC";
  ctx.lineWidth = 1;
  ctx.rect(
    x - consts.FACT_BOX_X_OFFSET + 0.5,
    y - consts.FACT_BOX_HEIGHT_HALF + 0.5,
    width + consts.FACT_BOX_X_OFFSET_DOUBLED - 1.0,
    consts.FACT_BOX_HEIGHT - 1.0
  );
  */
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.rect(
    x - geom.factBoxXOffset + 1.0,
    y - geom.factBoxHeightHalf + 1.0,
    width + geom.factBoxXOffsetDoubled - 2.0,
    geom.factBoxHeight - 2.0
  );
  ctx.fill();
  ctx.shadowBlur = 0;
  ctx.stroke();
  ctx.lineWidth = 2;

  // Timestamp
  ctx.font = fonts.headline;
  ctx.textAlign = "left";
  ctx.fillStyle = "#222222";
  ctx.textBaseline = "middle";
  ctx.fillText(date, x, y + geom.fontOffset);
}

// ----------------------------------------------------------------------------
// Misc Helper
// ----------------------------------------------------------------------------

function resizeCanvasToClientSize(canvas: HTMLCanvasElement, clientSize: Size) {
  // Sets both the render target sizes, as well as the canvas client size.
  //
  // Originally inspired by: https://stackoverflow.com/questions/4938346/canvas-width-and-height-in-html5
  //
  // Note 1: To prevent DOM querying/thrashing from canvas.clientWidth/canvas.clientWidth,
  // the clientSize needs to be queried externally and passed in. EDIT: In fact, now the
  // canvas isn't even measured directly, but its parent container, due to converting from
  // factional sizes (the parent container) to purely integer sizes for the canvas.
  //
  // Note 2: canvas.clientWidth is not necessarily exactly the same as
  // canvas.style.width, because the style could say "100%" whereas the
  // clientWidth always returns the number in pixel.
  //
  // TODO: Possible incorporate window.devicePixelRatio?
  // There is a long discussion on using devicePixelRation in this article:
  // https://webglfundamentals.org/webgl/lessons/webgl-resizing-the-canvas.html
  //
  // Notes about devicePixelRatio:
  // - Only the canvas.width/height must be scaled. The style stays in the normal "CSS"
  //   sizes. If scaling the style by the pixel device ratio as well, the canvas will
  //   just extend offscreen and the resulting pixel density is the same as for
  //   ignoring the device ratio.
  // - Unfortunately all drawing operations (and event handler coordinates!) need
  //   to incorporate the scaling as well to have the desired effect...

  const useDeviceRatio = false;
  const scaling = !useDeviceRatio ? 1.0 : window.devicePixelRatio;

  // Set render target size
  canvas.width = clientSize.width * scaling;
  canvas.height = clientSize.height * scaling;
  // Set client size
  canvas.style.width = `${clientSize.width}px`;
  canvas.style.height = `${clientSize.height}px`;
}

export function isInCircle(x: number, y: number, circle: FactCircle): boolean {
  if (
    x >= circle.x - circle.r &&
    x <= circle.x + circle.r &&
    y >= circle.y - circle.r &&
    y <= circle.y + circle.r
  ) {
    let dx = x - circle.x;
    let dy = y - circle.y;
    let dist = Math.sqrt(dx * dx + dy * dy);
    if (dist <= circle.r) {
      return true;
    }
  }
  return false;
}

export function isInLabelBox(x: number, y: number, label: TextLabel) {
  const circle = label.circle;
  if (
    y >= circle.y - circle.r &&
    y <= circle.y + circle.r &&
    x > geom.xFactLabels &&
    x < geom.xFactLabels + label.width
  ) {
    return true;
  } else {
    return false;
  }
}

class ColorNormalizer {
  private minRelevance = Infinity;
  private maxRelevance = -Infinity;
  private invWidth?: number;

  scanFacts(facts: FactCore[]) {
    this.minRelevance = Infinity;
    this.maxRelevance = -Infinity;
    for (let i = 0; i < facts.length; ++i) {
      const fact = facts[i];
      if (fact.relevance < this.minRelevance) {
        this.minRelevance = fact.relevance;
      }
      if (fact.relevance > this.maxRelevance) {
        this.maxRelevance = fact.relevance;
      }
    }
    if (isFinite(this.minRelevance) && this.maxRelevance !== this.minRelevance) {
      this.invWidth = 100 / (this.maxRelevance - this.minRelevance);
    } else {
      this.invWidth = undefined;
    }
  }

  getRelevanceColor(relevance: number): string {
    if (this.invWidth != null) {
      relevance = (relevance - this.minRelevance) * this.invWidth;
    }
    return getRelevanceColor(relevance);
  }

  getRelevanceColorDarkened(relevance: number): string {
    if (this.invWidth != null) {
      relevance = (relevance - this.minRelevance) * this.invWidth;
    }
    return getRelevanceColorDarkened(relevance);
  }

  getRelevanceColorSemiDarkened(relevance: number): string {
    if (this.invWidth != null) {
      relevance = (relevance - this.minRelevance) * this.invWidth;
    }
    return getRelevanceColorSemiDarkened(relevance);
  }
}
