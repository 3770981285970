import React, { useRef, useEffect, useState } from "react";

/// Helper function to avoid calling constructors/initializers on refs multiple
/// times. See discussion at:
/// https://github.com/facebook/react/issues/14490#issuecomment-451924162
export function useRefFn<T>(init: () => T): React.MutableRefObject<T> {
  const ref = useRef<T | typeof USE_REF_FN_SENTINEL>(USE_REF_FN_SENTINEL);
  if (ref.current === USE_REF_FN_SENTINEL) {
    ref.current = init();
  }
  return ref as React.MutableRefObject<T>;
}

const USE_REF_FN_SENTINEL = {};

export function useIdGenerator(): () => number {
  const ref = useRef(0);

  function getId() {
    ref.current += 1;
    return ref.current;
  }

  return getId;
}

export function useInitialRender(): boolean {
  const initial = useRef(true);

  useEffect(() => {
    initial.current = false;
  });

  return initial.current;
}

/**
 * Inspired by SWR, useRequest uses a `key` trigger requests.
 *
 * This allows to pass in a `getter` function as a normal arrow function.
 * I.e., the fetch effect doesn't run if the identify of the getter function
 * changes, so there is no need to wrap all getters into a `useCallback`.
 *
 * Instead the hook ignores the `getter`, and a fetch request is retriggered
 * if and only if the key changes.
 *
 * Note that it is necessary to allow the key to be undefined. The reason is
 * that on use-site the key may not be defined yet e.g. if it comes from route
 * parameters like `useParams<{ factId?: string }>()` that are optional by
 * design. In this case it is not possible to terminate the control flow early
 * via `if (!factId) return` because execution of hooks must be unconditional.
 * In theory we could handle the `key == undefined` case here and avoid calling
 * the getter, but there is not much benefit of doing so, because on use-site
 * TypeScript cannot know that the getter callback will only be called if the
 * key is defined. I.e., in this example the user would need an `if (factId)`
 * check or `factId!` expressions in the getter body anyway to satisfy TypeScript.
 * Because of that it may be easiest to call the getter unconditionally and
 * let the user deal with the potentially undefined key case. Typically this
 * will lead to getter functions that return `T | undefined` because they have
 * nothing meaningful to do when the key isn't there. An alternative approach
 * would be to pass the key back to the getter as an argument, with the `undefined`
 * removed from the type. This would work in the case where they fetch key
 * is exactly the type that is needed in the getter body. However it would be
 * a problem if the body needs access to non-string variables or when compound
 * keys are needed. For instance the key may be `${fromTimestamp}_${uptoTimestamp}`.
 * In this case it doesn't help that the key is now available in its defined form
 * in the getter body, while `fromTimestamp` and `uptoTimestamp` are still
 * potentially undefined.
 */
export function useRequest<T>(
  key: string | undefined,
  getter: () => Promise<T>
): [T | undefined, Error | undefined] {
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    async function fetch() {
      try {
        const newData = await getter();
        setData(newData);
      } catch (e) {
        setError(e);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [data, error];
}

/**
 * Draft of a "preloader" solution that solves the problem that the React router
 * only fetches data after a route switch. The idea is:
 *
 * - Wrap requests into a FooPreloader singleton.
 * - Call `await FooPreloader.preload(...)` before a route switch.
 * - Use `FooPreloader.get(...)` in the component (possibly within `useRequest`).
 *
 * If data has been preloaded the promise completes immediately, otherwise the
 * preloader performs the request normally. Preload data gets cleared after each
 * use to avoid loading a stale state.
 *
 * Technically it may not be needed to include the `key` here, but since this class
 * is typically use in conjunction with `useRequest` it can serve as an extra safety
 * measure to avoid using the "wrong" preloaded data.
 */
export class Preloader<Getter extends (...args: any[]) => Promise<any>> {
  preloaded?: ReturnType<Getter> extends Promise<infer T> ? T : never;
  preloadedKey?: string;

  constructor(private getter: Getter) {}

  async preload(key: string, ...args: Parameters<Getter>) {
    this.preloaded = await this.getter(...args);
    this.preloadedKey = key;
  }

  async get(
    key: string,
    ...args: Parameters<Getter>
  ): Promise<ReturnType<Getter> extends Promise<infer T> ? T : never> {
    if (this.preloaded != null && this.preloadedKey === key) {
      // console.log("Preloaded: using preloaded value");
      const tmp = this.preloaded;
      this.preloaded = undefined;
      this.preloadedKey = undefined;
      return tmp;
    } else {
      // console.log("Preloaded: direct get");
      return await this.getter(...args);
    }
  }

  hasPreloadedData(): boolean {
    return this.preloaded != null;
  }
}
