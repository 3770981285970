import { useRef } from "react";

import styles from "./Main.module.css";

import { S, css } from "../../styles/styles";

import { backend } from "../../backend";

import { ResizeObserver } from "../../utils/resize_observer";
import * as date_utils from "../../utils/date_utils";

import { Timeline, TimelineHandle } from "../timeline/Timeline";
import { ViewRange } from "../timeline/view_range";
import { DebouncedSelect, ValueType } from "../utils/DeboucedSelect";

import { geom } from "../timeline/geometry";

const cssHeader = css(S.mt(28), S.mb(8));
const cssTimeButtonRow = css(S.flex, S.justify("end"), S.spaceX(5), S.textSize(12), S.mt(20));
const cssTimeButton = css(S.unstyledButton);

type MainProps = {
  viewRange: ViewRange;
  resizeObserver: ResizeObserver;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
};

export function Main({ viewRange, resizeObserver, selectedTags, setSelectedTags }: MainProps) {
  console.log("Rendering Main");

  const timelineHandle = useRef<TimelineHandle>();

  return (
    <div className={styles.vContainer}>
      <div {...cssHeader}>
        {
          <DebouncedSelect<ValueType>
            mode="multiple" // or tags?
            allowClear
            style={{ width: "100%" }}
            placeholder="Topics"
            value={
              selectedTags.map((x) => ({
                key: x,
                label: x,
                value: x,
              })) as any
            }
            onChange={
              ((evt: ValueType[]) => {
                // console.log(evt);
                setSelectedTags(evt.map((x) => x.value));
              }) as any
            }
            fetchOptions={async (value) => {
              const tags = await backend().tagsTop(value);
              if (tags.isOk) {
                return tags.data.map((tag) => ({
                  key: tag.tag,
                  label: `${tag.tag} (${tag.count})`,
                  value: tag.tag,
                }));
              } else {
                console.log("Request failed: " + tags.reqError || tags.appError);
                return [];
              }
            }}
          />
        }
        <div {...cssTimeButtonRow}>
          <span>Last</span>
          <button
            {...cssTimeButton}
            onClick={() => {
              timelineHandle.current?.zoomTo(
                viewRange.extendRange(date_utils.getLastWeek(), geom.viewYExtend)
              );
            }}
          >
            week
          </button>
          <span>/</span>
          <button
            {...cssTimeButton}
            onClick={() => {
              timelineHandle.current?.zoomTo(
                viewRange.extendRange(date_utils.getLastMonth(), geom.viewYExtend)
              );
            }}
          >
            month
          </button>
          <span>/</span>
          <button
            {...cssTimeButton}
            onClick={() => {
              timelineHandle.current?.zoomTo(
                viewRange.extendRange(date_utils.getLastYear(), geom.viewYExtend)
              );
            }}
          >
            year
          </button>
        </div>
      </div>
      <div className={styles.vStretch}>
        <Timeline
          viewRange={viewRange}
          resizeObserver={resizeObserver}
          tags={selectedTags}
          handle={timelineHandle}
        />
      </div>
    </div>
  );
}
