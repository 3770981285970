import { ok, err, AsResponse } from "./response";

export async function request<X>(
  url: string,
  route: string,
  method: "GET" | "POST",
  data?: Record<string, unknown>
): Promise<AsResponse<X>> {
  let response: globalThis.Response;
  try {
    if (method === "GET") {
      const params =
        data != null ? `?${new URLSearchParams(data as Record<string, any>).toString()}` : "";
      response = await fetch(`${url}${route}${params}`, {
        method: "GET",
        credentials: "same-origin",
      });
    } else {
      response = await fetch(`${url}${route}`, {
        method: "POST",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        body: data != null ? JSON.stringify(data) : undefined,
      });
    }
  } catch (e) {
    console.log("Network error during fetch:", e);
    return err(undefined, "Network error during fetch: ", e) as AsResponse<X>;
  }
  const code = response.status;

  let responseRaw: string;
  try {
    responseRaw = await response.text();
  } catch (e) {
    console.log("Could not extract response text:", e);
    return err(code, "Could not extract response text: " + e) as AsResponse<X>;
  }

  if (!response.ok && code !== 400) {
    console.log("General request error:", responseRaw);
    return err(code, "Could not extract response text: " + responseRaw) as AsResponse<X>;
  }

  let responseData: any;
  try {
    responseData = JSON.parse(responseRaw);
  } catch (e) {
    console.log("JSON data decode error:", e);
    return err(code, "JSON data decode error: " + e) as AsResponse<X>;
  }

  if (typeof responseData !== "object" || responseData == null) {
    console.log("Response isn't a valid object but:", responseData);
    return err(code, "Response isn't a valid object but: " + responseData) as AsResponse<X>;
  }

  if (!("isOk" in responseData)) {
    console.log("Response is missing `isOk` field.");
    return err(code, "Response is missing `isOk` field.") as AsResponse<X>;
  }

  const isOk = responseData["isOk"];

  if (isOk !== true) {
    if (!("error" in responseData)) {
      console.log("Response is missing `error` field.");
      return err(code, "Response is missing `error` field.") as AsResponse<X>;
    } else {
      return err(code, undefined, responseData["error"]) as AsResponse<X>;
    }
  } else {
    if (!("data" in responseData)) {
      console.log("Response is missing `data` field.");
      return err(code, "Response is missing `data` field.") as AsResponse<X>;
    } else {
      return ok(code, responseData["data"]) as AsResponse<X>;
    }
  }
}
