import { Response, AsResponse } from "./response";
import { request } from "./request";

import * as types from "./types/api";

// Type helper:
// Since the request types contain a `[k: string]: unknown` index signature,
// they are not immediately compatible with the `data: Record<string, ...>`
// types. As a simple work-around, the index signature can be removed by this
// helper. See: https://stackoverflow.com/a/66252656/1804173
type KnownKeys<T> = {
  [P in keyof T as string extends P ? never : number extends P ? never : P]: T[P];
};

export class Backend {
  private accessToken: string | undefined;

  constructor(readonly url: string) {}

  // Internal utils

  private async get<X>(route: string, data?: Record<string, string>): Promise<AsResponse<X>> {
    return request(this.url, route, "GET", data);
  }

  private async post<X>(route: string, data?: Record<string, any>): Promise<AsResponse<X>> {
    return request(this.url, route, "POST", data);
  }

  // User routes

  async usersIsUsernameAvailable(username: string): Promise<Response<boolean, string>> {
    return await this.get<types.ResUsersIsUsernameAvailable>(
      "/api/v1/users/is_username_available",
      { username }
    );
  }

  async usersRegister(
    args: KnownKeys<types.ReqUsersRegister>
  ): Promise<Response<types.UserId, string>> {
    return await this.post<types.ResUsersRegister>("/api/v1/users/register", args);
  }

  async usersLogin(
    username: string,
    password: string
  ): Promise<Response<types.AccessToken, string>> {
    const res = await this.post<types.ResUsersLogin>("/api/v1/users/login", {
      username,
      password,
    });
    if (res.isOk) {
      this.accessToken = res.data.accessToken;
    }
    return res;
  }

  async usersSession(): Promise<Response<types.Session, string>> {
    const res = await this.get<types.ResUsersSession>("/api/v1/users/session");
    if (res.isOk) {
      this.accessToken = res.data.accessToken;
    }
    return res;
  }

  async userStats(username: string): Promise<Response<types.UserStats, string>> {
    return await this.get<types.ResUserStats>(`/api/v1/users/${username}/stats`);
  }

  // Fact routes

  async factsAdd(
    headline: string,
    date: string,
    tags: types.TagRelevance[],
    bullets: string[],
    references: string[]
  ): Promise<Response<types.FactId, string>> {
    const data: KnownKeys<types.ReqFactsAdd> = { headline, date, tags, bullets, references };
    return await this.post<types.ResFactsAdd>("/api/v1/facts/add", data);
  }

  async factsGet(body: types.ReqFactsGet): Promise<Response<types.FactsTagged, string>> {
    return await this.post<types.ResFactsGet>(`/api/v1/facts/get`, body);
  }

  async factsGetPrefiltered(
    body: types.ReqFactsGetPrefiltered
  ): Promise<Response<types.FactsTaggedPrefiltered, string>> {
    return await this.post<types.ResFactsGetPrefiltered>(`/api/v1/facts/get_prefiltered`, body);
  }

  async factGet(factId: string): Promise<Response<types.Fact, string>> {
    return await this.get<types.ResFactGet>(`/api/v1/facts/${factId}/get`);
  }

  async factVote(
    factId: string,
    tag: string,
    vote: types.InputVoteKind
  ): Promise<Response<types.TagRelevanceWithUserVoting, string>> {
    const data: KnownKeys<types.ReqFactVote> = { tag, vote };
    return await this.post<types.ResFactVote>(`/api/v1/facts/${factId}/vote`, data);
  }

  async factAddTag(
    factId: string,
    tag: string,
    relevance: number
  ): Promise<Response<types.ActualTag, string>> {
    const data: KnownKeys<types.ReqFactAddTag> = { tag, relevance };
    return await this.post<types.ResFactAddTag>(`/api/v1/facts/${factId}/add_tag`, data);
  }

  // Tag routes

  async tagsTop(search: string): Promise<Response<types.TagWithCount[], string>> {
    const data: KnownKeys<types.ReqTagsTop> = { search };
    return await this.get<types.ResTagsTop>(`/api/v1/tags/top`, data);
  }
}

// ----------------------------------------------------------------------------
// Singleton handling
// ----------------------------------------------------------------------------

function inferBackendUrl() {
  const useDifferentOrigin = false;
  if (useDifferentOrigin) {
    let loc = window.location;
    let url = `${loc.protocol}//${loc.hostname}:8080`;
    console.log(loc);
    console.log(url);
    return url;
  } else {
    return window.location.origin;
  }
}

let backendInstance: Backend | undefined;

export function backend(backendUrl?: string): Backend {
  // Note: Web workers do not have access to `window` and therefore cannot use
  // `inferBackendUrl`. They need to obtain the information from the main thread
  // and manually pass it in.
  if (backendInstance == null) {
    backendInstance = new Backend(backendUrl != null ? backendUrl : inferBackendUrl());
  }
  return backendInstance;
}
