import { Modal } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { Window } from "../utils";

import { S, css } from "../../styles/styles";

import { useHistory, useParams } from "react-router-dom";

import { backend } from "../../backend";
import { useRequest, Preloader } from "../../utils/react_utils";

const cssBack = css(S.unstyledButton, S.linkLike, S.textSize(12));

const cssHeadline = css(S.mt(10));

export const UserPreloader = new Preloader(
  async (userId: string) => await backend().userStats(userId)
);

export function User() {
  const { userId } = useParams<{ userId?: string }>();
  if (userId != null) {
    return <UserImpl userId={userId} />;
  } else {
    console.log("ERROR: Router did not provide a userId.");
    return null;
  }
}

type UserProps = {
  userId: string;
};

export function UserImpl({ userId }: UserProps) {
  const requestKey = userId;

  const history = useHistory();

  const [user] = useRequest(requestKey, async () => {
    const requestKey = userId;
    const req = await UserPreloader.get(requestKey, userId);
    // Handle request errors
    // Note that request error handling should be in the callback, because it is
    // illegal to use history.push() directly in the render function, and also
    // the modal window displays twice (perhaps due to the double render function
    // calling in dev mode).
    if (!req.isOk) {
      Modal.error({
        title: "Failed to fetch user",
        content: req.reqError || req.appError,
      });
      history.push("/");
      return undefined;
    } else {
      return req.data;
    }
  });

  // Handle loading
  if (user == null) {
    if (UserPreloader.hasPreloadedData()) {
      // If the preloaded has data available, it is fine to return an empty VDOM, because the
      // component will re-render immediately anyway when the effect within useRequest runs.
      return null;
    } else {
      // TODO: Here we could implement a loading indicator.
      return null;
    }
  }

  return (
    <Window>
      <button {...cssBack} onClick={() => history.goBack()}>
        <LeftOutlined style={{ fontSize: "12px" }} /> back
      </button>

      <h2 {...cssHeadline}>User Stats</h2>

      <p>
        Eventually this page is supposed to show users stats, activities, and reputation changes.
        Until then:
      </p>

      <div style={{ margin: "20px auto", width: "100%", textAlign: "center", fontSize: "18px" }}>
        <b>
          Thank you, <span style={{ color: S.colorHighlightDark }}>{user.username}</span>
        </b>
      </div>
      <p>
        for having joined on <b>{String(user.created).slice(0, 10)}</b> and having made{" "}
        <b>{user.numPosts}</b> posts so far.
      </p>
    </Window>
  );
}
