import { Form, Input, Button, Divider, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { S, css } from "../../styles/styles";

import { Window } from "../utils/Window";

import { backend } from "../../backend";

const cssPseudoBullet = css(S.flex, S.items("center"));
const cssPseudoBulletIcon = css(S.textSize(22), S.override(S.textColor("#444")), S.mr(12), S.my(6));

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

type FormValues = {
  username: string;
  email: string;
  password: string;
};

export type RegisterProps = {
  onRegister: (username: string) => void;
};

export function Register({ onRegister }: RegisterProps) {
  const [form] = Form.useForm();

  const onFinish = async (values: FormValues) => {
    let res = await backend().usersRegister({
      username: values.username,
      password: values.password,
      email: values.email,
    });
    if (res.isOk) {
      onRegister(values.username);
    } else {
      Modal.error({
        title: "Registration failed",
        content: res.reqError || res.appError,
      });
    }
  };

  return (
    <div>
      {/*
      <WindowInvisible>
        <h1 style={{ fontSize: "28px" }}>Join the ChronWire community</h1>
        <ul style={{ fontSize: "16px" }}>
          <li>Post time facts</li>
          <li>Unlock voting</li>
          <li>Save your favorite topics</li>
        </ul>
      </WindowInvisible>
      */}
      <Window>
        <div>
          <h1 style={{ fontSize: "28px" }}>Join the chronwire community</h1>
          <div {...cssPseudoBullet}>
            <CheckCircleOutlined {...cssPseudoBulletIcon} />
            Post time facts
          </div>
          <div {...cssPseudoBullet}>
            <CheckCircleOutlined {...cssPseudoBulletIcon} />
            Unlock voting
          </div>
          <div {...cssPseudoBullet}>
            <CheckCircleOutlined {...cssPseudoBulletIcon} />
            Save your favorite topics (coming soon...)
          </div>
        </div>
        <Divider />
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
        >
          <Form.Item
            name="username"
            label="Username"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please choose a username.",
                whitespace: false,
              },
              () => ({
                async validator(_, value) {
                  let valueSanitized = value.trim();
                  if (valueSanitized.length !== value.length) {
                    return Promise.reject("Username must not have leading/trailing whitespace.");
                  }
                  const isAvailableRes = await backend().usersIsUsernameAvailable(value);
                  // In case of a network error, we can pretend that the username is available,
                  // and report the network error in the following registration attempt instead?
                  // Otherwise we would have to report the network error during the form validation
                  // which may be awkward.
                  if (isAvailableRes.isOk && isAvailableRes.data === false) {
                    return Promise.reject("Username already exists.");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Doesn't seem to be a valid e-mail.",
              },
              {
                required: true,
                message: "Please input your e-mail.",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please choose a password.",
              },
              () => ({
                async validator(_, value) {
                  if (value.length < 6) {
                    return Promise.reject("At least 6 characters are needed.");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match.");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Sign up
            </Button>
          </Form.Item>
        </Form>
      </Window>
    </div>
  );
}
