/*
General resources on colors:

- https://www.youtube.com/watch?list=PLYx7XA2nY5Gcpabmu61kKcToLz0FapmHu&v=xAoljeRJ3lU
- https://www.youtube.com/watch?v=XjHzLUnHeM0
- https://www.youtube.com/watch?v=o9KxYxROSgM

Library references:

- https://github.com/colorjs/color-interpolate
- https://github.com/bpostlethwaite/colormap
- https://github.com/bpostlethwaite/colormap/blob/master/colorScale.js
- https://github.com/politiken-journalism/scale-color-perceptual
- https://github.com/colorjs/color-space

Note: When importing the colormaps from the second link above, I've
simply dropped the color indices for now. This means that the colors
do not necessarily match the exact positions in the 0-1 range of the
official colormaps, but are rather places uniformly in 0-1.
*/

/*
const pointsUser4 = [[0.0, "#e4e600"], [0.024193548387096777, "#ffe600"], [0.02956989247311828, "#ffe620"], [0.20967741935483872, "#ff6020"], [0.22043010752688175, "#ff5820"], [0.24462365591397853, "#ff3e22"], [0.25268817204301075, "#ff3e2e"], [0.2688172043010753, "#ff3e40"], [0.43817204301075274, "#ff3efd"], [0.4408602150537635, "#ff41ff"], [0.4623655913978495, "#ff63ff"], [0.48924731182795705, "#ff81ff"], [0.5430107526881721, "#d782ff"], [0.6344086021505377, "#9382ff"], [0.6801075268817205, "#6082ff"], [0.696236559139785, "#4782ff"], [0.6989247311827957, "#4484ff"], [0.7096774193548387, "#448cff"], [0.717741935483871, "#328cff"], [0.7231182795698925, "#218cff"], [0.728494623655914, "#008cfe"], [0.7419354838709677, "#008cea"], [0.7822580645161291, "#008cbd"], [0.8225806451612904, "#008c9f"], [0.8629032258064517, "#00aa9f"], [0.913978494623656, "#00aa79"], [0.9274193548387097, "#00aa6f"], [0.9650537634408602, "#00aa45"], [0.9704301075268817, "#00aa3f"], [0.9838709677419356, "#00aa2b"], [0.9919354838709679, "#00aa1b"], [1.0, "#00aa00"]]
const pointsViridis = [[0.0, "#440154"], [0.047058823529411764, "#471365"], [0.08627450980392157, "#482071"], [0.12941176470588234, "#472e7c"], [0.17254901960784313, "#443b84"], [0.2196078431372549, "#3e4989"], [0.27058823529411763, "#38588c"], [0.4156862745098039, "#287c8e"], [0.4980392156862745, "#21908d"], [0.5647058823529412, "#1fa088"], [0.6, "#22a884"], [0.6352941176470588, "#2ab07f"], [0.7058823529411764, "#46c06f"], [0.7450980392156863, "#5ac864"], [0.788235294117647, "#73d056"], [0.8745098039215686, "#aadc32"], [0.9372549019607843, "#d5e21a"], [0.9686274509803922, "#eae51a"], [1.0, "#fde725"]]
*/

type Color = { r: number; g: number; b: number };
type CmapPoint = [number, Color];
type CmapPoints = CmapPoint[];

function parseColorFromHexString(s: string) {
  return {
    r: parseInt(s.substr(1, 2), 16),
    g: parseInt(s.substr(3, 2), 16),
    b: parseInt(s.substr(5, 2), 16),
  };
}

function componentToHex(x: number) {
  x = Math.round(x);
  if (x < 0) {
    x = 0;
  } else if (x > 255) {
    x = 255;
  }
  const hex = x.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function convertColorToHexString(c: Color): string {
  return "#" + componentToHex(c.r) + componentToHex(c.g) + componentToHex(c.b);
}

const cmapPoints: CmapPoints = ([
  [0.0, "#4483ff"],
  [0.0196078431372549, "#3389ff"],
  [0.029411764705882353, "#278cff"],
  [0.0392156862745098, "#148eff"],
  [0.044117647058823525, "#0390ff"],
  [0.06372549019607843, "#0093f4"],
  [0.11274509803921569, "#0098db"],
  [0.12254901960784313, "#0098d5"],
  [0.15196078431372548, "#009bc9"],
  [0.20098039215686275, "#009db7"],
  [0.37254901960784315, "#00aa7e"],
  [0.4117647058823529, "#00ac70"],
  [0.4264705882352941, "#00ad68"],
  [0.43137254901960786, "#00ae67"],
  [0.4852941176470588, "#00b149"],
  [0.49019607843137253, "#00b247"],
  [0.5049019607843137, "#00b23c"],
  [0.5098039215686274, "#00b33a"],
  [0.5245098039215687, "#00b32e"],
  [0.5294117647058824, "#00b42b"],
  [0.5441176470588235, "#00b41b"],
  [0.553921568627451, "#00b50c"],
  [0.5588235294117647, "#00b600"],
  [0.5833333333333334, "#00c000"],
  [0.5931372549019608, "#28c100"],
  [0.5980392156862745, "#32c100"],
  [0.6127450980392157, "#46c300"],
  [0.6225490196078431, "#52c300"],
  [0.642156862745098, "#64c500"],
  [0.7009803921568627, "#8fcb00"],
  [0.7107843137254902, "#95cd00"],
  [0.7205882352941176, "#9bcd00"],
  [0.8186274509803921, "#d7d800"],
  [0.8725490196078431, "#e8c800"],
  [0.8823529411764706, "#ecc600"],
  [0.9019607843137255, "#f0c000"],
  [0.9362745098039216, "#fbb600"],
  [0.9411764705882353, "#fbb400"],
  [0.9558823529411764, "#ffb002"],
  [0.9852941176470588, "#ffa715"],
  [1.0, "#ffa217"],
] as [number, string][]).map((point) => [point[0], parseColorFromHexString(point[1])]);

const cmapPointsSemiDarkened: CmapPoints = ([
  [0.0, "#435983"],
  [0.044117647058823525, "#3d5d81"],
  [0.15196078431372548, "#3c6477"],
  [0.21568627450980393, "#3b6770"],
  [0.27450980392156865, "#3b6967"],
  [0.3382352941176471, "#3c6a5d"],
  [0.4117647058823529, "#3c6951"],
  [0.45588235294117646, "#3c6847"],
  [0.5245098039215687, "#3c653a"],
  [0.5588235294117647, "#3c6435"],
  [0.5833333333333334, "#3e6437"],
  [0.642156862745098, "#4a6538"],
  [0.7009803921568627, "#536439"],
  [0.8137254901960784, "#60633b"],
  [0.8872549019607843, "#69603a"],
  [0.9313725490196079, "#6e5d38"],
  [1.0, "#745a38"],
] as [number, string][]).map((point) => [point[0], parseColorFromHexString(point[1])]);

const cmapPointsDarkened: CmapPoints = ([
  [0.0, "#292b2f"],
  [0.09803921568627451, "#292c2f"],
  [0.25980392156862747, "#292d2d"],
  [0.5588235294117647, "#292d28"],
  [0.8137254901960784, "#2c2d29"],
  [1.0, "#2e2c29"],
] as [number, string][]).map((point) => [point[0], parseColorFromHexString(point[1])]);

function interpolate(cmapPoints: CmapPoints, x: number): string {
  // Note: We could binary search, but not crucial because lookups are cached anyway.
  for (let i = 0; i < cmapPoints.length - 1; ++i) {
    const [xFrom, colorFrom] = cmapPoints[i];
    const [xUpto, colorUpto] = cmapPoints[i + 1];
    if (xFrom <= x && x <= xUpto) {
      const a = (x - xFrom) / (xUpto - xFrom);
      const color = {
        r: (1 - a) * colorFrom.r + a * colorUpto.r,
        g: (1 - a) * colorFrom.g + a * colorUpto.g,
        b: (1 - a) * colorFrom.b + a * colorUpto.b,
      };
      return convertColorToHexString(color);
    }
  }
  if (x < 0) {
    return convertColorToHexString(cmapPoints[0][1]);
  } else {
    return convertColorToHexString(cmapPoints[cmapPoints.length - 1][1]);
  }
}

// Note:
// Computing the relevance color doesn't require arbitrary precision,
// and caching seems to have a significant benefit (see benchmarks).
// Therefore the following is spit in internal + external computations
// based on cache.

// ----------------------------------------------------------------------------
// Normal
// ----------------------------------------------------------------------------

function getRelevanceColorInternal(relevance: number): string {
  const color = interpolate(cmapPoints, relevance / 100);
  return color;
}

const relevanceColorCache = Array.from(Array(101)).map((_, i) => getRelevanceColorInternal(i));

export function getRelevanceColor(relevance: number): string {
  let index = Math.round(relevance);
  if (index < 0) {
    index = 0;
  } else if (index > 100) {
    index = 100;
  }
  return relevanceColorCache[index];
}

// ----------------------------------------------------------------------------
// Semi-Darkened
// ----------------------------------------------------------------------------

function getRelevanceColorSemiDarkenedInternal(relevance: number): string {
  const color = interpolate(cmapPointsSemiDarkened, relevance / 100);
  return color;
}

// Computing the relevance color doesn't require arbitrary precision,
// and caching seems to have a significant benefit (see benchmarks).
const relevanceColorSemiDarkenedCache = Array.from(Array(101)).map((_, i) =>
  getRelevanceColorSemiDarkenedInternal(i)
);

export function getRelevanceColorSemiDarkened(relevance: number): string {
  let index = Math.round(relevance);
  if (index < 0) {
    index = 0;
  } else if (index > 100) {
    index = 100;
  }
  return relevanceColorSemiDarkenedCache[index];
}

// ----------------------------------------------------------------------------
// Darkened
// ----------------------------------------------------------------------------

function getRelevanceColorDarkenedInternal(relevance: number): string {
  const color = interpolate(cmapPointsDarkened, relevance / 100);
  return color;
}

// Computing the relevance color doesn't require arbitrary precision,
// and caching seems to have a significant benefit (see benchmarks).
const relevanceColorDarkenedCache = Array.from(Array(101)).map((_, i) =>
  getRelevanceColorDarkenedInternal(i)
);

export function getRelevanceColorDarkened(relevance: number): string {
  let index = Math.round(relevance);
  if (index < 0) {
    index = 0;
  } else if (index > 100) {
    index = 100;
  }
  return relevanceColorDarkenedCache[index];
}
