import { FactsProvider } from "../../core";
import { FactCircle, LayoutParams } from "../../core/types";

import { Timestamp, getFutureTimestamp } from "../../utils/date_utils";

import { RendererCanvas } from "./renderer_canvas";
import { updateRenderStats } from "./render_stats";

import { ViewRange } from "./view_range";
import { DrawParams } from "./types";

import { geom } from "./geometry";

// import { DebugStatistic } from "./render_stats";
// const stats = new DebugStatistic();

type ViewState = {
  viewRange: ViewRange;
  tags: string[];
  box?: BoxLookup;
  mouseX?: number;
  mouseY?: number;
};

/**
 * The TimelineView class deals mainly with DOM abstractions, i.e.:
 * - Keeps track of required DOM element references.
 * - Is a aware of client bounding rects.
 * - Is capable of delegating DOM events in screen coordinates to view elements.
 *
 * Its main interface is a bunch of `updateXXX` function that can internally
 * trigger a this.redraw() which delegates rendering to the underlying renderers.
 */
export class TimelineView {
  readonly root: HTMLElement | undefined = undefined;
  readonly canvas: HTMLCanvasElement | undefined = undefined;
  readonly canvasHover: HTMLCanvasElement | undefined = undefined;

  private state: ViewState;
  private factsProvider: FactsProvider;
  private params: DrawParams;

  private renderer: RendererCanvas | undefined;

  constructor(viewRange: ViewRange, tags: string[]) {
    this.state = {
      viewRange,
      tags,
    };

    this.factsProvider = new FactsProvider(() => {
      this.redraw();
    });

    this.params = {
      drawSecondaryAxisLabels: false,
      future: getFutureTimestamp(),
    };
  }

  // *** Ref initialization

  setRoot(root: HTMLElement) {
    (this as { root: HTMLElement }).root = root;
  }

  setCanvas(canvas: HTMLCanvasElement) {
    (this as { canvas: HTMLElement }).canvas = canvas;
    this.tryInitRendererCanvasIfNeeded();
  }

  setCanvasHover(canvasHover: HTMLCanvasElement) {
    (this as { canvasHover: HTMLElement }).canvasHover = canvasHover;
    this.tryInitRendererCanvasIfNeeded();
  }

  private tryInitRendererCanvasIfNeeded() {
    if (this.renderer == null) {
      if (this.canvas != null && this.canvasHover != null) {
        this.renderer = new RendererCanvas(this.canvas, this.canvasHover);
      }
    } else {
      if (this.canvas != null) {
        this.renderer.canvas = this.canvas;
      }
      if (this.canvasHover != null) {
        this.renderer.canvasHover = this.canvasHover;
      }
    }
  }

  // *** Getters

  getTimestampFromClientY(clientY: number): Timestamp | undefined {
    if (this.state.box != null) {
      return this.state.viewRange.getTimestamp(this.state.box.getY(clientY));
    }
  }

  getMatchingCircleFromClientXY(clientX: number, clientY: number): FactCircle | undefined {
    if (this.state.box != null) {
      const [x, y] = this.state.box.getXY(clientX, clientY);
      return this.getMatchingCircle(x, y);
    }
  }

  getMatchingCircle(x: number, y: number): FactCircle | undefined {
    return this.renderer?.getMatchingCircle(x, y);
  }

  // *** Updates

  updateViewRange(viewRange: ViewRange) {
    this.state.viewRange = viewRange;
    this.redraw();
  }

  updateTags(tags: string[]) {
    this.state.tags = tags;
    // this.redraw();
    // Currently updating the tags doesn't even require a redraw directly. It is
    // sufficient to re-request the facts, and the observer callback will trigger
    // a redraw once the new data is available.
    this.factsProvider.getFacts(
      this.state.viewRange.getRange(),
      this.state.tags,
      this.getLayoutParams()
    );
  }

  updateClientBoundingRect(newRect: DOMRect) {
    // In general the box can have a fractional size. For the canvases it is bad
    // to use these fractional sizes as values for setting the canvas.width/height
    // because that enables sub-pixel adjustment, leading to blurry rendering.
    // There are two options: Either round/floor the values just when resizing the
    // canvas, or adjust the box width here, so that everything uses the same
    // rounded sizes.
    newRect.width = Math.floor(newRect.width);
    newRect.height = Math.floor(newRect.height);

    let sizeHasChanged: boolean;
    if (this.state.box == null) {
      sizeHasChanged = true;
      this.state.box = new BoxLookup(newRect);
    } else {
      const oldRect = this.state.box.boundingClientRect;
      sizeHasChanged = oldRect.width !== newRect.width || oldRect.height !== newRect.height;
      this.state.box = new BoxLookup(newRect);
    }

    this.state.viewRange.setHeight(newRect.height, geom.viewYExtend);

    if (sizeHasChanged && this.renderer != null) {
      console.log(`Resizing canvas to ${newRect.width} x ${newRect.height}`);
      this.renderer.resizeCanvasesToClientSize(this.state.box);
      this.redraw();
    }
  }

  updateMouseXY(clientX: number | undefined, clientY: number | undefined) {
    this.state.mouseX = clientX;
    this.state.mouseY = clientY;
    this.handleMouseHover();
  }

  // *** Internal handlers

  private handleMouseHover() {
    const matchingCircle =
      this.state.mouseX != null && this.state.mouseY != null
        ? this.getMatchingCircleFromClientXY(this.state.mouseX, this.state.mouseY)
        : undefined;

    // It is a bit unclear if it is better to set the cursor style on the root div or the canvases.
    if (this.root != null) {
      if (matchingCircle != null) {
        this.root.style["cursor"] = "pointer";
      } else {
        this.root.style["cursor"] = "grab";
      }
    }

    if (this.renderer != null && this.state.box != null) {
      if (matchingCircle != null) {
        this.renderer.renderHover(matchingCircle, this.state.box);
      } else {
        this.renderer.clearHover(this.state.box);
      }
    }
  }

  private getLayoutParams(): LayoutParams {
    return {
      timePerPixel: this.state.viewRange.getTimePerPixel(),
      radius: geom.bubbleRadius,
      textGap: geom.bubbleRadius * 3,
      minVisibleX: geom.xVertLineCeiled,
      transformRelevanceToX: geom.transformRelevanceToX,
      transformTimestampToY: this.state.viewRange.getYTransformParams(),
      futureUp: this.state.viewRange.futureUp,
    };
  }

  private redraw() {
    // console.log("redrawing", this.state);

    const { viewRange, tags, box } = this.state;
    if (box == null || this.canvas == null || this.renderer == null) {
      console.log("WARNING: redraw called on incomplete renderer state.");
      return;
    }

    const t1 = performance.now();

    const facts = this.factsProvider.getFacts(viewRange.getRange(), tags, this.getLayoutParams());

    const t2 = performance.now();

    this.renderer.render(viewRange, box, facts, this.params);

    // const t3 = performance.now();

    this.handleMouseHover();

    const t4 = performance.now();

    updateRenderStats(t1, t2, t4);
    // stats.push(t3 - t2);
  }
}

// ----------------------------------------------------------------------------
// Misc
// ----------------------------------------------------------------------------

/**
 * Helper class that allows conversion from client X/Y coordinates into
 * local x/y coordinates of an element.
 */
export class BoxLookup {
  width: number;
  height: number;

  constructor(readonly boundingClientRect: DOMRect) {
    this.width = boundingClientRect.width;
    this.height = boundingClientRect.height;
  }

  getX(clientX: number): number {
    return clientX - this.boundingClientRect.left;
  }

  getY(clientY: number): number {
    return clientY - this.boundingClientRect.top;
  }

  getXY(clientX: number, clientY: number): [number, number] {
    return [clientX - this.boundingClientRect.left, clientY - this.boundingClientRect.top];
  }
}
