import daysjs, { Dayjs } from "dayjs";

import { uuidv4 } from "./uuid";

// ----------------------------------------------------------------------------
// Fundamental validation types
// ----------------------------------------------------------------------------

export type ValidatorResult<O> = { value: O; msg: string; isValid: boolean; immediate: boolean };

export type Validator<I, O> = (input: I) => ValidatorResult<O>;

export class Validated<I, O> {
  // Flatten the ValidatorResult properties for convenience
  readonly value: O;
  readonly msg: string;
  readonly isValid: boolean;
  readonly immediate: boolean;

  private constructor(
    readonly key: string,
    private validator: Validator<I, O>,
    validatorResult: ValidatorResult<O>,
    readonly initial = false
  ) {
    ({
      value: this.value,
      msg: this.msg,
      isValid: this.isValid,
      immediate: this.immediate,
    } = validatorResult);
  }

  validate(valueRaw: I): Validated<I, O> {
    const validatorResult = this.validator(valueRaw);
    return new Validated(this.key, this.validator, validatorResult, false);
  }

  makeNonInitial(): Validated<I, O> {
    return new Validated(
      this.key,
      this.validator,
      {
        value: this.value,
        msg: this.msg,
        isValid: this.isValid,
        immediate: this.immediate,
      },
      false
    );
  }

  static create<I, SpecificInput extends I, O>(
    validator: Validator<I, O>,
    initValueRaw: SpecificInput
  ): Validated<I, O> {
    const validatorResult = validator(initValueRaw);
    return new Validated(uuidv4(), validator, validatorResult, true);
  }
}

// ----------------------------------------------------------------------------
// Validators
// ----------------------------------------------------------------------------

export function validatorText(valueRaw: string): ValidatorResult<string> {
  const maxLength = 120;

  const value = valueRaw.trim();
  const length = value.length;

  if (length === 0) {
    return { value, msg: "Must not be empty", isValid: false, immediate: false };
  }
  if (length > 20 && length <= maxLength) {
    return {
      value,
      msg: `${maxLength - length} characters remaining`,
      isValid: true,
      immediate: true,
    };
  } else if (length > maxLength) {
    return {
      value,
      msg: `${length - maxLength} characters too long`,
      isValid: false,
      immediate: true,
    };
  } else {
    return { value, msg: "", isValid: true, immediate: false };
  }
}

export function validatorDate(value: Dayjs | null): ValidatorResult<Dayjs | null> {
  if (value == null) {
    return { value, msg: "Date must be specified", isValid: false, immediate: false };
  } else {
    const tomorrow = daysjs().endOf("day");
    if (value.isBefore(tomorrow)) {
      return { value, msg: "", isValid: true, immediate: false };
    } else {
      return {
        value,
        msg: "Date must not be in the future.",
        isValid: false,
        immediate: true,
      };
    }
  }
}

export function validatorTagName(valueRaw: string): ValidatorResult<string> {
  const value = valueRaw.trim();
  const length = value.length;

  if (length === 0) {
    return { value, msg: "Must not be empty", isValid: false, immediate: false };
  }
  // Note:
  // \x30-\x39 => numbers
  // \x41-\x5A => A-Z
  // \x61-\x7A => a-z
  // underscore and hyphen listed separately
  const allValidChars = /^[\x30-\x39\x41-\x5A\x61-\x7A_-]*$/.test(value);
  if (!allValidChars) {
    return {
      value,
      msg: "Must only contain normal letters, numbers, underscores, or hyphens.",
      isValid: false,
      immediate: true,
    };
  }
  return { value, msg: "", isValid: true, immediate: false };
}

export function validatorTagRating(
  value: number | string | null | undefined
): ValidatorResult<number> {
  if (value == null || typeof value !== "number" || isNaN(value)) {
    return {
      value: NaN,
      msg: "Must be a valid number",
      isValid: false,
      immediate: false,
    };
  }
  return { value, msg: "", isValid: true, immediate: false };
}

export function validatorUrl(valueRaw: string): ValidatorResult<string> {
  const value = valueRaw.trim();
  const length = value.length;

  if (length === 0) {
    return { value, msg: "Must not be empty", isValid: false, immediate: false };
  } else {
    try {
      new URL(value);
      return { value, msg: "", isValid: true, immediate: false };
    } catch {
      return { value, msg: "Must be a valid URL", isValid: false, immediate: false };
    }
  }
}

export type ValidatedString = Validated<string, string>;
export type ValidatedNumber = Validated<string | number | null | undefined, number>;
export type ValidatedDate = Validated<Dayjs | null, Dayjs | null>;
