import { useState, ReactNode, useRef, useEffect, useCallback } from "react";

import useLatest from "@react-hook/latest";

import { Spin, Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { S, css } from "../../styles/styles";

import { useDebounce } from "./use_debounce";
/*

https://www.codeinwp.com/blog/react-ui-component-libraries-frameworks/

Inspirations:

From Semantic UI:
- https://react.semantic-ui.com/modules/dropdown/
- https://react.semantic-ui.com/modules/search/

In Material UI, the "Creatable" example is interesting:
- https://material-ui.com/components/selects/
- https://material-ui.com/components/autocomplete/

*/

// ----------------------------------------------------------------------------
// Styles
// ----------------------------------------------------------------------------

const cssInputLike = css(
  S.relative,
  S.wFull,
  S.inlineFlex,
  S.items("center"),
  S.border(1, "#d9d9d9"),
  S.rounded(2),
  S.textColor("rgba(0, 0, 0, 0.85)"),
  S.bgColor("#FFF"),
  S.transitionAll(300),
  S.hover(S.borderColor("#40a9ff")),
  // Must be focus-within, because it is actually the child <input/> that get's the focus.
  S.focusWithin(
    S.borderColor("#40a9ff"),
    "outline: 0",
    "box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2)"
  )
);

const cssInputUnstyled = css(
  S.m(0),
  // A regular Antd input has x-padding of 11, but we need to emulate it,
  // because, when a Tag is rendered, 11 is too much.
  // S.px(11),
  S.px(0),
  S.py(4),
  "border: none",
  S.focus("outline: 0"),
  S.flexGrow
);

const cssInputTag = css(S.override(S.mx(4)));

const cssInputIcon = css(S.mx(8));

const cssPopupAnchor = css(S.relative, S.wFull);

const cssCommonPopup = [
  S.absolute,
  "top: 4px",
  "left: 0",
  "right: 0",
  "box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)",
  S.border(1, "#d9d9d9"),
  S.rounded(2),
  S.py(4),
  "transition: all 0.2s;",
  "transform-origin: left top;",
  S.bgColor("#FFF"),
  S.z(50),
];

const cssPopup = css(...cssCommonPopup);

const cssPopupHidden = css(...cssCommonPopup, "transform: scale(1, 0)", "opacity: 0");

const cssPopupRow = css(S.py(2), S.px(4), "cursor: pointer");

// ----------------------------------------------------------------------------
// Component
// ----------------------------------------------------------------------------

export type TagWithData<T = undefined> = {
  tagKey: string;
  auxData: T;
};

export type SearchResults<T = undefined> = {
  entries: TagWithData<T>[];
  exactMatch: boolean;
  invalidReason: string | undefined;
};

export type DropdownProps<T> = {
  search: (s: string) => Promise<SearchResults<T>>;
  renderRow: (x: TagWithData<T>) => ReactNode;
  mode: "single" | "multi";
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
};

type EntryRegular<T> = {
  isCreateNew: false;
  tagKey: string;
  origTagWithData: TagWithData<T>;
};

type EntryCreateNew = {
  isCreateNew: true;
  tagKey: string;
};

type Entry<T> = EntryRegular<T> | EntryCreateNew;

export function TagSelect<T>(props: DropdownProps<T>) {
  const [isFocused, setIsFocused] = useState(false);
  const [popupState, setPopupVisible, onTransitionEnd] = useTransitionState();

  const [isLoading, setIsLoading] = useState(false);

  const [entries, setEntries] = useState<Entry<T>[]>([]);
  const [invalidReason, setInvalidReason] = useState<string | undefined>();
  const [highlightedRow, setHighlightedRow] = useState(-1);

  const refInput = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTermDebounced, setSearchTermImmediately] = useDebounce("", 500);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Handling the onBlur properly is awkward. onBlur can either be called by regular
  // onBlurs (the user clicking somewhere), but we also need to actively call the
  // input.blur() on selects. In general is seems to be better to perform the clearing
  // of the input in a single place only, i.e., it had to be done finally in the onBlur.
  // However, the onBlur gets called synchronously from handleSelect. Because of how
  // React's setState works, it is not possible to read the already modified state
  // (handleSelect writes the selected entry) within onBlur. Calling onBlur asynchronously
  // with a setTimeout isn't an option either, because then there is a single frame
  // that already renders the newly selected tag, while the search value is still
  // briefly visible in the input. For this reason the call probably has to be
  // synchronous. In general it is not possible to tell within the onBlur whether
  // it has been called from handleSelect, or from a regular blur. The onBlurArgs
  // is a workaround allowing to pass information into onBlur.
  const onBlurArgs = useRef({ selectedValue: "" });

  /*
  // For debugging popup state
  useEffect(() => {
    console.log(popupState);
  }, [popupState]);
  */

  const fetchEffectContext = useLatest({
    searchFunc: props.search,
    setPopupVisible: setPopupVisible,
  });

  useEffect(() => {
    // console.log("effect triggered", searchTerm, isFocused);
    async function load() {
      if (isFocused) {
        setIsLoading(true);
        try {
          const {
            entries: searchEntries,
            exactMatch,
            invalidReason: newInvalidReason,
          } = await fetchEffectContext.current.searchFunc(searchTerm);

          const newEntries: Entry<T>[] = searchEntries.map((entry) => ({
            isCreateNew: false,
            tagKey: entry.tagKey,
            origTagWithData: entry,
          }));

          const isValid = newInvalidReason == null;
          if (isValid && !exactMatch && searchTerm.length > 0) {
            newEntries.push({ isCreateNew: true, tagKey: searchTerm });
          }

          setEntries(newEntries);
          setInvalidReason(newInvalidReason);

          if (newEntries.length > 0) {
            setHighlightedRow(0);
          } else {
            setHighlightedRow(-1);
          }
          fetchEffectContext.current.setPopupVisible(true);
        } finally {
          setIsLoading(false);
        }
      }
    }
    load();
  }, [searchTerm, isFocused, fetchEffectContext]);

  const handleSelect = (row: number) => {
    // console.log("selecting", row);
    if (props.mode === "single") {
      const entry = entries[row];
      setSelectedItems([entry.tagKey]);
      onBlurArgs.current.selectedValue = entry.tagKey;

      // Note: Clearing the input field text is happening in onBlur for consistency with
      // regular blur events. For this reason calling onChange also happens in the onBlur.
      // Otherwise regular blurs clear the input, but parents won't be notified of the
      // onChange to "", believing a previously selected tag is still selected.
      if (refInput.current != null) {
        refInput.current.blur();
      }
    }
  };

  const onFocus = async () => {
    if (props.mode === "single") {
      setSelectedItems([]);
      onBlurArgs.current.selectedValue = "";
    }
    setIsFocused(true);
    if (props.onFocus != null) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    const selectedValue = onBlurArgs.current.selectedValue;

    // We have to clear the input field. Since it is not a controlled input, we have
    // to manually clear the ref. Note that assigning value should not trigger an onChange.
    if (refInput.current != null) {
      refInput.current.value = "";
    }
    // We also have to reset the search term. Otherwise the search term is remembered,
    // and when the user focuses the input again (which now has an empty value), the
    // listed search entries still belong to the old search term. Since isFocused is
    // set to false as well, this should not actually triggering a search effect for "".
    setIsFocused(false);
    setSearchTermImmediately("");

    setPopupVisible(false);

    // Note: Information onChange should happen before the onBlur to be consistent with
    // regular inputs.
    if (props.mode === "single") {
      // Caution: We must not use selectedItems directly here, because it hasn't been updated yet...
      if (props.onChange != null) {
        props.onChange(selectedValue);
      }
    }
    if (props.onBlur != null) {
      props.onBlur();
    }
  };

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        setHighlightedRow(computeSelectedIndex(entries.length, highlightedRow, -1));
        break;
      case "ArrowDown":
        event.preventDefault();
        setHighlightedRow(computeSelectedIndex(entries.length, highlightedRow, +1));
        break;
      case "Escape":
        if (refInput.current != null) {
          refInput.current.blur();
        }
        break;
      case "Enter":
        if (highlightedRow !== -1) {
          // Note: currently we only allow pressing enter when the input content is identical to the debounced
          // search term. This is perhaps not ideal from a UX perspective but not doing so is awkward as well:
          // If the user quickly types a name and presses ENTER, the ENTER actually has the semantics of
          // selecting the currently highlighted row, and not the word the user has written. Requiring that
          // the values match means that the debounce is over, but it is also still necessary to wait
          // until the entries have updated...
          if (
            refInput.current != null &&
            refInput.current.value.toLowerCase() === searchTerm.toLowerCase() &&
            !isLoading
          ) {
            handleSelect(highlightedRow);
          }
        }
        event.preventDefault();
        break;
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // console.log(event.target.value);
    setSearchTermDebounced(event.target.value);
  }

  function Input() {
    return (
      <div {...cssInputLike}>
        {selectedItems.length === 0 ? (
          <InputTagPlaceholder />
        ) : (
          selectedItems.map((tagKey) => (
            <Tag key={tagKey} {...cssInputTag}>
              {tagKey}
            </Tag>
          ))
        )}
        <input
          ref={refInput}
          {...cssInputUnstyled}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          autoFocus={props.autoFocus}
        />
        {isLoading ? <LoadIndicator /> : null}
      </div>
    );
  }

  const styleHint = { marginLeft: "4px", cursor: "default", fontSize: "12px" };

  function PopupContent() {
    if (popupState !== "invisible") {
      if (entries.length === 0 && searchTerm.length === 0) {
        return (
          <div {...cssPopupRow} style={styleHint}>
            <i>No existing tags</i>
          </div>
        );
      } else {
        return (
          <>
            {entries.map((entry, i) => (
              <div
                key={entry.tagKey}
                {...cssPopupRow}
                style={i === highlightedRow ? { background: "#e4f3ff" } : {}}
                onMouseMove={() => {
                  if (highlightedRow !== i) {
                    setHighlightedRow(i);
                  }
                }}
                onMouseDown={(evt) => {
                  // Needed because of blur: https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
                  evt.preventDefault();
                }}
                onClick={() => handleSelect(i)}
              >
                {!entry.isCreateNew ? (
                  props.renderRow(entry.origTagWithData)
                ) : (
                  <>
                    <span style={styleHint}>Create new tag</span> <Tag>{entry.tagKey}</Tag>
                  </>
                )}
              </div>
            ))}
            {invalidReason != null ? (
              <div {...cssPopupRow} style={{ ...styleHint, color: S.colorError }}>
                {invalidReason}
              </div>
            ) : null}
          </>
        );
      }
    } else {
      return null;
    }
  }

  const curPopupCss =
    popupState === "entering" || popupState === "visible" ? cssPopup : cssPopupHidden;

  return (
    <div className={props.className}>
      {
        // Note that calling <Input /> directly leads to a focus problem:
        // https://stackoverflow.com/a/65603418/1804173
        Input()
      }
      <div {...cssPopupAnchor}>
        <div {...curPopupCss} onTransitionEnd={onTransitionEnd}>
          <PopupContent />
        </div>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------
// Utils
// ----------------------------------------------------------------------------

type TransitionState = "entering" | "visible" | "exiting" | "invisible";

function useTransitionState(): [TransitionState, (visible: boolean) => void, () => void] {
  const [state, setState] = useState<TransitionState>("invisible");

  // TODO: This doesn't really make setStateBinary a stable callback.
  // Should we apply the pattern of wrapping `state` into a `latestState`
  // ref via useLatest here as well? Is useLatest a silver bullet to
  // side-step unwanted dependencies?
  const setStateBinary = useCallback(
    (visible: boolean) => {
      if (visible && state !== "visible" && state !== "entering") {
        setState("entering");
      } else if (!visible && state !== "invisible" && state !== "exiting") {
        setState("exiting");
      }
    },
    [state]
  );

  const onTransitionEnd = () => {
    if (state === "entering") {
      setState("visible");
    } else if (state === "exiting") {
      setState("invisible");
    }
  };

  return [state, setStateBinary, onTransitionEnd];
}

function LoadIndicator() {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin {...cssInputIcon} />} />;
}

export function computeSelectedIndex(listLength: number, current: number, delta: number): number {
  if (listLength <= 0) {
    return -1;
  } else if (current === -1) {
    return delta > 0 ? 0 : listLength - 1;
  } else {
    let newValue = current + delta;
    while (newValue >= listLength) newValue -= listLength;
    while (newValue < 0) newValue += listLength;
    return newValue;
  }
}

const cssInputSpacer = css(S.w(11));

function InputTagPlaceholder() {
  return <div {...cssInputSpacer} />;
}
